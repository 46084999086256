<h4 class="content-title">Hardware Serials</h4>
<myp-segmented-bar [white]="true" (selected)="selectSection($event.id)" [options]="sections"></myp-segmented-bar>

<div class="row m-t-30" *ngIf="selectedSection == 'create'">
  <div class="form-group col-md-6">
    <label class="control-label" for="serial">Serial Number</label>
    <input type="text" [(ngModel)]="args.serial" name="serial" class="form-control" id="serial" [placeholder]="'Enter a hardware serial...'" />
  </div>
  <div class="form-group col-md-6">
    <label class="control-label" for="description">Description</label>
    <input type="text" [(ngModel)]="args.description" name="description" class="form-control" id="description" [placeholder]="'Enter a description...'" />
  </div>
  <div class="form-group col-md-6">
    <label class="control-label" for="material_number">Material Number</label>
    <input type="text" [(ngModel)]="args.material_number" name="material_number" class="form-control" id="material_number" [placeholder]="'Enter a material number...'" />
  </div>
  <div class="form-group col-md-12">
    <button class="btn btn-primary" (click)="create()">Create</button>
    &nbsp;&nbsp;&nbsp;
    <fa-icon icon="spinner" [animation]="'spin'" *ngIf="loading"></fa-icon>
    <ng-container *ngIf="createSuccess">
      <fa-icon icon="check-circle" class="c-green"></fa-icon>
      &nbsp; Serial
      <strong>{{ args.serial }}</strong>
      created successfully.
    </ng-container>
  </div>
  <div class="col-md-12">
    <span class="text-muted">Hint: Mustang Micro material number is 2311600000, and the description should be MUSTANG MICRO PLUS - US CA EU AU JP</span>
  </div>
</div>

<div class="row m-t-30" *ngIf="selectedSection == 'search'">
  <div class="form-group col-md-6">
    <label class="control-label" for="serial">Serial Number</label>
    <input type="text" [(ngModel)]="query" name="serial" class="form-control" id="serial" (keyup.enter)="search()" [placeholder]="'Enter a hardware serial...'" />
  </div>

  <div class="form-group col-md-12 m-b-30">
    <button class="btn btn-primary" (click)="search()">Search</button>
    &nbsp;&nbsp;&nbsp;
    <fa-icon icon="spinner" [animation]="'spin'" *ngIf="loading"></fa-icon>
  </div>

  <ng-container *ngIf="searchResults">
    <div class="col-4 col-lg-3">Serial</div>
    <div class="col-8 col-lg-9">{{ searchResults.serial }}</div>
    <div class="col-8 col-lg-9 offset-4 offset-lg-3">
      <hr class="m-y-10" />
    </div>

    <div class="col-4 col-lg-3">Material Number</div>
    <div class="col-8 col-lg-9">{{ searchResults.material_number }}</div>
    <div class="col-8 col-lg-9 offset-4 offset-lg-3">
      <hr class="m-y-10" />
    </div>

    <div class="col-4 col-lg-3">Created At</div>
    <div class="col-8 col-lg-9">{{ searchResults.created_at | date }}</div>
    <div class="col-8 col-lg-9 offset-4 offset-lg-3">
      <hr class="m-y-10" />
    </div>

    <div class="col-4 col-lg-3">Description</div>
    <div class="col-8 col-lg-9">{{ searchResults.description }}</div>
    <div class="col-8 col-lg-9 offset-4 offset-lg-3">
      <hr class="m-y-10" />
    </div>

    <ng-container *ngIf="searchResults.user_id">
      <div class="col-4 col-lg-3">User ID</div>
      <div class="col-8 col-lg-9">
        <a [routerLink]="['/customer/detail/' + searchResults.user_id]">{{ searchResults.user_id }}</a>
      </div>
      <div class="col-8 col-lg-9 offset-4 offset-lg-3">
        <hr class="m-y-10" />
      </div>
    </ng-container>

    <ng-container *ngIf="searchResults.subscription_id">
      <div class="col-4 col-lg-3">Subscription ID</div>
      <div class="col-8 col-lg-9">
        <div class="d-flex">
          <span>{{ searchResults.subscription_id }}</span>
          <button
            type="button"
            [disabled]="canceling"
            class="btn btn-secondary btn-sm m-l-10"
            (click)="cancelSubscription(searchResults.user_id, searchResults.subscription_id)"
            [disabled]="loadingSubscriptions"
          >
            Cancel Immediately
            <fa-icon icon="spinner" [animation]="'spin'" *ngIf="canceling"></fa-icon>
          </button>
        </div>
      </div>
      <div class="col-8 col-lg-9 offset-4 offset-lg-3">
        <hr class="m-y-10" />
      </div>
    </ng-container>

    <ng-container *ngIf="searchResults.redeem_date">
      <div class="col-4 col-lg-3">Redeem Date</div>
      <div class="col-8 col-lg-9">{{ searchResults.redeem_date | date }}</div>
      <div class="col-8 col-lg-9 offset-4 offset-lg-3">
        <hr class="m-y-10" />
      </div>
    </ng-container>
  </ng-container>
  <div *ngIf="searchResults !== undefined && !searchResults" class="col-12 text-muted m-y-30">No results.</div>
</div>
