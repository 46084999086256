import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { of as observableOf, BehaviorSubject, Subject, combineLatest, take } from "rxjs";
import { map } from "rxjs/operators";
import * as moment from "moment";
import { environment } from "../environments/environment";
import { StorageService, StorageKeys } from "./storage.service";
import { WindowService } from "./window.service";
import { LogService } from "./log.service";
import { HelperService } from "./helper.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class SupportService {
  public categoriesMap: any = {};
  public q2aCategoriesMap: any = {};
  public sectionsMap: any = {};
  public articlesMap: any = {};
  public ticketMap: any = {};
  public questionsMap: any = {};
  public zdSearchQuery$: BehaviorSubject<string> = new BehaviorSubject("");
  public openTickets$: Subject<any> = new Subject();
  public closedTickets$: Subject<any> = new Subject();

  public searchQueryUpdated$: Subject<string> = new Subject();

  public inProgressTicketArgs: any = {};

  private _openTix: any;
  private _closedTix: any;
  private _zdCategories: any;
  private _zdSections: any;
  private _zdArticles: any;
  private _ticketFields: any;

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private _win: WindowService,
    private _log: LogService,
    private _helpers: HelperService,
    private _userService: UserService
  ) {
    this._helpers.userID = this._userService.user ? this._userService.user.id : undefined;
  }

  public ticketCreated$: EventEmitter<number> = new EventEmitter();
  public ticketCommentAdded$: EventEmitter<number> = new EventEmitter();
  public q2aAnswerAdded$: EventEmitter<number> = new EventEmitter();
  public q2aQuestionAsked$: EventEmitter<number> = new EventEmitter();

  get openTickets() {
    if (!this._openTix) {
      this._openTix = this.storage.getItem(StorageKeys.ZD_OPENTICKETS);
    }
    return this._openTix;
  }
  set openTickets(tickets) {
    this._openTix = tickets;
    this.storage.setItem(StorageKeys.ZD_OPENTICKETS, tickets);
  }

  get closedTickets() {
    if (!this._closedTix) {
      this._closedTix = this.storage.getItem(StorageKeys.ZD_CLOSEDTICKETS);
    }
    return this._closedTix;
  }
  set closedTickets(tickets) {
    this._closedTix = tickets;
    this.storage.setItem(StorageKeys.ZD_CLOSEDTICKETS, tickets);
  }

  get zdCategories() {
    if (!this._zdCategories) {
      this._zdCategories = this.storage.getItem(StorageKeys.ZD_CATEGORIES);
    }
    return this._zdCategories;
  }
  set zdCategories(categories) {
    this._zdCategories = categories;
    this.storage.setItem(StorageKeys.ZD_CATEGORIES, categories);
  }

  get zdSections() {
    if (!this._zdSections) {
      this._zdSections = this.storage.getItem(StorageKeys.ZD_SECTIONS);
    }
    return this._zdSections;
  }
  set zdSections(sections) {
    this._zdSections = sections;
    this.storage.setItem(StorageKeys.ZD_SECTIONS, sections);
  }

  get zdArticles() {
    if (!this._zdArticles) {
      this._zdArticles = this.storage.getItem(StorageKeys.ZD_ARTICLES);
    }
    return this._zdArticles;
  }
  set zdArticles(articles) {
    this._zdArticles = articles;
    this.storage.setItem(StorageKeys.ZD_ARTICLES, articles);
  }

  get ticketFields() {
    if (!this._ticketFields) {
      this._ticketFields = this.storage.getItem(StorageKeys.ZD_TICKETFIELDS);
    }
    return this._ticketFields;
  }
  set ticketFields(fields) {
    this._ticketFields = fields;
    this.storage.setItem(StorageKeys.ZD_TICKETFIELDS, fields);
  }

  getCategories(forceRefresh?) {
    const categories = this.zdCategories;
    if (!forceRefresh && categories) {
      for (let i = 0; categories.length > i; i++) {
        this.categoriesMap[categories[i].id] = categories[i];
      }
      return observableOf(categories);
    } else {
      this._log.info("getting fresh categories");
      return this.http.get(environment.paeApiUrl + "ticket/categories", this._helpers.getHttpOptions()).pipe(
        map((data: any) => {
          let categories = data.categories;
          for (let i = 0; categories.length > i; i++) {
            categories[i].image = encodeURI("https://pae-web.presonusmusic.com/global/images/support/" + categories[i].name + ".png");
            if (categories[i].name == "Studio One v6") categories[i].image = encodeURI("https://pae-web.presonusmusic.com/uploads/products/media/images/SO60_Flagship_Screenshot_Pro_2_big.jpg");
            this.categoriesMap[categories[i].id] = categories[i];
          }
          this.zdCategories = categories;
          return categories;
        })
      );
    }
  }

  public getSavedSectionByCategoryID(category_id) {
    let savedSections = this.zdSections;
    for (let i in savedSections) {
      savedSections[i].forEach((sec) => (this.sectionsMap[sec.id] = sec));
    }
    if (savedSections && savedSections[category_id]) {
      this.sectionsMap[savedSections[category_id].id] = savedSections[category_id];
      return savedSections[category_id];
    } else {
      return false;
    }
  }

  public getSectionsByCategoryId(category_id) {
    return this.http.get(environment.paeApiUrl + "ticket/categories?category_id=" + category_id, this._helpers.getHttpOptions()).pipe(
      map((data: any) => {
        let sections = data.sections;
        for (let i = 0; sections.length > i; i++) {
          this.sectionsMap[sections[i].id] = sections[i];
        }

        let savedSections = this.zdSections;
        if (!savedSections) savedSections = {};
        savedSections[category_id] = sections;
        this.zdSearchQuery$ = savedSections;
        return sections;
      })
    );
  }

  public getSavedArticlesBySectionID(section_id) {
    let savedArticles = this.zdArticles;
    if (savedArticles && savedArticles[section_id]) {
      this.articlesMap[savedArticles[section_id].id] = savedArticles[section_id];
      return savedArticles[section_id];
    } else {
      return false;
    }
  }

  public getArticlesBySection(section_id) {
    return this.http.get(environment.paeApiUrl + "ticket/categories?category_id=0&section_id=" + section_id, this._helpers.getHttpOptions()).pipe(
      map((data: any) => {
        let articles = data.articles;
        for (let i = 0; articles.length > i; i++) {
          articles[i].created_at_pretty = moment(articles[i].created_at).format("LL");
          this.articlesMap[articles[i].id] = articles[i];
        }
        let savedArticles = this.zdArticles;
        if (!savedArticles) savedArticles = {};
        savedArticles[section_id] = articles;
        this.zdArticles = savedArticles;

        return articles;
      })
    );
  }

  public getTicketFields() {
    const fields = this.ticketFields;
    if (fields) {
      this._log.info("getting saved ticket fields");
      return observableOf(fields);
    } else {
      this._log.info("getting fresh ticket fields");
      return this.http.get(environment.paeApiUrl + "ticket/fields", this._helpers.getHttpOptions()).pipe(
        map((data: any) => {
          this.ticketFields = data;
          return data;
        })
      );
    }
  }

  public submitTicket(args) {
    return this.http.post(environment.paeApiUrl + "ticket", args, this._helpers.getHttpOptions()).pipe();
  }

  getOpenTickets(fresh?) {
    let openTix = this.openTickets;
    if (openTix && !fresh) this.openTickets$.next(this.processTickets(openTix));
    if (!openTix || fresh || !this.storage.checkTimestamp("open_tickets")) {
      this.http
        .get(environment.paeApiUrl + "ticket/list?type=open", this._helpers.getHttpOptions())
        .pipe(
          map((data: any) => {
            let tickets = this.processTickets(data.requests);
            this.openTickets = tickets;
            this.storage.setTimestamp("open_tickets", 1);
            this.openTickets$.next(tickets);
          })
        )
        .subscribe();
    }
  }

  getClosedTickets(fresh?) {
    let closedTix = this.closedTickets;
    if (closedTix && !fresh) this.closedTickets$.next(this.processTickets(closedTix));
    if (!closedTix || fresh || !this.storage.checkTimestamp("closed_tickets")) {
      this.http
        .get(environment.paeApiUrl + "ticket/list?type=close", this._helpers.getHttpOptions())
        .pipe(
          map((data: any) => {
            let tickets = this.processTickets(data.requests);
            this.closedTickets = tickets;
            this.storage.setTimestamp("closed_tickets", 72);
            this.closedTickets$.next(tickets);
          })
        )
        .subscribe();
    }
  }

  private processTickets(tickets) {
    if (!tickets || !tickets.length) return [];
    tickets.sort((a, b) => {
      if (moment(a.updated_at) < moment(b.updated_at)) {
        return 1;
      } else if (moment(a.updated_at) > moment(b.updated_at)) {
        return -1;
      }
    });
    tickets.forEach((element) => {
      if (!this.ticketMap[element.id]) this.ticketMap[element.id] = element;
      element.created_at_pretty = moment(element.created_at).format("LL");
      element.updated_at_pretty = moment(element.updated_at).format("LL");
    });
    return tickets;
  }

  public getTicket(id) {
    return new Promise((resolve, reject) => {
      // Combine both ticket streams and take first emission
      combineLatest([this.openTickets$.pipe(take(1)), this.closedTickets$.pipe(take(1))]).subscribe({
        next: () => {
          const ticket = this.ticketMap[id];
          if (ticket) {
            resolve(ticket);
          } else {
            reject(new Error("Ticket not found"));
          }
        },
        error: (err) => reject(err),
      });

      // Trigger ticket fetching
      this.getOpenTickets();
      this.getClosedTickets();
    });
  }

  public addComment(args, ticket_id) {
    return this.http.post(environment.paeApiUrl + "ticket/" + ticket_id + "/comments", args, this._helpers.getHttpOptions()).pipe();
  }

  public getTicketDetails(id) {
    return this.http.get(environment.paeApiUrl + "ticket/" + id + "/comments", this._helpers.getHttpOptions()).pipe(
      map((response: any) => {
        let comments = response;
        comments.forEach(function (comment) {
          comment.created_at_pretty = moment(comment.created_at).format("LLL");
        });
        comments.sort((a, b) => {
          if (moment(a.created_at) > moment(b.created_at)) {
            return 1;
          } else if (moment(a.created_at) < moment(b.created_at)) {
            return -1;
          }
        });
        return comments;
      })
    );
  }

  public searchHelpCenter(query) {
    query = (query || "").replace(/&/g, "");
    return this.http.get(environment.paeApiUrl + "ticket/articles?query=" + encodeURI(query), this._helpers.getHttpOptions()).pipe(
      map((response: any) => {
        if (response && response.results && response.results.length) {
          response.results.forEach((item) => {
            item.created_at_pretty = "Created: " + moment(item.created_at).format("LLL");
            item.updated_at_pretty = "Last updated on " + moment(item.updated_at).format("LLL");
          });
        }
        return response;
      })
    );
  }

  public getQ2ACategories() {
    let id = this._userService.user.id;
    return this.http.get(environment.q2aApiUrl + "categories/", this._helpers.getHttpOptions(true, id)).pipe(
      map((result: Array<any>) => {
        result.forEach((cat) => {
          this.q2aCategoriesMap[cat.categoryid] = cat;
          if (cat.subcategories && cat.subcategories.length) {
            cat.subcategories.forEach((subcat) => {
              this.q2aCategoriesMap[subcat.categoryid] = subcat;
            });
          }
        });
        return result;
      })
    );
  }

  public castQ2AVote(postid, vote) {
    let data = {
      vote: vote,
      handle: this._userService.user.username,
      post_id: postid,
    };
    return this.http.put(environment.q2aApiUrl + "castvote/", { data }, this._helpers.getHttpOptions(true, this._userService.user.id)).pipe();
  }

  public searchQA(query) {
    query = query.replace(/&/g, "");
    return this.http.get(environment.q2aApiUrl + "search/" + encodeURI(query), this._helpers.getHttpOptions(true, this._userService.user.id)).pipe(
      map((response) => {
        let results = response;
        let processedResults = this.processPosts(results);
        return processedResults;
      })
    );
  }

  getCategoryQuestions(id) {
    return this.http.get(environment.q2aApiUrl + "category/" + id, this._helpers.getHttpOptions(true, this._userService.user.id)).pipe(
      map((response) => {
        let results = response;
        let processedResults = this.processPosts(results);
        return processedResults;
      })
    );
  }

  public getMyQuestions() {
    return this.http.get(environment.q2aApiUrl + "userquestions/" + this._userService.user.id, this._helpers.getHttpOptions(true, this._userService.user.id)).pipe(
      map((response) => {
        let results = response;
        let processedResults = this.processPosts(results);
        return processedResults;
      })
    );
  }

  private processPosts(posts) {
    posts.forEach((item) => {
      this.questionsMap[item.postid] = item;
      item.created_at_pretty = moment.unix(item.created).format("ll");
      item.iosContent =
        '<style type="text/css">span { background-color: transparent!important; color: white; } img { max-width: 100%; }</style><div style="font-size: 16px; font-family: Helvetica; color: white!important;">' +
        item.content +
        "</div>";
      item.androidContent = '<div style="font-size: 16px; font-family: Helvetica; color: white!important;">' + item.content + "</div>";

      let selchildindex: any = false;
      let selectedAnswer: any = false;
      item.answers.forEach((answer, index) => {
        answer.iosContent =
          '<style type="text/css">span { background-color: transparent!important; color: white; } img { max-width: 100%; }</style><div style="font-size: 16px; font-family: Helvetica;">' +
          answer.content +
          "</div>";
        answer.androidContent = '<div style="font-size: 16px; font-family: Helvetica;">' + answer.content + "</div>";
        answer.created_at_pretty = moment.unix(answer.created).format("ll");
        if (item.selchildid && item.selchildid == answer.postid) {
          answer.selectedAnswer = true;
          selchildindex = index;
          selectedAnswer = answer;
        }
      });
      if (selectedAnswer) {
        item.answers.splice(selchildindex, 1);
        item.answers.unshift(selectedAnswer);
      }
    });

    return posts;
  }

  public getQuestion(id) {
    return this.questionsMap[id];
  }

  public submitQuestion(data) {
    return this.http.post(environment.q2aApiUrl + "new/", { data }, this._helpers.getHttpOptions(true, this._userService.user.id)).pipe(
      map((response: any) => {
        let results = response;
        if (results.post) {
          let item = results.post;
          this.questionsMap[item.postid] = item;
          item.created_at_pretty = moment.unix(item.created).format("ll");
          if (item.parentid) {
            item.content =
              '<style type="text/css">span { background-color: transparent!important; } image { max-width: 100%; }</style><div style="font-size: 16px; font-family: Helvetica;">' +
              item.content +
              "</div>";
            // if (isIOS) {
            //   item.content = '<style type="text/css">span { background-color: transparent!important; } image { max-width: 100%; }</style><div style="font-size: 16px; font-family: Helvetica;">' + item.content + '</div>';
            // } else {
            //   item.content = '<div style="font-size: 16px; font-family: Helvetica;">' + item.content + '</div>';
            // }
          } else {
            item.content =
              '<style type="text/css">span { background-color: transparent!important; } image { max-width: 100%; }</style><div style="font-size: 16px; font-family: Helvetica; color: white;">' +
              item.content +
              "</div>";
            // if (isIOS) {
            //   item.content = '<style type="text/css">span { background-color: transparent!important; } image { max-width: 100%; }</style><div style="font-size: 16px; font-family: Helvetica; color: white;">' + item.content + '</div>';
            // } else {
            //   item.content = '<div style="font-size: 16px; font-family: Helvetica; color: white;">' + item.content + '</div>';
            // }
          }
        }
        return results;
      })
    );
  }

  clearCache() {
    this.ticketMap = {};
    this.openTickets$.next([]);
    this._openTix = false;
    this.storage.removeItem(StorageKeys.ZD_OPENTICKETS);
    this.closedTickets$.next([]);
    this._closedTix = false;
    this.storage.removeItem(StorageKeys.ZD_CLOSEDTICKETS);
  }
}
