<div
  [class.mustang]="mustang"
  [class.register-custom-ui]="registerCustomUI?.backgroundImage"
  [class.c-white]="mustang || registerCustomUI?.backgroundImage"
  [ngStyle]="{ 'background-image': registerCustomUI?.backgroundImage ? 'url(' + registerCustomUI?.backgroundImage + ')' : '' }"
>
  <div class="modal-header" *ngIf="!mustang && !registerCustomUI">
    <h1 id="modal-title" class="modal-title">{{ "products.register.register_product" | translate }}</h1>
    <button
      type="button"
      ngbAutofocus
      *ngIf="!registering"
      class="fs-close-btn right border-0 bg-transparent p-0"
      (click)="activeModal.dismiss(dismissMessage)"
      data-cy="close-success-splash-button"
      aria-label="close"
    >
      <fa-icon icon="times-circle" class="circle-icon-btn" aria-hidden="true"></fa-icon>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center m-b-20" *ngIf="mustang">
      <img src="./assets/images/mustangmicro-logo-white.svg" alt="Mustang Micro" style="max-width: 250px" />
    </div>
    <div class="text-center m-b-20 m-t-20" *ngIf="registerCustomUI?.headerImage">
      <img [src]="registerCustomUI.headerImage" style="max-width: 250px" />
    </div>
    <div class="modal-loader m-y-20 text-center" *ngIf="registering" role="status" aria-live="assertive">
      <myp-inline-loader [loading]="registering" height="100px" spinnerName="registeringProductLoader" aria-hidden="true"></myp-inline-loader>
      <span>{{ loadingProgressMessage }}</span>
      <br />
      <br />
      <span class="t-12">Please do not go back, refresh your browser, or close this window.</span>
    </div>
    <div class="form-group" *ngIf="!registering && !registerResponse">
      <div *ngIf="optionCardOptions && optionCardOptions.asset_ids.length > 1">
        {{ "product_detail.option_cards.choose_opt_card_asset" | translate }}
        <select class="form-control" [(ngModel)]="selectedOptionCardAssetId">
          <option *ngFor="let opt of optionCardOptions.asset_ids" [value]="opt.asset_id">{{ opt.serial }}</option>
        </select>
      </div>
      <div *ngIf="mustang">Locate the serial number on your new Mustang Micro Plus and enter it below to redeem {{ env.studioOnePlusBrandName }}.</div>
      <div *ngIf="registerCustomUI?.blurb">{{ registerCustomUI.blurb }}</div>
      <div *ngIf="!mustang && !registerCustomUI?.hardwareOnly">
        <div id="modal_label" class="small form-text text-muted mb-0">{{ "products.register.what_type_register" | translate }}</div>
        <myp-segmented-bar
          [white]="true"
          (selected)="selectedType = $event.id"
          ariaLabelledBy="modal_label"
          [workAsRadioButton]="true"
          [options]="[
            {
              name: ('products.register.soft_or_ext' | translate),
              id: 'software',
              selected: true,
            },
            {
              name: ('products.register.hardware' | translate),
              id: 'hardware',
            },
          ]"
        ></myp-segmented-bar>
      </div>

      <br />
      <fieldset *ngIf="selectedType == 'software'">
        <legend class="control-label" for="productkey">
          <label for="productkey" class="control-label">
            {{ "products.register.enter_key" | translate }}
            <sup><code>*</code></sup>
          </label>
        </legend>

        <input
          id="productkey"
          ngbAutofocus
          class="form-control mono text-md"
          (keydown)="keyPressed($event)"
          guide="false"
          [maskito]="{ mask: mask }"
          [(ngModel)]="productKey"
          aria-describedby="key_length_instructions"
          required
        />
        <small class="form-text pad text-muted" id="key_length_instructions">{{ "products.register.key_length_desc" | translate }}</small>
        <br />
      </fieldset>
      <div *ngIf="selectedType == 'hardware'">
        <fieldset class="form-group" *ngIf="!mustang">
          <legend>
            <strong>
              <label class="control-label" for="hw_purchase_date">
                {{ "products.register.enter_purchase_date" | translate }}
                <sup><code>*</code></sup>
              </label>
            </strong>
          </legend>
          <div
            class="form-control pointer"
            [class.register-custom-ui-input]="registerCustomUI?.backgroundImage"
            [class.c-white]="registerCustomUI?.backgroundImage"
            mode="button"
            name="hw_purchase_date"
            id="hw_purchase_date"
            (click)="showDatePicker()"
          >
            {{ date ? (date | date) : "Choose a date..." }}
          </div>
        </fieldset>

        <fieldset class="form-group">
          <legend>
            <strong>
              <label class="control-label" for="hw_serial_number">
                {{ "products.register.enter_serial_number" | translate }}
                <sup><code>*</code></sup>
              </label>
            </strong>
          </legend>
          <input
            type="text"
            data-cy="serial-number-input"
            [(ngModel)]="productKey"
            (keydown)="keyPressed($event)"
            class="form-control mono text-md"
            [class.mustang-input]="mustang"
            [class.register-custom-ui-input]="registerCustomUI?.backgroundImage"
            placeholder=""
            id="hw_serial_number"
            required
          />
        </fieldset>
        <div class="m-b-20" *ngIf="hasUsbAccess">
          <button class="btn btn-secondary btn-sm" (click)="detectSerial()">Detect Serial Number</button>
          <div id="modal_label" class="small form-text text-muted mb-0">
            If your device is connected to your computer, click the button above and select it to automatically fill out the serial number.
          </div>
        </div>
        <button
          role="button"
          tabindex="0"
          class="bare_btn sm border-0 bg-transparent m-t-10"
          [ngbPopover]="popContent"
          [popoverTitle]="popTitle"
          #popover="ngbPopover"
          [attr.aria-expanded]="popover.isOpen()"
          aria-controls="ngbPopover"
          *ngIf="!mustang && !registerCustomUI"
        >
          {{ "products.register.where_find_sn" | translate }}
        </button>
      </div>

      <div *ngIf="errorMsg" class="error-box" [innerHTML]="errorMsg"></div>
    </div>

    <div *ngIf="!registering && registerResponse" class="text-center">
      <fa-icon icon="check-circle" class="c-green" style="font-size: 35px"></fa-icon>
      <br />
      <br />

      {{ optionCardOptions ? "products.register.optioncard_success" : ("products.register.product_success" | translate) }}
      <br />
      <br />
      <div *ngIf="registeredProduct && registeredProduct.navId == 'ABOX96KULTIMATE'">
        {{ "products.register.eris_dontforget" | translate }}
        <br />
        <br />
        <button class="btn btn-primary" (click)="resetRegisterFlow('hardware')">{{ "products.register.register_eris" | translate }}</button>
        <br />
        <br />
      </div>
      <div *ngIf="registeredProduct && registeredProduct.navId == 'ATOMPRODUCERSLAB'">
        {{ "products.register.atom_dontforget" | translate }}
        <br />
        <br />
        <button class="btn btn-primary" (click)="resetRegisterFlow('hardware')">{{ "products.register.register_atom" | translate }}</button>
        <br />
        <br />
      </div>
      <div *ngIf="registeredProduct && registeredProduct.navId == 'FADERPORT8'">
        {{ "products.register.faderport_new_firmware" | translate }}
        <br />
        <br />
        <a class="btn btn-primary" href="http://www.presonus.com/products/FaderPort-8/downloads" target="_blank">{{ "products.register.download_uc" | translate }}</a>
        <br />
        <br />
      </div>
      <div *ngIf="rewards && rewards.length == 1">
        <span [translate]="'products.register.register_reward'" [translateParams]="{ rewardTitle: registerResponse.reward.title }"></span>
        <br />
      </div>
      <div *ngIf="rewards && rewards.length > 1">
        <span>{{ "sphere.congrats_promo_product" | translate }}</span>
        <br />
        <br />
        <div *ngFor="let reward of rewards">
          <strong>{{ reward.title }}</strong>
        </div>
      </div>

      <button type="button" class="btn btn-primary" *ngIf="!optionCardOptions" (click)="viewRegisteredProduct(registerResponse.assetId || registerResponse.licenseId)">
        {{ "general.view_product" | translate }}
      </button>
      <button type="button" class="btn btn-primary" [class.c-white]="mustang || registerCustomUI?.backgroundImage" *ngIf="optionCardOptions" (click)="activeModal.close()">
        {{ "general.close" | translate }}
      </button>
    </div>
  </div>
  <div class="modal-footer" ngbAutofocus *ngIf="!registering && !registerResponse">
    <button type="button" class="btn btn-primary" data-cy="register-button" (click)="performRegister()">
      {{ (upgrading ? "product_detail.upgrade.upgrade" : mustang ? "Redeem" : "products.register.register") | translate }}
    </button>
    <button type="button" class="btn btn-blank" [class.c-white]="mustang || registerCustomUI?.backgroundImage" (keydown)="$event.stopPropagation()" (click)="close($event)">
      {{ "general.close" | translate }}
    </button>
  </div>

  <ng-template #popContent>
    {{ "products.register.sn_location_desc" | translate }}
  </ng-template>
  <ng-template #popTitle>
    <span style="color: #333">{{ "products.register.where_find_sn" | translate }}</span>
  </ng-template>
</div>
