import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { map, take } from "rxjs/operators";
import { of as observableOf, BehaviorSubject, Subject } from "rxjs";
import { environment } from "../environments/environment";
import { StorageService, StorageKeys } from "./storage.service";
import { WindowService } from "./window.service";
import { LogService } from "./log.service";
import * as moment from "moment";
import { HelperService } from "./helper.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private window: WindowService,
    private _log: LogService,
    private _helpers: HelperService,
    public userService: UserService
  ) {
    this._helpers.userID = this.userService.user ? this.userService.user.id : undefined;
  }

  public shopOrderPlaced$: EventEmitter<string> = new EventEmitter();

  private _orders: any;
  get orders() {
    if (!this._orders) this._orders = this.storage.getItem(StorageKeys.ORDERS);
    return this._orders;
  }

  set orders(orders) {
    this._orders = orders;
    this.storage.setItem(StorageKeys.ORDERS, orders);
  }

  public orders$: Subject<any> = new Subject();
  public recentOrders$: Subject<any> = new Subject();
  getOrders(fresh?, recent?) {
    return this.http.get(environment.paeApiUrl + `user/orders`, this._helpers.getHttpOptions());
  }

  /** getOrder() Returns a cached order detail. */
  getOrder(id) {
    var order = this.orders.filter((o) => o.id == id)[0];
    return order;
  }

  clearCache() {
    // this.orders$.next(false);
    // this.recentOrders$.next(false);
    this._orders = false;
    this.storage.removeItem(StorageKeys.ORDERS);
  }
}
