<div class="column-layout-container">
  <div class="menu animate-in-from-left" [class.mobile-shown]="appService.mobileMenuShown">
    <div class="menu-top d-flex justify-content-between align-items-center menu-title">
      {{ "com.messages" | translate }}
      <div>
        <button class="fa-button m-r-10" placement="bottom" [ngbTooltip]="'com.send_new_message' | translate" (click)="showCreateMessage()">
          <fa-icon icon="plus" class="action create-action circle-icon-btn"></fa-icon>
        </button>
      </div>
    </div>

    <myp-inline-loader [loading]="loading" height="200px" spinnerName="messagesLoader"></myp-inline-loader>

    <div *ngIf="!loading && !threads?.length" class="m-y-20 m-x-10">{{ "com.no_messages" | translate }}</div>
    <div *ngIf="threads && threads.length">
      <div class="item-filter m-r-10 m-b-10" *ngIf="threads.length > 3">
        <div class="filter-input no-max-width">
          <input type="text" [(ngModel)]="filter" (ngModelChange)="doFilter()" [placeholder]="'com.type_to_filter' | translate" />
          <div class="clear" (click)="filter = ''; doFilter()" *ngIf="filter"><fa-icon icon="times"></fa-icon></div>
        </div>
      </div>
      <ul class="menu-options">
        <ng-container *ngFor="let thread of displayedThreads">
          <ng-container *ngTemplateOutlet="threadItem; context: { thread, archived: false }"></ng-container>
        </ng-container>
        <ng-container *ngIf="archivedThreadsVisible">
          <ng-container *ngFor="let thread of archivedThreads">
            <ng-container *ngTemplateOutlet="threadItem; context: { thread, archived: true }"></ng-container>
          </ng-container>
        </ng-container>
        <li class="menu-option">
          <button *ngIf="archivedThreads.length && !filter" class="small-btn d-block mx-auto my-3" (click)="toggleArchivedThreads()">
            <span *ngIf="!this.archivedThreadsVisible">View</span>
            <span *ngIf="this.archivedThreadsVisible">Hide</span>
            Archived Threads
          </button>
        </li>
      </ul>
    </div>
  </div>
  <div class="content animate-in-from-right" id="messages-cmp-content" [class.mobile-shown]="!appService.mobileMenuShown">
    <div class="mobile-back-btn" (click)="appService.mobileMenuShown = true">
      <fa-icon icon="chevron-left"></fa-icon>
      {{ "com.messages" | translate }}
    </div>

    <div class="content-constrained">
      <ng-container *ngIf="!selectedThread">
        <div *ngIf="noThreadSelected" class="w-full h-full d-flex justify-content-center align-items-center">
          <span class="m-y-100">{{ "com.no_convo_selected" | translate }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="selectedThread">
        <myp-messages-detail #messageDetailCmp (unsupportedFileChosen)="unsupportedFileChosen($event)" (threadUnarchived)="setupThreads()" [thread]="selectedThread"></myp-messages-detail>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #threadItem let-thread="thread" let-archived="archived">
  <li
    *ngIf="thread.connection"
    tabindex="0"
    class="menu-option detailed"
    (keyup.enter)="appService.mobileMenuShown = false; selectThread(thread)"
    (click)="appService.mobileMenuShown = false; selectThread(thread)"
    [class.active]="thread.id == selectedThread?.id"
    [class.archived]="archived"
  >
    <div class="primary-option">
      <div class="d-flex align-items-center">
        <img
          class="image-border round align-top"
          [class.viewing]="communityService.viewing[thread.id] && communityService.viewing[thread.id][thread.them]"
          [src]="thread.connection?.photoURL || './assets/images/default_avatar.jpg'"
        />
        <div>
          <span class="title">{{ thread.connection?.firstName + " " + thread.connection?.lastName }}</span>
          <div *ngIf="thread.latestMessage">
            <span class="msg-extra-info">{{ helperService.stripHtml(thread.latestMessage.message) }}</span>
            <span class="msg-extra-info-date">{{ thread.latestMessage.timestamp.toDate() | date: "MMM Do [at] hh:mm" : true }}</span>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <fa-icon icon="ellipsis-h" (click)="messageOptions(thread, $event)" placement="left" [ngbTooltip]="'sphere.options' | translate" class="action circle-icon-btn"></fa-icon>
        <span
          class="unread-messages-badge"
          *ngIf="communityService.activeThread?.id != thread.id && communityService.unreadCounts.threads[thread.id] && communityService.unreadCounts.threads[thread.id] > 0"
        >
          {{ communityService.unreadCounts.threads[thread.id] }}
        </span>
      </div>
    </div>
  </li>
</ng-template>
