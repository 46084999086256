import { Component, Input, Output, EventEmitter } from "@angular/core";

import { ActivateOfflineBaseComponent } from "@mypxplat/xplat/features";
import { StorageService, UserService, ProductService, WindowService, ProductLicenseItem } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AppService } from "@mypxplat/xplat/web/core";

@Component({
  selector: "myp-activate-offline",
  templateUrl: "activate-offline.component.html",
})
export class ActivateOfflineComponent extends ActivateOfflineBaseComponent {
  @Input() license: ProductLicenseItem;
  @Output() activationCompleted: EventEmitter<any> = new EventEmitter();
  public computerKey: string;
  public computerName: string;
  public loading: boolean = false;

  constructor(
    storageService: StorageService,
    userService: UserService,
    public activeModal: NgbActiveModal,
    public productService: ProductService,
    public win: WindowService,
    public appService: AppService
  ) {
    super(userService);
  }

  performActivation() {
    if (this.computerKey && this.computerName) {
      let args = {
        computerKey: this.computerKey,
        computerName: this.computerName,
        assetId: this.license.licenseId,
      };
      this.loading = true;
      this.productService.activateSoftware(args).subscribe({
        next: (result) => {
          this.loading = false;
          this.activationCompleted.next(result);
          this.activeModal.close();
        },
        error: (error) => {
          this.loading = false;
          this.appService.alertError(error);
        },
      });
    } else {
      this.win.alert("Please enter the activation code and a name for your computer.");
    }
  }
}
