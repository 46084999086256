import { OnInit, EventEmitter, Directive } from "@angular/core";
import { BaseComponent, UserService, StorageService, EventBusService, StorageKeys, WindowService } from "@mypxplat/xplat/core";
import { takeUntil } from "rxjs/operators";

@Directive()
export abstract class VideoPlayerBaseComponent extends BaseComponent implements OnInit {
  public video: any;
  public videoReady = true;
  public videoInitted: EventEmitter<any> = new EventEmitter();
  public minimized: boolean = false;

  constructor(
    public _storageService: StorageService,
    userService: UserService,
    public eventBusService: EventBusService
  ) {
    super(userService);
  }

  get url() {
    if (this.video.platform) {
      return this.video.platform == "youtube"
        ? "https://www.youtube.com/embed/" + this.video.platform_id + "?autoplay=1&enablejsapi=1"
        : "https://player.vimeo.com/video/" + this.video.platform_id + "?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=173584";
    } else {
      return this.video.type == "youtube"
        ? "https://www.youtube.com/embed/" + this.video.id + "?autoplay=1&enablejsapi=1"
        : "https://player.vimeo.com/video/" + this.video.id + "?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=173584";
    }
  }

  ngOnInit() {
    this.eventBusService
      .observe(this.eventBusService.types.playVideo)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (!this.video) {
          this.video = result;
          this.videoReady = true;
          this.videoInitted.next(result);
        }
      });
  }
}
