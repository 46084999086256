<div class="modal-header" *ngIf="!preview || (splash && splash.headerText)">
  <h4 class="modal-title">{{ preview && splash && splash.headerText ? splash.headerText : "Add/Edit Product Splash" }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="addEditSplashLoader"></myp-inline-loader>
  <div *ngIf="!loading">
    <h4 class="section-header m-b-10 m-t-20" *ngIf="!preview" [ngStyle]="{ opacity: successSplashCollapsed ? '0.5' : '1' }">
      Successful Product Registration Splash
      <span class="section-header-link" (click)="successSplashCollapsed = !successSplashCollapsed">
        {{ successSplashCollapsed ? ("sphere.expand" | translate) : ("sphere.collapse" | translate) }}
        <fa-icon [icon]="successSplashCollapsed ? 'chevron-down' : 'chevron-up'"></fa-icon>
      </span>
    </h4>
    <ng-container *ngIf="!successSplashCollapsed">
      <myp-segmented-bar
        *ngIf="!preview || preview == 'successSplash'"
        (selected)="togglePreview('successSplash')"
        [options]="[
          {
            name: 'Edit',
            id: 'edit',
            selected: true,
          },
          {
            name: 'Preview',
            id: 'preview',
          },
        ]"
      ></myp-segmented-bar>
      <div class="m-t-10" *ngIf="!preview">
        <div class="form-group m-t-20">
          <label class="control-label" for="id">Unique String ID</label>
          <small *ngIf="exists" class="form-text text-muted">
            This ID is what will determine the url route at which Connect will show preregistration data. For example, if you set it to "quantum", and set up a preregistration UI, users will see that
            UI at connect when they go to connect.presonus.com/product/quantum.
          </small>
          <input type="text" name="id" [disabled]="exists" id="id" [(ngModel)]="splash.id" class="form-control" />
        </div>

        <div class="form-check">
          <input class="form-check-input" type="checkbox" [(ngModel)]="splash.modalBackground" id="modalBackground" />
          <label class="form-check-label" for="modalBackground">Show a background in the modal overlay, instead of the html on top of a blurred background.</label>
        </div>

        <div class="form-group m-t-20" *ngIf="splash.modalBackground">
          <label class="control-label" for="id">Modal Header Title</label>
          <small class="form-text text-muted">Leave this blank to have no header on the modal.</small>
          <input type="text" name="id" id="id" [(ngModel)]="splash.headerText" class="form-control" />
        </div>

        <div class="form-group m-t-20">
          <label class="control-label" for="description">Products</label>
          <small class="form-text text-muted">When a user registers one of these products, they will see this success splash modal.</small>
          <input class="form-control" [(ngModel)]="associatedProductsQuery" />
        </div>
        <div class="product-results m-b-30 trans-bg border-radius10 p-10" *ngIf="associatedProductsQuery && associatedProductResults && associatedProductResults.length">
          <ng-container>
            <myp-list-item *ngFor="let product of associatedProductResults" [title]="product.title" [subData]="[product.DTYPE]" [includeChevron]="false" [showImage]="false">
              <div class="button-content">
                <fa-icon icon="plus" placement="left" [ngbTooltip]="'Choose Product'" (click)="setAssociatedProd(product)" class="action green circle-icon-btn m-r-10"></fa-icon>
              </div>
            </myp-list-item>
            <div class="m-t-20 text-center">
              <button class="btn btn-secondary btn-sm" (click)="associatedProductsQuery = ''; associatedProductResults = []">Close</button>
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="splash.string_ids?.length">
          <myp-list-item *ngFor="let item of splash.string_ids" [showImage]="false" [showBackground]="true" marginBottom="5px" [title]="item" [includeChevron]="false">
            <div class="button-content">
              <fa-icon icon="times" placement="left" [ngbTooltip]="'Remove Product'" (click)="removeAssociatedProduct(item)" class="action circle-icon-btn"></fa-icon>
            </div>
          </myp-list-item>
        </ng-container>

        <div class="form-check m-t-20">
          <input class="form-check-input" type="checkbox" [(ngModel)]="enableProductDownloads" id="enableProductDownloads" />
          <label class="form-check-label" for="enableProductDownloads">Show downloads for a product in the success overlay.</label>
        </div>

        <div class="m-t-10" *ngIf="enableProductDownloads">
          <ng-container *ngIf="!splash.downloadsProductId">
            <div class="form-group m-t-20">
              <label class="control-label" for="description">Use Downloads From This Product</label>
              <small class="form-text text-muted">Choose which product you would like to use to display downloads in the success modal.</small>
              <input class="form-control" [(ngModel)]="productDownloadsQuery" />
            </div>
            <div class="product-results m-b-30 trans-bg border-radius10 p-10" *ngIf="productDownloadsQuery && productDownloadsResults && productDownloadsResults.length">
              <ng-container>
                <myp-list-item *ngFor="let product of productDownloadsResults" [title]="product.title" [subData]="[product.DTYPE]" [includeChevron]="false" [showImage]="false">
                  <div class="button-content">
                    <fa-icon
                      [icon]="settingDownloadsProduct == product.id ? 'spinner' : 'plus'"
                      [animation]="settingDownloadsProduct == product.id ? 'spin' : ''"
                      placement="left"
                      [ngbTooltip]="'Choose Product'"
                      (click)="setDownloadsProduct(product)"
                      class="action green circle-icon-btn m-r-10"
                    ></fa-icon>
                  </div>
                </myp-list-item>
                <div class="m-t-20 text-center">
                  <button class="btn btn-secondary btn-sm" (click)="productDownloadsQuery = ''; productDownloadsResults = []">Close</button>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <fa-icon icon="spinner" animation="spin" *ngIf="gettingProductDownloadsProduct"></fa-icon>
          <myp-list-item *ngIf="selectedDownloadsProduct" [showImage]="false" [showBackground]="true" marginBottom="5px" [title]="selectedDownloadsProduct.title" [includeChevron]="false">
            <div class="button-content">
              <fa-icon icon="times" placement="left" [ngbTooltip]="'Remove Product'" (click)="removeSelectedDownloadsProduct(item)" class="action circle-icon-btn"></fa-icon>
            </div>
          </myp-list-item>

          <div class="form-check m-t-20">
            <input class="form-check-input" type="checkbox" [(ngModel)]="splash.onlyShowDownloadsForUnauth" id="onlyShowDownloadsForUnauth" />
            <label class="form-check-label" for="onlyShowDownloadsForUnauth">Only show downloads when the user is not logged in (has skipped authentication).</label>
          </div>
        </div>

        <div class="form-group m-t-20">
          <label class="control-label" for="description">Splash Content</label>
          <small class="form-text text-muted">Compose your HTML in an HTML editor an then paste it here.</small>
          <textarea type="text" name="description" id="description" style="height: 300px" [(ngModel)]="splash.description" class="form-control"></textarea>
          <small class="form-text text-muted">
            If this splash is for hardware that includes bundled {{ env.studioOnePlusBrandName }} time, include a div with the ID "bundle_time_descriptor". That div will be populated with a
            description of the status of your bundled software, if applicable.
          </small>
        </div>
        <hr />
      </div>
    </ng-container>

    <h4 class="section-header m-b-10 m-t-20" *ngIf="!preview" [ngStyle]="{ opacity: preregistrationUICollapsed ? '0.5' : '1' }">
      Pre-Authentication Custom UI
      <span class="section-header-link" (click)="preregistrationUICollapsed = !preregistrationUICollapsed">
        {{ preregistrationUICollapsed ? ("sphere.expand" | translate) : ("sphere.collapse" | translate) }}
        <fa-icon [icon]="preregistrationUICollapsed ? 'chevron-down' : 'chevron-up'"></fa-icon>
      </span>
    </h4>
    <ng-container *ngIf="!preregistrationUICollapsed">
      <myp-segmented-bar
        *ngIf="(!preview || preview == 'preregistrationUI') && enablePreregistrationCustomUI"
        (selected)="togglePreview('preregistrationUI')"
        [options]="[
          {
            name: 'Edit',
            id: 'edit',
            selected: true,
          },
          {
            name: 'Preview',
            id: 'preview',
          },
        ]"
      ></myp-segmented-bar>
      <div class="m-t-10" *ngIf="!preview">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" [(ngModel)]="enablePreregistrationCustomUI" id="preregistrationCustomUI" />
          <label class="form-check-label" for="preregistrationCustomUI">Enable a pre-authentication custom UI in Connect.</label>
        </div>
        <div class="m-t-10" *ngIf="enablePreregistrationCustomUI">
          <div class="text-muted">These settings apply to Connect. When a user goes to connect.presonus.com/product/{{ splash.id }}, they will see a custom UI that you can define here.</div>
          <div class="form-group m-t-10">
            <label class="control-label" for="id">Title</label>
            <small class="form-text text-muted">This title will show above the sign in/sign up UI.</small>
            <input type="text" name="title" id="title" [(ngModel)]="preregistrationCustomUI.title" class="form-control" />
          </div>
          <div class="form-group m-t-10">
            <label class="control-label" for="id">Blurb</label>
            <small class="form-text text-muted">These are brief instructions to give to the user, relevant to the product registration process.</small>
            <input type="text" name="blurb" id="blurb" [(ngModel)]="preregistrationCustomUI.blurb" class="form-control" />
          </div>
          <div class="form-group m-t-10">
            <label class="control-label">Image</label>
            <small class="form-text text-muted">This image displays above the authentication form in Connect.</small>
            <div
              ng2FileDrop
              class="content-bg content-drop-zone m-b-20"
              *ngIf="(!preAuthImageUploader || !preAuthImageUploader.queue.length) && !preregistrationCustomUI?.imageUrl"
              [class.hovering]="hoveringFileDrops.preAuthImageUploader"
              (fileOver)="fileOverBase($event, 'preAuthImageUploader')"
              [uploader]="preAuthImageUploader"
            >
              <div>Drop your image file here.</div>
            </div>
            <div class="content-bg m-b-20 d-flex align-items-center" *ngIf="selectedFiles.preAuthImageUploader || preregistrationCustomUI?.imageUrl">
              <img [src]="displayImages?.preAuthImageUploader || preregistrationCustomUI?.imageUrl" style="width: 40px; margin-right: 10px" />
              <div class="d-flex m-x-10 align-items-center" *ngIf="fileUploadProgress.preAuthImageUploader">
                <fa-icon icon="spinner" [animation]="'spin'" class="m-r-5"></fa-icon>
                <strong>{{ fileUploadProgress.preAuthImageUploader }}%</strong>
              </div>
              <div class="d-flex justify-content-between align-items-center w-full">
                <div class="text-truncate overflow-hidden">
                  <span class="brandfont-black">
                    {{ selectedFiles.preAuthImageUploader?.file?.name || preregistrationCustomUI?.imageUrl.split("/")[preregistrationCustomUI?.imageUrl.split("/").length - 1] }}
                  </span>
                </div>
                <button class="btn btn-sm btn-secondary" (click)="removeImage('preAuthImageUploader')">Remove</button>
              </div>
            </div>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [(ngModel)]="preregistrationCustomUI.allowSkipRegistration" id="allowSkipRegistration" />
            <label class="form-check-label" for="allowSkipRegistration">
              Allow the user to skip logging in or signing up for an account and view the splash content and download product materials (if applicable).
            </label>
          </div>
        </div>
        <hr />
      </div>
    </ng-container>

    <h4 class="section-header m-b-10 m-t-20" [ngStyle]="{ opacity: customProductRegistrationUICollapsed ? '0.5' : '1' }" *ngIf="!preview">
      Custom Product Registration UI
      <span class="section-header-link" (click)="customProductRegistrationUICollapsed = !customProductRegistrationUICollapsed">
        {{ customProductRegistrationUICollapsed ? ("sphere.expand" | translate) : ("sphere.collapse" | translate) }}
        <fa-icon [icon]="customProductRegistrationUICollapsed ? 'chevron-down' : 'chevron-up'"></fa-icon>
      </span>
    </h4>
    <ng-container *ngIf="!customProductRegistrationUICollapsed">
      <myp-segmented-bar
        *ngIf="(!preview || preview == 'registerProductUI') && enableRegisterCustomUI"
        (selected)="togglePreview('registerProductUI')"
        [options]="[
          {
            name: 'Edit',
            id: 'edit',
            selected: true,
          },
          {
            name: 'Preview',
            id: 'preview',
          },
        ]"
      ></myp-segmented-bar>
      <div class="m-t-10" *ngIf="!preview">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" [(ngModel)]="enableRegisterCustomUI" id="registerCustomUI" />
          <label class="form-check-label" for="registerCustomUI">Enable a custom product registration UI in MyPreSonus.</label>
        </div>
        <div class="m-t-10" *ngIf="enableRegisterCustomUI">
          <div class="text-muted">
            These settings apply to MyPreSonus. When a user goes to my.presonus.com/home?register={{ splash.id }}, they will see a custom product registration modal UI that you can define here.
          </div>
          <div class="form-group m-t-10">
            <label class="control-label" for="id">Blurb</label>
            <small class="form-text text-muted">These are brief instructions to give to the user that will display at the top of the product registration modal.</small>
            <input type="text" name="blurb" id="blurb" [(ngModel)]="registerCustomUI.blurb" class="form-control" />
          </div>

          <div class="form-group m-t-10">
            <label class="control-label">Header Image</label>
            <small class="form-text text-muted">This image displays above the product registration form.</small>
            <div
              ng2FileDrop
              class="content-bg content-drop-zone m-b-20"
              *ngIf="(!prodRegHeaderImageUploader || !prodRegHeaderImageUploader.queue.length) && !registerCustomUI?.headerImage"
              [class.hovering]="hoveringFileDrops.prodRegHeaderImageUploader"
              (fileOver)="fileOverBase($event, 'prodRegHeaderImageUploader')"
              [uploader]="prodRegHeaderImageUploader"
            >
              <div>Drop your image file here.</div>
            </div>

            <div class="content-bg m-b-20 d-flex align-items-center" *ngIf="selectedFiles.prodRegHeaderImageUploader || registerCustomUI?.headerImage">
              <img [src]="displayImages?.prodRegHeaderImageUploader || registerCustomUI?.headerImage" style="width: 40px; margin-right: 10px" />
              <div class="d-flex m-x-10 align-items-center" *ngIf="fileUploadProgress.prodRegHeaderImageUploader">
                <fa-icon icon="spinner" [animation]="'spin'" class="m-r-5"></fa-icon>
                <strong>{{ fileUploadProgress.prodRegHeaderImageUploader }}%</strong>
              </div>
              <div class="d-flex justify-content-between align-items-center w-full">
                <div class="text-truncate overflow-hidden">
                  <span class="brandfont-black">
                    {{ selectedFiles.prodRegHeaderImageUploader?.file?.name || registerCustomUI?.headerImage.split("/")[registerCustomUI?.headerImage.split("/").length - 1] }}
                  </span>
                </div>
                <button class="btn btn-sm btn-secondary" (click)="removeImage('prodRegHeaderImageUploader')">Remove</button>
              </div>
            </div>
          </div>

          <div class="form-group m-t-10">
            <label class="control-label">Background Image</label>
            <small class="form-text text-muted">
              This image will be the background of the modal. If supplied, the text will be white, so the background image must be
              <strong>dark</strong>
              and the content must not interfere with text. Make sure you preview this before submitting.
            </small>
            <div
              ng2FileDrop
              class="content-bg content-drop-zone m-b-20"
              *ngIf="(!prodRegBgImageUploader || !prodRegBgImageUploader.queue.length) && !registerCustomUI?.backgroundImage"
              [class.hovering]="hoveringFileDrops.prodRegBgImageUploader"
              (fileOver)="fileOverBase($event, 'prodRegBgImageUploader')"
              [uploader]="prodRegBgImageUploader"
            >
              <div>Drop your image file here.</div>
            </div>

            <div class="content-bg m-b-20 d-flex align-items-center" *ngIf="selectedFiles.prodRegBgImageUploader || registerCustomUI?.backgroundImage">
              <img [src]="displayImages?.prodRegBgImageUploader || registerCustomUI?.backgroundImage" style="width: 40px; margin-right: 10px" />
              <div class="d-flex m-x-10 align-items-center" *ngIf="fileUploadProgress.prodRegBgImageUploader">
                <fa-icon icon="spinner" [animation]="'spin'" class="m-r-5"></fa-icon>
                <strong>{{ fileUploadProgress.prodRegBgImageUploader }}%</strong>
              </div>
              <div class="d-flex justify-content-between align-items-center w-full">
                <div class="text-truncate overflow-hidden">
                  <span class="brandfont-black">
                    {{ selectedFiles.prodRegBgImageUploader?.file?.name || registerCustomUI?.backgroundImage.split("/")[registerCustomUI?.backgroundImage.split("/").length - 1] }}
                  </span>
                </div>
                <button class="btn btn-sm btn-secondary" (click)="removeImage('prodRegBgImageUploader')">Remove</button>
              </div>
            </div>
          </div>

          <div class="form-check">
            <input class="form-check-input" type="checkbox" [(ngModel)]="registerCustomUI.hardwareOnly" id="hardwareOnly" />
            <label class="form-check-label" for="hardwareOnly">The product registration modal is for hardware only.</label>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="text-center m-t-20" *ngIf="preview == 'successSplash'">
      <h4 class="section-header justify-content-center">Successful Product Registration Splash</h4>
      This is what users will see when they successfully register a product (plus confetti).
      <hr />
      <div [innerHTML]="splash.description ? (splash.description | safe: 'html') : 'No description entered yet...'"></div>
    </div>
    <div class="text-center m-t-20" *ngIf="preview == 'preregistrationUI'">
      <h4 class="section-header justify-content-center">Pre-Authentication Custom UI</h4>
      This is what users will see when they go to connect.presonus.com/product/{{ splash.id }}.
      <hr />
      <div>
        <div class="d-flex">
          <img src="./assets/images/connect-bg-s17.webp" style="width: 60%" />
          <div class="d-flex flex-column justify-content-center align-items-center">
            <img [src]="appService.presonusLogo" style="width: 40%" class="m-b-10" />
            <span class="d-block text-center t-12 bold m-b-10" *ngIf="preregistrationCustomUI?.title">{{ preregistrationCustomUI?.title }}</span>
            <img *ngIf="displayImages?.preAuthImageUploader || preregistrationCustomUI?.imageUrl" [src]="displayImages?.preAuthImageUploader || preregistrationCustomUI?.imageUrl" style="width: 60%" />
            <span class="d-block text-center t-10 m-b-10 m-t-10" *ngIf="preregistrationCustomUI?.blurb">{{ preregistrationCustomUI?.blurb }}</span>
            <div style="scale: 0.6">
              <input class="form-control m-b-5" />
              <input class="form-control m-b-5" />
              <div style="height: 30px; background-color: #0083ff; border-radius: 10px"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div [innerHTML]="splash.description ? (splash.description | safe: 'html') : 'No description entered yet...'"></div> -->
    </div>
    <div class="text-center m-t-20" *ngIf="preview == 'registerProductUI'">
      <h4 class="section-header justify-content-center">Custom Product Registration UI</h4>
      This is what users will see after the authenticate and the register product modal is opened, or when they go directly to my.presonus.com/home?register={{ splash.id }}.
      <hr />
      <div
        class="content-bg"
        [class.c-white]="registerCustomUI?.backgroundImage"
        [ngStyle]="{ 'background-image': registerCustomUI?.backgroundImage ? 'url(' + registerCustomUI?.backgroundImage + ')' : null }"
        style="max-width: 600px; border-radius: 20px; padding: 20px; margin: auto; background-size: cover; background-position: bottom right"
      >
        <div class="m-b-20" *ngIf="displayImages?.prodRegHeaderImageUploader || registerCustomUI?.headerImage">
          <img [src]="displayImages?.prodRegHeaderImageUploader || registerCustomUI?.headerImage" style="width: 50%" />
        </div>
        <div class="text-left">
          <div *ngIf="registerCustomUI?.blurb" class="m-b-20">
            <span>{{ registerCustomUI.blurb }}</span>
          </div>
          <span class="t-12">Enter a purchase date.</span>
          <br />
          <input
            [ngStyle]="{
              'background-color': registerCustomUI?.backgroundImage ? 'rgba(0, 0, 0, 0.45) !important' : 'inherit',
              border: registerCustomUI?.backgroundImage ? '1px solid #0083ff' : '1px solid transparent',
            }"
            class="form-control m-b-5"
          />
          <span class="t-12">Enter a serial number.</span>
          <br />
          <input
            [ngStyle]="{
              'background-color': registerCustomUI?.backgroundImage ? 'rgba(0, 0, 0, 0.45) !important' : 'inherit',
              border: registerCustomUI?.backgroundImage ? '1px solid #0083ff' : '1px solid transparent',
            }"
            class="form-control m-b-5"
          />
        </div>
      </div>
      <!-- <div [innerHTML]="splash.description ? (splash.description | safe: 'html') : 'No description entered yet...'"></div> -->
    </div>
  </div>
</div>
<div class="modal-footer" *ngIf="!preview">
  <button type="button" [disabled]="loading" class="btn btn-primary" (click)="submit()">
    <span>Submit</span>
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
