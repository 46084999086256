<main class="column-layout-container" #communityColumnLayoutContainer basepage="primary" *ngIf="!inSetupMode">
  <div class="menu animate-in-from-left" [class.mobile-shown]="appService.mobileMenuShown">
    <aside role="region" aria-label="side navigation">
      <div class="menu-top d-flex justify-content-between align-items-center menu-title">
        <ng-container *ngIf="!betaStringId">
          {{ "com.community" | translate }}
        </ng-container>
        <div class="d-flex align-items-center" *ngIf="betaStringId">
          <button class="fa-button m-r-10" placement="bottom" ngbTooltip="Back to Beta Programs" [routerLink]="['/beta']">
            <fa-icon icon="chevron-left" class="action circle-icon-btn"></fa-icon>
          </button>
          <span [routerLink]="['/beta']" style="cursor: pointer">Beta Programs</span>
        </div>
      </div>

      <ng-container *ngIf="!displayedChannels?.length">
        <myp-skeleton-loader extraContainerClass="m-y-20" number="18" type="line" [useRandomWidth]="true" height="10px"></myp-skeleton-loader>
      </ng-container>
      <ul class="menu-options">
        <div class="m-r-10" *ngIf="displayedChannels?.length && !betaStringId && (displayedSection == 'channels' || displayedSection == 'search')">
          <div class="form-group">
            <label class="control-label" for="search-all-post">Search all posts</label>
            <div class="product-filter m-b-20">
              <div class="filter-input">
                <input id="search-all-post" type="text" (keyup.enter)="search()" [(ngModel)]="query" placeholder="Enter a query ..." />
                <button type="button" class="border-0 btn-transparent clear p-0 bg-transparent" (click)="clear()" *ngIf="query">
                  <fa-icon icon="times" aria-hidden="true"></fa-icon>
                  <span class="sr-only">clear search</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <li class="menu-option" *ngIf="betaStringId" [class.active]="displayedSection == 'betadetails'">
          <a
            class="primary-option"
            tabindex="0"
            href="javascript:void(0);"
            (keyup.enter)="appService.mobileMenuShown = false; switchSection('betadetails')"
            (keyup.space)="appService.mobileMenuShown = false; switchSection('betadetails')"
            (click)="appService.mobileMenuShown = false; switchSection('betadetails')"
          >
            Beta Details
          </a>
        </li>
        <li *ngIf="!betaStringId || betaCommunityTags?.length" class="menu-option" [class.active]="displayedSection == 'channels'">
          <a
            class="primary-option"
            tabindex="0"
            href="javascript:void(0);"
            (keyup.enter)="appService.mobileMenuShown = false; showChannel(betaStringId ? 'all_posts' : 'general', true)"
            (keyup.space)="appService.mobileMenuShown = false; showChannel(betaStringId ? 'all_posts' : 'general', true)"
            (click)="appService.mobileMenuShown = false; showChannel(betaStringId ? 'all_posts' : 'general', true)"
          >
            <ng-container *ngIf="!betaStringId">
              {{ (showingMyChannels ? "com.my_channels" : "com.all_channels") | translate }}
            </ng-container>
            <ng-container *ngIf="betaStringId">Bugs & Feature Requests</ng-container>
          </a>
          <div class="m-r-10" *ngIf="this.displayedChannels?.length && betaStringId && (displayedSection == 'channels' || displayedSection == 'search')">
            <div class="product-filter m-b-20">
              <div class="filter-input">
                <input type="text" (keyup.enter)="search()" [(ngModel)]="query" placeholder="Search all posts..." />
                <button type="button" class="border-0 btn-transparent clear p-0 bg-transparent" (click)="clear()" *ngIf="query">
                  <fa-icon icon="times" aria-hidden="true"></fa-icon>
                  <span class="sr-only">clear search</span>
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="betaStringId && displayedSection == 'channels'" class="m-r-10 m-b-20">
            <span class="font-weight-bold t-15 p-10 d-block">Display</span>
            <myp-segmented-bar
              [white]="true"
              [nowrap]="true"
              [id]="'beta-view-segmented-bar'"
              (selected)="selectBetaView($event.id)"
              [options]="[
                {
                  name: 'Detailed',
                  id: 'detailed',
                  selected: selectedBetaView == 'detailed',
                },
                {
                  name: 'List',
                  id: 'list',
                  selected: selectedBetaView == 'list',
                },
              ]"
            ></myp-segmented-bar>
            <span class="font-weight-bold t-15 p-10 d-block m-t-20">Sorting</span>
            <select class="form-control custom-select-sm m-r-10" (change)="filter()" [(ngModel)]="filters.sort">
              <option value="date">Newest</option>
              <option value="activity">Most Replies</option>
            </select>
            <span class="font-weight-bold t-15 p-10 d-block m-t-20">Filters</span>
            <select class="form-control custom-select-sm m-r-10" (change)="filter()" [(ngModel)]="filters.type">
              <option value="all">All Post Types</option>
              <option value="bug">Bugs</option>
              <option value="feature_request">Feature Requests</option>
              <option value="post">Posts</option>
            </select>
            <select class="form-control custom-select-sm m-r-10 m-t-10" (change)="filter()" [(ngModel)]="filters.status">
              <option value="all">Any Status</option>
              <option value="open">Open</option>
              <option value="cannot_reproduce">Cannot Reproduce</option>
              <option value="fixed">Fixed</option>
              <option value="investigating">Investigating</option>
              <option value="accepted">Accepted</option>
              <option value="rejected">Rejected</option>
            </select>
            <select class="form-control custom-select-sm m-r-10 m-t-10 m-b-10" (change)="filter()" [(ngModel)]="filters.reviewStatus">
              <option value="all">Reviewed & Not Reviewed</option>
              <option value="true">Only Reviewed</option>
              <option value="false">Only Not Reviewed</option>
            </select>
            <select class="form-control custom-select-sm m-r-10 m-t-10 m-b-10" (change)="filter()" [(ngModel)]="filters.author" *ngIf="communityService.betaAuthorList?.length">
              <option value="all">Any Author</option>
              <option *ngFor="let author of communityService.betaAuthorList" [value]="author.id">{{ author.name }}</option>
            </select>
            <input class="form-control custom-select-sm m-r-10 m-t-10 m-b-10" placeholder="Jira Ticket Number" type="text" (keyup.enter)="filter()" [(ngModel)]="filters.jiraTicketNumber" />

            <button class="btn btn-primary btn-sm" (click)="resetFilters()">Reset Filters</button>
          </div>
          <div class="menu-suboptions" *ngIf="displayedChannels && displayedChannels.length">
            <div class="sub-option d-block" *ngFor="let channel of displayedChannels">
              <a
                tabindex="0"
                href="javascript:void(0);"
                (keyup.enter)="showChannel(channel, true)"
                (keyup.space)="showChannel(channel, true)"
                [class.active]="displayedSection == 'channels' && selectedChannel && selectedChannel.title == channel.title"
                (click)="appService.mobileMenuShown = false; showChannel(channel, true)"
              >
                {{ channel.string_id != "general" && channel.string_id != "all_posts" && channel.string_id != "pinned_posts" && channel.string_id != "my_posts" ? "#" : "" }}
                {{ betaStringId ? channel.title : ("com.skills." + channel.string_id | translate) }}
                <span class="badge" *ngIf="betaStringId && communityService.betaTagCountMap && communityService.betaTagCountMap[channel.string_id]">
                  {{
                    channel.string_id != "general" && channel.string_id != "all_posts" && channel.string_id != "pinned_posts" && channel.string_id != "my_posts"
                      ? communityService.betaTagCountMap[channel.string_id]
                      : ""
                  }}
                </span>
              </a>
            </div>
            <div class="m-l-10">
              <button class="small-btn" *ngIf="mySkills && mySkills.length" (click)="toggleChannels()">{{ (showingMyChannels ? "com.show_all" : "com.show_mine") | translate }}</button>
              <button class="small-btn" *ngIf="user.active_subscription && !betaStringId" (click)="addSkill()">{{ "general.manage" | translate }}</button>
            </div>
          </div>
        </li>
        <li class="menu-option" *ngIf="allTopics && allTopics.length" [class.active]="displayedSection == 'topics'">
          <a
            class="primary-option"
            tabindex="0"
            href="javascript:void(0);"
            *ngIf="topics.length || user.is_sphere_admin"
            (keyup.enter)="appService.mobileMenuShown = false; showTopic(topics[0], true)"
            (keyup.space)="appService.mobileMenuShown = false; showTopic(topics[0], true)"
            (click)="appService.mobileMenuShown = false; showTopic(topics[0], true)"
          >
            Topics
          </a>
          <div class="menu-suboptions">
            <div class="sub-option d-block" *ngFor="let topic of showingAllTopics ? allTopics : topics">
              <a
                tabindex="0"
                href="javascript:void(0);"
                (keyup.enter)="showTopic(topic, true)"
                (keyup.space)="showTopic(topic, true)"
                [class.archived]="topic.archived"
                [class.active]="displayedSection == 'topics' && selectedTopic && selectedTopic?.title == topic.title"
                (click)="appService.mobileMenuShown = false; showTopic(topic, true)"
              >
                {{ topic.title }}
                <span *ngIf="topic.archived" class="tiny-pill">ARCHIVED</span>
              </a>
            </div>

            <div class="m-l-10" *ngIf="user.is_sphere_admin">
              <a [routerLink]="['/admin/community_topics']" class="small-btn m-r-10">Manage Topics</a>
              <button class="small-btn" *ngIf="user.active_subscription && allTopics.length > topics.length" (click)="showingAllTopics = !showingAllTopics">
                {{ showingAllTopics ? "Hide" : "View" }} Archived Topics
              </button>
            </div>
          </div>
        </li>
        <li class="menu-option" *ngIf="!betaStringId" [class.active]="displayedSection == 'connect'">
          <a
            class="primary-option"
            tabindex="0"
            href="javascript:void(0);"
            (keyup.enter)="appService.mobileMenuShown = false; switchSection('connect')"
            (keyup.space)="appService.mobileMenuShown = false; switchSection('connect')"
            (click)="appService.mobileMenuShown = false; switchSection('connect')"
          >
            {{ "com.connect" | translate }}
          </a>
        </li>
      </ul>
    </aside>
  </div>
  <section role="contentinfo" class="content animate-in-from-right" #contentContainer (scroll)="contentContainerScrolled($event)" [class.mobile-shown]="!appService.mobileMenuShown">
    <button class="mobile-back-btn no-border" (click)="appService.mobileMenuShown = true">
      <fa-icon icon="chevron-left"></fa-icon>
      <ng-container *ngIf="!betaStringId">
        {{ "com.community" | translate }}
      </ng-container>
      <ng-container *ngIf="betaStringId">Beta Options</ng-container>
    </button>
    <div class="container">
      <myp-community-search
        *ngIf="displayedSection == 'search'"
        [betaStringId]="betaStringId"
        #searchCmp
        [query]="selectedQuery"
        (scrollToTop)="scrollToTop()"
        (postsLoaded)="postsLoaded()"
        (viewPost)="showPostDetail($event)"
      ></myp-community-search>
    </div>
    <div class="container" *ngIf="!shouldShowPostDetails">
      <ng-container *ngIf="displayedSection == 'betadetails' && betaProgram">
        <myp-beta-details [betaProgram]="betaProgram"></myp-beta-details>
      </ng-container>
      <myp-connect *ngIf="displayedSection == 'connect'" (membersSearched)="showMemberResults($event)" [skills]="skills"></myp-connect>
      <!-- <myp-inline-loader [loading]="loading" height="400px" spinnerName="postLoader"></myp-inline-loader> -->
      <ng-container *ngIf="postsLoading">
        <div style="margin: auto; width: 200px">
          <myp-skeleton-loader type="line" height="40px" width="100%"></myp-skeleton-loader>
        </div>
        <div style="margin: auto; max-width: 1000px">
          <myp-skeleton-loader extraContainerClass="m-t-20 m-b-30" max="100%" borderRadius="10px" type="carousel" height="100px"></myp-skeleton-loader>
          <myp-skeleton-loader extraContainerClass="m-y-40" max="100%" borderRadius="10px" type="carousel" height="300px"></myp-skeleton-loader>
          <myp-skeleton-loader extraContainerClass="m-y-40" max="100%" borderRadius="10px" type="carousel" height="100px"></myp-skeleton-loader>
          <myp-skeleton-loader extraContainerClass="m-y-40" max="100%" borderRadius="10px" type="carousel" height="200px"></myp-skeleton-loader>
          <myp-skeleton-loader extraContainerClass="m-y-40" max="100%" borderRadius="10px" type="carousel" height="340px"></myp-skeleton-loader>
        </div>
      </ng-container>
      <div class="community-post beta-header" *ngIf="displayedSection == 'channels' && selectedChannel && betaStringId">
        This is the Beta Community for:
        <br />
        <h3 class="brandfont-black">{{ betaProgram.title }}</h3>
      </div>
      <ng-container *ngIf="displayedSection == 'channels' && selectedChannel && betaStringId">
        <div style="max-width: 1000px; margin: auto" *ngIf="betaAnnouncements?.length">
          <h4 class="section-header m-t-20">
            Announcements
            <span class="section-header-link" (click)="announcementsCollapsed = !announcementsCollapsed">
              {{ announcementsCollapsed ? ("sphere.expand" | translate) : ("sphere.collapse" | translate) }}
              <fa-icon [icon]="announcementsCollapsed ? 'chevron-down' : 'chevron-up'"></fa-icon>
            </span>
          </h4>
          <div class="m-t-20" *ngIf="!announcementsCollapsed">
            <myp-comment
              *ngFor="let ann of betaAnnouncements"
              [betaStringId]="betaStringId"
              [comment]="ann"
              [isCommunityPost]="true"
              [replies]="communityService.activeParentThreadMap[ann.key]"
              (replied)="sendReply($event)"
              (deleteIndexSet)="postDeleteIndex = $event"
              [index]="i"
              (channelSelected)="channelSelected($event)"
              [displayedSection]="postType"
            ></myp-comment>
          </div>
          <div *ngIf="announcementsCollapsed" class="text-muted m-b-20">{{ betaAnnouncements.length }} Announcement{{ betaAnnouncements.length > 1 ? "s" : "" }} Hidden.</div>
        </div>
      </ng-container>
      <myp-channel
        #channelCmp
        *ngIf="(displayedSection == 'channels' && selectedChannel) || (displayedSection == 'topics' && selectedTopic)"
        (postsLoaded)="postsLoaded($event)"
        [postType]="displayedSection"
        (channelSelected)="channelSelected($event)"
        (resetFilters)="resetFilters()"
        [selectedChannel]="selectedChannel"
        (topicSelected)="displayedSection = 'topics'; selectedChannel = undefined; selectedTopic = webCommunityService.topicMap[$event]"
        [selectedTopic]="selectedTopic"
        [betaStringId]="betaStringId"
        [selectedBetaView]="selectedBetaView"
      ></myp-channel>
    </div>
  </section>
</main>

<main basepage="primary" class="fullwidth-layout-container h-full" *ngIf="inSetupMode">
  <div class="d-flex align-items-center justify-content-center h-full">
    <div class="text-center position-relative" style="max-width: 800px">
      <ng-container *ngIf="setupStep == 'init'">
        <div class="position-relative">
          <div>
            <div>
              <!-- <span class="">{{ "sphere.welcome.welcome_to" | translate }}</span
              ><br /> -->
              <!-- <img class="pointer text-logo" style="width: 200px" src="./assets/images/s1+black.png" /><br /> -->
              <h1 class="t-50 bold brandfont-black" style="font-size: 40px">{{ "sphere.welcome.welcome_to" | translate }} {{ env.studioOnePlusBrandName }} {{ "com.community" | translate }}</h1>
            </div>
            <!-- <img src="./assets/images/community-horizon-flare2.png" style="width: 100%; max-width: 600px" /> -->
          </div>
        </div>
        <div class="position-relative" style="z-index: 999">
          <div class="community-onboarding-box">
            <span>{{ "com.onboarding.com_lets_you" | translate }}</span>
            <div class="m-y-50">
              <span class="bold">{{ "com.onboarding.step1" | translate }}</span>
              <div [innerHTML]="'com.onboarding.to_interact' | translate | safe: 'html'"></div>
              <br />
              <small class="text-muted">{{ "com.onboarding.decide_hidden" | translate }}</small>
            </div>
            <button class="btn btn-primary m-t-10 d-inline-block" (click)="setProfile(true)">
              {{ "com.onboarding.turn_it_on" | translate }}
              <fa-icon class="btn-primary" icon="check"></fa-icon>
            </button>
            <br />
            <br />
            <button class="tiny-link black m-t-10 d-inline-block" (click)="setProfile(false)">
              {{ "com.onboarding.keep_me_hidden" | translate }}
              <fa-icon icon="times"></fa-icon>
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="setupStep == 'extra_details'">
        <div class="community-onboarding-box">
          <h1 class="bold">{{ "com.onboarding.step2" | translate }}</h1>
          <div class="row">
            <div class="form-group col-md-6" *ngIf="!user.firstName">
              <small class="form-text text-muted">{{ "profile.edit.first_name" | translate }}</small>
              <input type="text" class="form-control" name="firstName" id="firstName" [(ngModel)]="profile.firstName" [placeholder]="'profile.edit.enter_name' | translate" />
            </div>

            <div class="form-group col-md-6" *ngIf="!user.lastName">
              <small class="form-text text-muted">{{ "profile.edit.last_name" | translate }}</small>
              <input type="text" class="form-control" name="lastName" id="lastName" [(ngModel)]="profile.lastName" [placeholder]="'profile.edit.enter_last_name' | translate" />
            </div>

            <div class="form-group col-md-12">
              <small class="form-text text-muted">{{ "com.tagline" | translate }}</small>
              <input type="text" class="form-control" name="tagline" id="tagline" [(ngModel)]="profile.tagline" [placeholder]="'com.enter_tagline' | translate" />
              <small class="form-text text-muted">{{ "com.tagline_description" | translate }}</small>
            </div>

            <div class="form-group col-md-12">
              <small class="form-text text-muted">{{ "profile.edit.description" | translate }}</small>
              <textarea name="description" id="description" [(ngModel)]="profile.description" class="form-control">{{ user.description }}</textarea>
              <small class="form-text text-muted">{{ "com.profile_description" | translate }}</small>
            </div>
          </div>

          <br />
          <br />
          {{ "com.skills_description" | translate }}
          <br />
          <div class="text-left">
            <div class="list-item hover dontgrow" *ngFor="let skill of mySkills">
              <div class="list-item-container">
                <div class="main-data">
                  <span class="title">{{ "com.skills." + skill.string_id | translate }}</span>
                  <span class="sub-data" *ngIf="skill.is_service || skill.notes">
                    <ng-container *ngIf="skill.is_service">
                      <div>{{ "com.offered_as_service" | translate }}</div>
                      <!-- <div *ngIf="skill.hourly_rate">Hourly rate: ${{ skill.hourly_rate }}</div> -->
                    </ng-container>
                    <div *ngIf="skill.notes">{{ "com.notes" | translate }}: {{ skill.notes }}</div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <button class="btn btn-primary m-t-10 d-inline-block" (click)="addSkill()">
            {{ (mySkills && mySkills.length ? "com.add_more_skills" : "com.add_skills") | translate }}
            <fa-icon icon="chevron-right" class="btn-primary"></fa-icon>
          </button>

          <div class="m-t-30">
            <button class="small-btn" (click)="updateProfile()" *ngIf="!mySkills || !mySkills.length">{{ "com.onboarding.do_this_later" | translate }}</button>
            <button class="btn btn-primary" (click)="updateProfile()" *ngIf="mySkills && mySkills.length">{{ "sphere.welcome.continue" | translate }}</button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="setupStep == 'done'">
        <div class="community-onboarding-box">
          <h1 class="brandfont-bold">{{ "com.onboarding.done" | translate }}</h1>
          <span class="text-muted">{{ "com.onboarding.heres_more" | translate }}</span>

          <ul class="row">
            <li class="m-y-20 col-md-6">
              <fa-icon icon="bullhorn" class="t-40 c-official-blue"></fa-icon>
              <h2 class="brandfont-bold">{{ "com.posts" | translate }}</h2>
              <span class="text-muted">{{ "com.onboarding.posts_desc" | translate }}</span>
            </li>

            <li class="m-y-20 col-md-6">
              <fa-icon icon="guitar" class="t-40 c-official-blue"></fa-icon>
              <h2 class="brandfont-bold">{{ "com.skills_word" | translate }}</h2>
              <span class="text-muted">{{ "com.onboarding.skills_desc" | translate }}</span>
            </li>

            <li class="m-y-20 col-md-6">
              <fa-icon icon="microphone" class="t-40 c-official-blue"></fa-icon>
              <h2 class="brandfont-bold">{{ "com.showcases" | translate }}</h2>
              <span class="text-muted">{{ "com.onboarding.showcases_desc" | translate }}</span>
            </li>

            <li class="m-y-20 col-md-6">
              <fa-icon icon="comments" class="t-40 c-official-blue"></fa-icon>
              <h2 class="brandfont-bold">{{ "com.connections_messages" | translate }}</h2>
              <span class="text-muted">{{ "com.onboarding.connections_desc" | translate }}</span>
            </li>
          </ul>

          <button class="btn btn-primary" (click)="doneSetup()">{{ "com.onboarding.lets_go" | translate }}</button>
        </div>
      </ng-container>
    </div>
  </div>
</main>

<myp-member-results-overlay *ngIf="shouldShowMemberResults" (closeClicked)="closeMemberResults()" [searchResults]="searchResults"></myp-member-results-overlay>
