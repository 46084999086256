<div class="card product-card" *ngIf="!registerProductTile && !redeemS17Tile" role="group" aria-label="product">
  <div class="downloads_header" (click)="tileClick()">
    <div class="d-flex justify-content-center align-items-center m-b-10">
      <span class="unread-badge" *ngIf="product && userService.userMetadataLoaded && (!userService.userMetadata.products_seen || !userService.userMetadata.products_seen[product.id])">
        <span class="sr-only">status unread</span>
      </span>
      <h4>{{ title }}</h4>
    </div>
  </div>
  <div class="d-flex h-full align-items-center justify-content-center" (click)="tileClick()">
    <img *ngIf="image" [src]="image" class="product-image" style="align-self: center" alt="" />
  </div>

  <div class="m-t-10" *ngIf="productService.elligleToRedeemStudioOneSeven && product.id == env.product_ids.s16pro">
    <button class="btn btn-primary" (click)="showRedeemS17()">Redeem Pro 7 for Free</button>
  </div>

  <div class="tile-bottom w-full justify-content-center m-y-10" *ngIf="product?.redeemable">
    <button class="brand-btn-reverse sm m-r-10" (click)="redeemProduct()">Claim Product</button>
    <fa-icon icon="info-circle" *ngIf="!redeeming" (click)="redeemInfo()"></fa-icon>
    <fa-icon icon="spinner" animation="spin" *ngIf="redeeming"></fa-icon>
  </div>
  <div class="tile-bottom w-full" *ngIf="(product && !product?.redeemable) || productLink">
    <div class="buttons left btn-group">
      <button
        type="button"
        role="link"
        *ngIf="product && product.includedInSubscription"
        class="btn btn-tranparent border-0 p-1"
        [ngbTooltip]="'Included in Studio One Pro+'"
        tooltipClass="nowrap"
        style="width: 30px"
      >
        <img [src]="'./assets/icons/plus-icon-blue.png'" class="action prod-sphere-icon" alt="" />
        <span class="sr-only">Included in Studio One Pro+</span>
      </button>
      <button
        type="button"
        *ngIf="product && product.categoryId == 'software'"
        class="btn btn-tranparent border-0 p-1"
        (keyup.enter)="downloadInstaller(product)"
        (click)="downloadInstaller(product)"
        [ngbTooltip]="'products.download_installer' | translate"
        placement="top"
        tooltipClass="nowrap"
      >
        <fa-icon icon="download" class="tile-action-btn"></fa-icon>
        <span class="sr-only">{{ "products.download_installer" | translate }}</span>
      </button>
      <button
        type="button"
        role="link"
        *ngIf="product && product.featuredSample"
        class="btn btn-tranparent border-0 p-1"
        (keyup.enter)="playSample(product.featuredSample)"
        (click)="playSample(product.featuredSample)"
        [ngbTooltip]="'Play Sample'"
        placement="top"
        tooltipClass="nowrap"
      >
        <fa-icon icon="play-circle" class="tile-action-btn" aria-hidden="true"></fa-icon>
        <span class="sr-only">press the button to play the sample audio</span>
      </button>
    </div>
    <div class="w-full t-12 text-center">
      <span *ngIf="showDate && product && product.categoryId == 'hardware' && (product.aquired || product.releaseDate)">
        {{ product.aquired || product.releaseDate | date }}
      </span>
      <span *ngIf="showDate && product && product.categoryId != 'hardware' && (product.releaseDate || product.aquired)">
        {{ product.releaseDate || product.aquired | date }}
      </span>
    </div>
    <div class="buttons right btn-group mr-2">
      <button
        type="button"
        role="link"
        *ngIf="product && product.id"
        class="btn btn-tranparent border-0 p-1"
        (click)="tileClick()"
        [ngbTooltip]="'general.view_product' | translate"
        placement="top"
        tabindex="0"
        tooltipClass="nowrap"
      >
        <fa-icon icon="chevron-right" class="t-16"></fa-icon>
        <span class="sr-only">{{ "general.view_product" | translate }}</span>
      </button>
      <button
        type="button"
        role="link"
        *ngIf="productLink"
        class="btn btn-tranparent border-0 p-1"
        [href]="productLink"
        target="_blank"
        [ngbTooltip]="'products.view_more_details' | translate"
        placement="top"
        tooltipClass="nowrap"
      >
        <fa-icon icon="chevron-right" class="t-16"></fa-icon>
        <span class="sr-only">{{ "products.view_more_details" | translate }}</span>
      </button>
    </div>
  </div>
</div>
<!-- START | card: register product -->
<div class="card register-product-card" *ngIf="registerProductTile" role="group" aria-label="register product">
  <img class="align-self-center product-image" src="./assets/images/register-product-bundle.webp" style="max-width: 300px" alt="" />
  <div class="card-body px-0 pb-0">
    <p class="card-text">{{ "newdash.prod_page_slug" | translate }}</p>
    <button type="button" class="btn btn-primary m-b-20" role="link" tabindex="0" (click)="registerProductClicked.next(true)" #register>Register Products</button>
  </div>
</div>
<!-- END | card: register product -->
<!-- START | card: redeem studio one 7 -->
<div class="card register-product-card" *ngIf="redeemS17Tile" role="group" aria-label="register product">
  <img class="align-self-center product-image" src="./assets/images/s17-images/s17-box.png" style="max-width: 300px" alt="" />
  <div class="card-body px-0 pb-0">
    <p class="card-text">You are eligible to redeem Studio One Pro 7 for free!</p>
    <button type="button" class="btn btn-primary m-b-20" role="link" tabindex="0" (click)="redeems17Clicked.next(true)" #register>Redeem Studio One Pro 7</button>
  </div>
</div>
<!-- END | card: redeem studio one 7 -->
