import { BaseComponent, CommunityService, UserService, WindowService, WorkspaceService } from "@mypxplat/xplat/core";
import { StorageDetails } from "@mypxplat/xplat/core";
import { Directive, NgZone } from "@angular/core";

@Directive()
export abstract class WorkspaceDetailBaseComponent extends BaseComponent {
  public uploadWatchMap: any = {};
  public workspace: any;
  public sharedFiles: Array<any>;
  public reversedSharedFiles: Array<any>;
  public sharedFilesMap: any = {};
  public messages: any;
  public messageDeleteIndex: number;
  public displayedMessages: any = [];
  public messagesSubscription: any;
  public newMsg: string;
  public myWorkspace: boolean;
  public mainMix: any;
  public collaboratorMap: any = {};
  public userStorageDetails: StorageDetails;
  public myRole: string;
  public groupedFilesMap = {};
  public groupedFormatsMap = {};
  public collabsNotMe: Array<any>;

  constructor(
    userService: UserService,
    public windowService: WindowService,
    public workspaceService: WorkspaceService,
    public zone: NgZone,
    public communityService: CommunityService
  ) {
    super(userService);
  }

  watchFileProgress(file) {
    if (file.type && file.type == "folder") return;
    if (this.uploadWatchMap[file.id]) this.windowService.clearInterval(this.uploadWatchMap[file.id].interval);
    this.uploadWatchMap[file.id] = {
      interval: this.windowService.setInterval(() => {
        this.workspaceService.checkFileUploadStatus(file.id).subscribe(
          (result: any) => {
            this.zone.run(() => {
              if (result && result.progress == 100) {
                if (this.workspaceService.fileMap[file.id]) this.workspaceService.fileMap[file.id].progress = result.progress;
                if (this.sharedFilesMap[file.id]) this.sharedFilesMap[file.id].progress = result.progress;
                this.windowService.clearInterval(this.uploadWatchMap[file.id].interval);
                delete this.uploadWatchMap[file.id];
              } else {
                if (this.uploadWatchMap[file.id]) {
                  if (this.uploadWatchMap[file.id].progress == result.progress) {
                    this.uploadWatchMap[file.id].sameCount++;
                  } else {
                    this.uploadWatchMap[file.id].sameCount = 0;
                  }
                  if (this.workspaceService.fileMap[file.id]) {
                    if (this.uploadWatchMap[file.id].sameCount > 4) {
                      this.windowService.clearInterval(this.uploadWatchMap[file.id].interval);
                      this.workspaceService.fileMap[file.id].stalled = true;
                      this.sharedFilesMap[file.id].stalled = true;
                      delete this.uploadWatchMap[file.id];
                    } else {
                      if (this.workspaceService.fileMap[file.id]) this.workspaceService.fileMap[file.id].progress = result.progress;
                      if (this.sharedFilesMap[file.id]) this.sharedFilesMap[file.id].progress = result.progress;
                      if (this.uploadWatchMap[file.id]) this.uploadWatchMap[file.id].progress = result.progress;
                    }
                  }
                }
              }
            });
          },
          (error) => {
            if (this.sharedFilesMap[file.id]) {
              this.sharedFilesMap[file.id].stalled = true;
              if (this.uploadWatchMap[file.id].interval) this.windowService.clearInterval(this.uploadWatchMap[file.id].interval);
              delete this.uploadWatchMap[file.id];
            }
            if (this.workspaceService.fileMap[file.id]) this.workspaceService.fileMap[file.id].stalled = true;
          }
        );
      }, 8000),
      progress: file.progress,
      sameCount: 0,
    };
  }

  updateMessages(result, reverse?) {
    this.messages = result;

    if (this.displayedMessages.length == 0 && this.messages.length > this.displayedMessages) {
      for (let i = 0; this.messages.length > i; i++) {
        this.displayedMessages.push(this.messages[i]);
      }
    } else if (this.displayedMessages.length > 0 && this.messages.length > this.displayedMessages.length) {
      for (let i = this.displayedMessages.length; this.messages.length > i; i++) {
        this.displayedMessages[reverse ? "unshift" : "push"](reverse ? this.messages.reverse()[i] : this.messages[i]);
      }
    } else if (this.messages.length > 0 && this.messages.length == this.displayedMessages.length) {
      this.displayedMessages.forEach((item, index, theArray) => {
        if (JSON.stringify(item) != JSON.stringify(this.messages[index])) {
          theArray[index] = this.messages[index];
        }
      });
    } else if (this.messages.length < this.displayedMessages.length) {
      // the user deleted a message
      this.displayedMessages.splice(this.messageDeleteIndex, 1);
      this.messageDeleteIndex = undefined;
    }
  }

  clearWatchFileProgress() {
    for (let i in this.uploadWatchMap) {
      this.windowService.clearInterval(this.uploadWatchMap[i].interval);
    }
    this.uploadWatchMap = {};
  }
}
