import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UsbDeviceService {
  constructor() {}

  public checkAvailability() {
    return (<any>navigator).usb ? true : false;
  }

  // Request user to select a USB device
  async requestUSBDevice() {
    try {
      const device = await (<any>navigator).usb.requestDevice({
        filters: [], // Provide filters for specific devices if needed
      });
      return device;
    } catch (error) {
      return null;
    }
  }

  // List all connected USB devices
  async getConnectedDevices() {
    let devices = null;
    if ((<any>navigator).usb) {
      devices = await (<any>navigator).usb.getDevices();
    }
    return devices;
  }
}
