import { Component, Input, Output, EventEmitter, ViewChildren, ElementRef, QueryList } from "@angular/core";
import { StorageService, UserService, ProductService, WindowService, ProductLicenseItem, ProductSnapshot } from "@mypxplat/xplat/core";
import { ManageActivationsBaseComponent } from "@mypxplat/xplat/features";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "myp-manage-activations",
  templateUrl: "manage-activations.component.html",
})
export class ManageActivationsComponent extends ManageActivationsBaseComponent {
  @Input() product: ProductSnapshot["product"];
  @Input() license: ProductLicenseItem;
  public loading: boolean = false;
  @Output() activationDeleted: EventEmitter<any> = new EventEmitter();

  constructor(
    storageService: StorageService,
    userService: UserService,
    public productService: ProductService,
    public activeModal: NgbActiveModal,
    public translate: TranslateService,
    public win: WindowService
  ) {
    super(userService);
  }

  deactivate(activation: any) {
    this.win
      .confirm("Are you you want to remove this activation?")
      .then((_) => {
        let args: any = {
          activationId: activation.id,
        };
        if (this.product.categoryId == "extension") {
          args.hardwareSerial = activation.hardwareSerialNumber;
        } else {
          args.assetId = activation.licenseId;
        }
        activation.loading = true;
        this.loading = true;
        this.productService.deactivateSoftware(args).subscribe((result) => {
          this.activationDeleted.next(args);
          activation.loading = false;
          this.activeModal.close();
        });
      })
      .catch((_) => {});
  }
}
