import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { of } from "rxjs";
import { filter, map, switchMap, take } from "rxjs/operators";
import { of as observableOf, BehaviorSubject, Subject, throwError } from "rxjs";
import { environment } from "../environments/environment";
import { StorageService, StorageKeys } from "./storage.service";
import { WindowService } from "./window.service";
import { UserService } from "./user.service";
import { LogService } from "./log.service";
import * as moment from "moment";
import { HelperService } from "./helper.service";
import { Md5 } from "ts-md5/dist/md5";
import { TranslateService } from "@ngx-translate/core";
import { GAService } from "./ga.service";
import { ProductCategory, ProductListItem } from "../models";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  private _savedProducts: any;
  private _dirty: boolean = false;
  private _products: any;

  private _news: any;

  public hasGottenProducts: boolean = false;

  public news$: BehaviorSubject<any> = new BehaviorSubject(false);
  public products$: BehaviorSubject<any> = new BehaviorSubject(false);
  public redeemS17ElligibilityRetrieved$: BehaviorSubject<any> = new BehaviorSubject(false);
  public productDataError$: Subject<boolean> = new Subject();
  public productRegistered$: EventEmitter<number> = new EventEmitter();

  public gettingProducts: boolean = false;

  public productDetailsMap: any = {};
  public productMap: {
    [key: number]: ProductListItem;
  } = {};
  public productsByAcquired: Array<any> = [];
  public productsByReleaseDate: Array<any> = [];
  public productErpIDs: Array<string> = [];
  public productsByErpIDs: any = {};
  public productsByStringIDs: any = {};
  public productsByProductID: any = {};
  public prodImagesMap: any = {};
  public subscriptionProductsMap: any = {};
  public productGroups: any = {};
  public learnProducts: any = [];
  public hasElligibleS1ForExchange: boolean = false;
  public sphereElligibleUpgradeProducts: Array<any> = [];
  public redeemableProducts: Array<any>;

  public documentLanguageIds: any = {
    English: "en",
    German: "de",
    French: "fr",
    Spanish: "es",
    Japanese: "ja",
    Chinese: "zh",
    Turkish: "tr",
    Français: "fr",
    Deutsch: "de",
    Español: "es",
    日本語: "ja",
    한글: "ko",
  };

  public s16Released: boolean = moment() >= moment("2022-09-29 08:00:00-05:00");

  public hasElligibleS15HardwareUpgrade = false;
  public elligibleS15HardwareUpgrades = {
    atom: true,
    "atom.producers.lab": true,
    AudioBoxiOne: true,
    "AudioBox.iOne.Platinum": true,
    AudioBoxiTwo: true,
    AudioBoxiTwoStudio: true,
    "abox96k.ultimate": true,
    "audiobox.usb.96": true,
    "audiobox.usb.96.blk": true,
    "audiobox.96.studio": true,
    "audiobox.usb.96.blk.studio": true,
    faderportv2: true,
    faderport16: true,
    faderport8: true,
    iostation24c: true,
    quantum: true,
    quantum2: true,
    quantum2626: true,
    studio1810c: true,
    studio1824c: true,
    "Studio.192": true,
    studio24c: true,
    studio26c: true,
    studio68c: true,
    "studiolive.16.0.2.usb": true,
    "studiolive.16.r": true,
    "studiolive.24.r": true,
    "studiolive.32.r": true,
    "studiolive.32s.iii": true,
    "studiolive.32sc.iii": true,
    "studiolive.32sx.iii": true,
    "studiolive.64.iii": true,
    "StudioLive.AR12.USBC": true,
    "StudioLive.AR16.USBC": true,
    "StudioLive.AR8.USBC": true,
  };

  public ecosystemProducts: any = {
    "earmix.16m": true,
    cable5: true,
    "studiolive.16.iii": true,
    "studiolive.16.r": true,
    "studiolive.24.iii": true,
    "studiolive.24.r": true,
    "studiolive.32.iii": true,
    "studiolive.32.r": true,
    "studiolive.32s.iii": true,
    "studiolive.32sc.iii": true,
    "studiolive.32sx.iii": true,
    "studiolive.64.iii": true,
    "nsb.16.8": true,
    "nsb.8.8": true,
  };

  public erpIDTitleMap: any = {
    STUDIOONE3: "Studio One 3",
    STUDIOONE4: "Studio One 4",
    STUDIOONE5: "Studio One 5",
  };

  public categoryMap: any = {};

  public lastProductListScrollPosition: number;
  public hardRefreshProducts: boolean = false;
  public newProductRegistered$: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
  public newProductRegistered = this.newProductRegistered$.asObservable();
  public s1SixToSevenElligiblePurchaseDate: Date = new Date("2024-08-01");

  public simpleProductList: {
    products: Array<ProductListItem>;
    categories: any;
  };

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private _win: WindowService,
    private _translate: TranslateService,
    private _log: LogService,
    private _helpers: HelperService,
    private userService: UserService,
    private gaService: GAService
  ) {
    this._helpers.userID = this.userService.user ? this.userService.user.id : undefined;
  }

  get products() {
    if (!this._products) this._products = this.storage.getItem(StorageKeys.PRODUCTS);
    return this._products;
  }
  set products(prods) {
    if (prods) {
      this._products = prods;
      this.storage.setItem(StorageKeys.PRODUCTS, prods);
    } else {
      this._products = false;
      this.storage.removeItem(StorageKeys.PRODUCTS);
    }
  }

  private _productImagesMap: any;
  get productImagesMap() {
    if (!this._productImagesMap) this._productImagesMap = this.storage.getItem(StorageKeys.PRODUCTIMAGESMAP);
    return this._productImagesMap;
  }

  set productImagesMap(map) {
    this._productImagesMap = map;
    this.storage.setItem(StorageKeys.PRODUCTIMAGESMAP, map);
  }

  public initProducts(getProductsIfNotGotten, mobile?) {
    this._savedProducts = this.products;
    let user = this.userService.user;
    if (user) {
      if (this._savedProducts) {
        let prodIDs = [];
        if (this._savedProducts.hardware && this._savedProducts.hardware.length) {
          this._savedProducts.hardware.forEach((item) => prodIDs.push(item.id));
        }
        if (this._savedProducts.software && this._savedProducts.software.length) {
          this._savedProducts.software.forEach((item) => prodIDs.push(item.id));
        }
        let hashedProdIDs = Md5.hashStr(JSON.stringify(prodIDs));
        if (!user.cached_product_ids || hashedProdIDs != user.cached_product_ids) {
          // get new product data because their product data was invalidated or it changed.
          this._win.setTimeout(() => {
            this.getProducts(true, false, false, mobile).subscribe();
          }, 2000);
        } else if (!this.storage.checkTimestamp("product_image")) {
          this._win.setTimeout(() => {
            this.getProducts(false, true, false, mobile).subscribe();
          }, 2000);
        } else {
          let productData;
          if (mobile) {
            productData = this._handleMobileProductData(this._savedProducts);
          } else {
            productData = this._handleProductData(this._savedProducts);
          }
          if (productData) {
            this.hasGottenProducts = true;
            this.products$.next(productData);
          }
        }
      } else if (getProductsIfNotGotten) {
        this.getProducts(false, false, false, mobile).subscribe();
      }
    }
  }

  public listProductDataMap: any = {};
  public getSimpleProductList() {
    this.isGettingProducts = true;
    return this.http.get(environment.paeApiUrl + "product/list/all", this._helpers.getHttpOptions()).pipe(
      map((data: any) => {
        if (data.products?.length) {
          data.products.forEach((item) => {
            this.listProductDataMap[item.id] = item;
          });
        }
        if (data.categories) this.processProductCategories(data.categories);
        this.isGettingProducts = false;
        return data;
      })
    );
  }

  public getDetailForAssetIds(id, assetIds) {
    let assetIdsString = assetIds.join(",");
    return this.http.get(environment.paeApiUrl + `product/detail/${id}?assetIds=${assetIdsString}`, this._helpers.getHttpOptions()).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public isGettingProducts: boolean = false;
  public getProducts(forceRefresh?, getNewImages?, clearCache = true, mobileOnly = false) {
    return this.getSimpleProductList().pipe(
      map((result: { products: Array<ProductListItem>; categories: any }) => {
        this.simpleProductList = result;
        this.prodArraySorter(this.simpleProductList.products);
        this.hasGottenProducts = true;
        this._handleProductData(this.simpleProductList.products);
        this.checkStudioOneSevenRedeemElligibility();
        this.products$.next(this.simpleProductList);
        return this.simpleProductList;
      })
    );
  }

  public productCategories: Array<ProductCategory>;
  public getProductCategories() {
    return this.http.get(environment.paeApiUrl + "product/categories", this._helpers.getHttpOptions()).pipe(
      map((data: any) => {
        this.processProductCategories(data);
        return data;
      })
    );
  }

  public productCategoryMap: any = {};
  processProductCategories(data) {
    data.forEach((item) => {
      this.productCategoryMap[item.string_id] = item;
      if (item.children) {
        this.processProductCategories(item.children);
      }
    });
    data.sort((a, b) => a.sort_order - b.sort_order);
    data.forEach((category) => {
      if (category.children && category.children.length > 0) {
        category.children.sort((a, b) => a.sort_order - b.sort_order);
      }
    });
    this.productCategories = data;
  }

  get news() {
    if (!this._news) this._news = this.storage.getItem(StorageKeys.NEWS_UPDATES);
    return this._news;
  }

  set news(news) {
    this._news = news;
    this.storage.setItem(StorageKeys.NEWS_UPDATES, news);
  }

  public getHomeRss(limit?, app = "mypresonus.web") {
    let language = this._translate.getDefaultLang();
    if (language != "de" && language != "en") {
      language = "en";
    }
    const storedUpdates = this.news;
    if (storedUpdates) {
      this.news$.next(limit ? storedUpdates.slice(0, limit) : storedUpdates);
    }
    return this.http.get(environment.paeApiUrl + "product/rss_feed?language=" + language + "&version=" + app, this._helpers.getHttpOptions()).pipe(
      map((data: any) => {
        let rssContent: any = [];
        let entries = data.feed.entry;
        if (!Array.isArray(entries)) entries = [entries];
        for (var i = 0; entries.length > i; i++) {
          let displayObj = {
            id: entries[i].id,
            title: entries[i].title,
            //"display_date": moment(entries[i].updated).format('MMM Do, YYYY'),
            display_date: entries[i].updated,
            summary: entries[i].summary,
          };
          if (entries[i].link && Array.isArray(entries[i].link) && entries[i].link.length) {
            for (let x = 0; entries[i].link.length > x; x++) {
              let link = entries[i].link[x];
              if (link["$"].type && link["$"].type.indexOf("image") != -1 && link["$"]["device-pixel-ratio"] == "2x") {
                displayObj["image"] = encodeURI(link["$"].href);
              }
              if (link["$"].href.indexOf("http") != -1 && link["$"].rel != "enclosure") displayObj["link"] = link["$"].href;
            }
          } else {
            if (entries[i].link["$"] && entries[i].link["$"].href && entries[i].link["$"].href.indexOf("http") != -1 && entries[i].link["$"].rel != "enclosure") {
              displayObj["link"] = entries[i].link["$"].href;
            }
          }
          if (i >= 10) break;
          rssContent.push(displayObj);
        }
        this.news = rssContent;
        this.news$.next(limit ? rssContent.slice(0, limit) : rssContent);
        return rssContent;
      })
    );
  }

  public getProduct(id) {
    return this.productMap[id] ? this.productMap[id] : this.productsByProductID[id];
  }

  public getProductSnapshot(productId: string, version?: string, betaProgramId?: any): any {
    let url = environment.paeApiUrl + "product/detail/" + productId;
    if (version || betaProgramId) {
      url += "?";
      if (version) url += "version=" + version;
      if (betaProgramId) url += (version ? "&" : "") + "betaProgramId=" + betaProgramId;
    }
    return this.http.get(url, this._helpers.getHttpOptions());
  }

  public getHardwareDownloads(id) {
    return this.http.get(environment.paeApiUrl + `product/downloads/${id}`, this._helpers.getHttpOptions());
  }

  public getLicenseDetails(licenseId: string): any {
    return this.http.get(environment.paeApiUrl + "product/license/detail/" + licenseId, this._helpers.getHttpOptions());
  }

  search(query) {
    return this.http.get(environment.apiUrl + "products/search/" + encodeURI(query), this._helpers.getHttpOptions()).pipe();
  }

  searchv2(searchField, query, limit = 5) {
    return this.http.get(environment.paeApiUrl + `product/search?searchField=${searchField}&query=${query}&limit=${limit}`, this._helpers.getHttpOptions()).pipe();
  }

  employeeRedeemSphere(coupon) {
    return this.http.get(environment.paeApiUrl + "checkout/redeem-employee-subscription/" + coupon, this._helpers.getHttpOptions()).pipe();
  }

  getSubscriptionProductDetails(asset_id) {
    return this.http.get(environment.apiUrl + "products/software/" + asset_id, this._helpers.getHttpOptions());
  }

  public getTokenizedUrl(folder, filename, siteId, productName, productId, productFileName, productFileSize, productVersionNumber) {
    return this.http
      .post(
        environment.paeApiUrl + "product/refresh-tokenized-url/",
        JSON.stringify({
          folder: folder,
          filename: filename,
          siteId: siteId,
          productName: productName,
          productId: productId,
          productFileName: productFileName,
          productFileSize: productFileSize,
          productVersionNumber: productVersionNumber,
        }),
        this._helpers.getHttpOptions()
      )
      .pipe();
  }

  public getOemKey(args) {
    return this.http.post(environment.paeApiUrl + "product/oemkey/", JSON.stringify(args), this._helpers.getHttpOptions()).pipe(
      map((data: any) => {
        this._dirty = true;
        return data;
      })
    );
  }

  public getLearnProduct(id) {
    return this.learnProducts.filter((item) => item.erpID == id)[0];
  }

  public redeemProduct(id?, configKey?, groupId?, productsToUpgrade?, options?, variant?, timeOutDays?, giveAway?) {
    let args: any = {};
    if (id && Array.isArray(id)) {
      args.ids = id;
    } else if (id) {
      args.id = id;
    }
    if (configKey) args.configKey = configKey;
    if (groupId) args.groupId = groupId;
    if (productsToUpgrade) args.productsToUpgrade = productsToUpgrade;
    if (options) args.options = options;
    if (variant) args.variant = variant;
    if (timeOutDays) args.timeOutDays = timeOutDays;
    if (giveAway) args.giveAway = giveAway;
    return this.http.post(environment.paeApiUrl + "product/redeem/", JSON.stringify(args), this._helpers.getHttpOptions()).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public redeemBetaProduct(betaId) {
    return this.http.put(environment.paeApiUrl + "betas/redeem/" + betaId, JSON.stringify({}), this._helpers.getHttpOptions()).pipe();
  }

  public performTransfer(args) {
    return this.http.post(environment.paeApiUrl + "checkout/transfer/", JSON.stringify(args), this._helpers.getHttpOptions()).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public performUpgrade(args) {
    let url = environment.apiUrl + "products/upgrade-software/";
    if (environment.features.paeapi_product_registration) url = environment.paeApiUrl + "product/upgrade";
    return this.http.post(url, JSON.stringify(args), this._helpers.getHttpOptions()).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public deactivateSoftware(args) {
    let url = "product/deactivate-software/";
    if (args.hardwareSerial) url = "product/deactivate-mixer-addon";

    return this.http.post(environment.paeApiUrl + url, JSON.stringify(args), this._helpers.getHttpOptions()).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public activateSoftware(args) {
    return this.http.post(environment.paeApiUrl + "product/activate-software/", JSON.stringify(args), this._helpers.getHttpOptions()).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public getFirmwareInstructions(product_id: number, version: string, dealer: boolean = false) {
    let url = environment.apiUrl + "products/firmware_instructions/" + product_id + "/" + version;
    if (dealer) url = environment.apiUrl + "products/firmware_instructions/" + product_id + "/" + version + "/true";
    return this.http.get(url, this._helpers.getHttpOptions());
  }

  public getFreeProduct(product: string, source?) {
    let url = environment.paeApiUrl + `product/request-demo?product=${product}`;
    if (source) url += "&source=" + source;
    return this.http.get(url, this._helpers.getHttpOptions());
  }

  public getOptionCardInstallationInstructions(product_id: number, card_product_id: number) {
    return this.http.get(environment.apiUrl + "products/option_card_instructions/" + product_id + "/" + card_product_id, this._helpers.getHttpOptions());
  }

  public updateFirmware(product_id: number, firmware_version: number) {
    return this.http.post(environment.apiUrl + "products/update_firmware/", JSON.stringify({ productid: product_id, firmware_version: firmware_version }), this._helpers.getHttpOptions()).pipe();
  }

  public registerSoftware(productKey: string) {
    let args: any = {
      productkey: productKey,
    };
    if (environment.features.paeapi_product_registration) {
      args.type = "software";
      return this.http.post(environment.paeApiUrl + "product/register", JSON.stringify(args), this._helpers.getHttpOptions()).pipe();
    } else {
      return this.http.post(environment.apiUrl + "products/register_software", JSON.stringify(args), this._helpers.getHttpOptions()).pipe();
    }
  }

  public registerHardware(productKey: string, date: string, asset_id: any = undefined, refreshAllProducts = true) {
    let args: any = {
      purchase_date: date,
      serial_number: productKey,
    };
    if (asset_id) args.asset_id = asset_id;
    if (environment.features.paeapi_product_registration) {
      args.type = "hardware";
      return this.http.post(environment.paeApiUrl + "product/register", JSON.stringify(args), this._helpers.getHttpOptions()).pipe();
    } else {
      return this.http.post(environment.apiUrl + "products/register_hardware", JSON.stringify(args), this._helpers.getHttpOptions()).pipe();
    }
  }

  public registerFenderPromo(serial: string) {
    let args: any = {
      serial: serial,
    };
    return this.http.put(environment.paeApiUrl + "user/redeem-fender-promo", JSON.stringify(args), this._helpers.getHttpOptions()).pipe();
  }

  public deregisterHardware(assetId, refreshAllProducts = true) {
    return this.http.post(environment.paeApiUrl + `product/deregister/${assetId}`, null, this._helpers.getHttpOptions()).pipe(
      map((data) => {
        if (refreshAllProducts) {
          this.getProducts(true).subscribe((result) => {
            return data;
          });
        } else {
          return data;
        }
      })
    );
  }

  public getDealerProducts() {
    return this.http.get(environment.apiUrl + "dealers/products", this._helpers.getHttpOptions()).pipe();
  }

  public getDealerProductDetail(id) {
    return this.http.get(environment.apiUrl + "dealers/product/" + id, this._helpers.getHttpOptions()).pipe();
  }

  public getShopNewNoteworthy() {
    return this.http.get(environment.apiUrl + "users/shop_new_noteworthy", this._helpers.getHttpOptions()).pipe();
  }

  public getPreviousVersions(productId, licenseScheme) {
    return this.http.get(environment.paeApiUrl + `product/previous-versions?productId=${productId}&licenseScheme=${licenseScheme}`, this._helpers.getHttpOptions()).pipe();
  }

  private _handleMobileProductData(data) {
    let prods = {
      software: <any>[],
      extensions: <any>[],
      hardware: <any>[],
    };

    if (data) {
      this.productGroups = {};
      this.prodImagesMap = this.productImagesMap || {};
      this.productsByAcquired = [];
      this.productErpIDs = [];
      let erpIDsAdded = {};
      this.productsByStringIDs = {};
      for (var i in data) {
        if (data[i] && data[i].length) {
          data[i].forEach((item, index) => {
            if (item && item.product) {
              if (item.product.erpId && !erpIDsAdded[item.product.erpId]) {
                this.productErpIDs.push(item.product.erpId);
                this.productsByErpIDs[item.product.erpId] = item;
                erpIDsAdded[item.product.erpId] = true;
              }

              if (item.product.imageUrl) item.product.imageUrl = item.product.imageUrl.replace("studioone_content", "studioone-content");
              // encoding the URI is no longer necessary with cloudflare images.
              if (this.prodImagesMap[item.product.id]) {
                if (item.product.imageUrl) item.product.imageUrl = this.prodImagesMap[item.product.id].url.replace("studioone_content", "studioone-content");
              } else {
                this.prodImagesMap[item.product.id] = {
                  fileName: "",
                  folder: "pictures",
                  id: item.product.id,
                  url: item.product.imageUrl,
                };
              }
              this.productMap[item.id] = item;
              this.productsByStringIDs[item.product.stringId] = item;
              this.productsByAcquired.push(item);
              if (!this.productGroups[item.product.stringId]) {
                this.productGroups[item.product.stringId] = [];
                if (item.product.categoryId == "hardware") {
                  prods.hardware.push(item);
                } else if (item.product.categoryId == "software") {
                  prods.software.push(item);
                } else if (item.product.categoryId == "extension") {
                  prods.extensions.push(item);
                }
              }
            }
          });
        }
      }
      if (prods.software.length) this.prodArraySorter(prods.software);
      if (prods.software.length) this.prodArraySorter(prods.hardware);
      if (prods.software.length) this.prodArraySorter(prods.extensions);
      if (this.productsByAcquired.length) this.prodArraySorter(this.productsByAcquired);
    }

    return prods;
  }

  public prodArraySorter(arr, releaseDateOnly?) {
    arr.sort((a, b) => {
      let comparerA = releaseDateOnly ? a.releaseDate : a.releaseDate || a.aquired;
      let comparerB = releaseDateOnly ? b.releaseDate : b.releaseDate || b.aquired;

      // Format the date if not in the desired format 'YYYY-MM-DD'
      if (comparerA && comparerA.length != 10 && comparerA[4] == "-") {
        comparerA = comparerA.substring(0, 10);
      } else if (comparerA && comparerA.length != 10) {
        comparerA = moment(comparerA).format("YYYY-MM-DD");
      }
      if (comparerB && comparerB.length != 10 && comparerB[4] == "-") {
        comparerB = comparerB.substring(0, 10);
      } else if (comparerB && comparerB.length != 10) {
        comparerB = moment(comparerB).format("YYYY-MM-DD");
      }

      // If comparerA or comparerB is undefined or null, treat it as a very old date to sort it to the bottom
      let aDate = comparerA ? new Date(comparerA) : new Date(-8640000000000000);
      let bDate = comparerB ? new Date(comparerB) : new Date(-8640000000000000);

      // Perform the comparison
      if (aDate > bDate) {
        return -1; // a is newer
      } else if (aDate < bDate) {
        return 1; // b is newer
      } else {
        return 0; // a and b are the same age
      }
    });
  }

  private _handleProductData(data) {
    if (data) {
      this.sphereElligibleUpgradeProducts = [];
      this.productGroups = {};
      this.productsByAcquired = [];
      this.productsByReleaseDate = [];
      this.productErpIDs = [];
      let erpIDsAdded = {};
      this.productsByErpIDs = {};
      this.productsByStringIDs = {};
      this.productsByProductID = {};
      this.prodImagesMap = this.productImagesMap || {};
      try {
        data.forEach((item: ProductListItem, index) => {
          if (item) {
            if (item.imageUrl) item.imageUrl = item.imageUrl.replace("studioone_content", "studioone-content");
            // encoding the URI is no longer necessary with cloudflare images.
            if (this.prodImagesMap[item.id]) {
              if (item.imageUrl) item.imageUrl = this.prodImagesMap[item.id].url.replace("studioone_content", "studioone-content");
            } else {
              this.prodImagesMap[item.id] = {
                fileName: "",
                folder: "pictures",
                id: item.id,
                url: item.imageUrl,
              };
            }
            // item.prettyAquired = moment(item.aquired).format("ll");
            this.productMap[item.id] = item;
            this.productsByAcquired.push(item);
            this.productsByReleaseDate.push(item);

            if (item.stringId.indexOf("studioapp") > -1) {
              let parts = item.stringId.split(".");
              let version = parts[parts.length - 1];
              if (version == "pro" || version == "artist" || version == "producer" || version == "beta") {
                this.hasElligibleS1ForExchange = true;
              } else if (version == "oem" && item.stringId.indexOf("free") == -1) {
                this.hasElligibleS1ForExchange = true;
              }
            }

            if (item.navId && !erpIDsAdded[item.navId]) {
              this.productErpIDs.push(item.navId);
              this.productsByErpIDs[item.navId] = item;
              erpIDsAdded[item.navId] = true;
            }
            this.productsByStringIDs[item.stringId] = item;
            this.productsByProductID[item.id] = item;
          }
        });

        this.productImagesMap = this.prodImagesMap;
        if (this.productsByAcquired.length) this.prodArraySorter(this.productsByAcquired);
        if (this.productsByReleaseDate.length) this.prodArraySorter(this.productsByReleaseDate, true);
      } catch (err) {
        this.products = false;
        this.productDataError$.next(true);
        return false;
      } finally {
        return data;
      }
    } else {
      return data;
    }
  }

  public clearCache() {
    this.products$.next(false);
    this.learnProducts = false;
    this.products = false;
    this._savedProducts = false;
    this.productMap = {};
    this.productDetailsMap = {};
    this.productMap = {};
    this.productsByAcquired = [];
    this.productErpIDs = [];
    this.productsByErpIDs = {};
    this.productsByStringIDs = {};
    this.productsByProductID = {};
    this.prodImagesMap = {};
    this.productGroups = {};
  }

  getProductTransferDetails(productId: any) {
    return this.http.get(environment.apiUrl + `stripe/retrieve_product/${productId}`, this._helpers.getHttpOptions()).pipe();
  }

  public elligleToRedeemStudioOneSeven: boolean = false;
  public s16ProductToUpgradeTo7: any;
  checkStudioOneSevenRedeemElligibility() {
    if (
      this.productMap[environment.product_ids.s16pro]?.assetIds &&
      (!this.productMap[environment.product_ids.s17pro] || !this.productMap[environment.product_ids.s17pro].assetIds?.length) &&
      environment.features.studio_one_seven &&
      new Date() < new Date("2025-01-01")
    ) {
      this.getDetailForAssetIds(environment.product_ids.s16pro, this.productMap[environment.product_ids.s16pro].assetIds).subscribe((result) => {
        let elligibleResults = result.filter((item) => new Date(item.aquired) >= this.s1SixToSevenElligiblePurchaseDate && !item.upgraded);
        if (elligibleResults.length) {
          this.elligleToRedeemStudioOneSeven = true;
          this.s16ProductToUpgradeTo7 = elligibleResults[0];
          this.redeemS17ElligibilityRetrieved$.next(true);
        } else {
          this.redeemS17ElligibilityRetrieved$.next(true);
        }
      });
    } else {
      this.redeemS17ElligibilityRetrieved$.next(true);
    }
  }
}
