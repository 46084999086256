import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

import { BaseComponent, HelperService, UserService, WindowService, WorkspaceService } from "@mypxplat/xplat/core";
import { AppService, AvoService } from "@mypxplat/xplat/web/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from "@angular/fire/compat/firestore";

@Component({
  selector: "myp-add-workspace",
  templateUrl: "add-workspace.component.html",
})
export class AddWorkspaceComponent extends BaseComponent implements OnInit {
  @Input() editing: any;
  @Input() initialCollaborator: any;
  @Output() workspaceSaved: EventEmitter<any> = new EventEmitter();
  @Output() workspaceDeleted: EventEmitter<any> = new EventEmitter();
  public subscribed: boolean = true;
  public dmsOnly: boolean = false;
  public loading: boolean = false;
  public preferences: any = {
    all: true,
    hourly: true,
    direct_messages: true,
    inline_comments: true,
    none: false,
  };
  public args: any = {
    name: "",
    description: "",
  };

  constructor(
    userService: UserService,
    public workspaceService: WorkspaceService,
    public appService: AppService,
    public activeModal: NgbActiveModal,
    private _router: Router,
    public db: AngularFirestore,
    public avoService: AvoService,
    private _win: WindowService,
    public helperService: HelperService
  ) {
    super(userService);
  }

  ngOnInit() {
    if (this.editing) {
      this.args = {
        id: this.editing.id,
        name: this.editing.name,
        description: this.editing.description,
      };
      this.loading = true;
      this.workspaceService.getWorkspacePreferences().subscribe((myprefs: any) => {
        this.loading = false;
        this.preferences.hourly = myprefs.preferences[this.editing.id] ? myprefs.preferences[this.editing.id].hourly : true;
        this.preferences.direct_messages = myprefs.preferences[this.editing.id] ? myprefs.preferences[this.editing.id].direct_messages : true;
        this.preferences.inline_comments = myprefs.preferences[this.editing.id] ? myprefs.preferences[this.editing.id].inline_comments : true;
        this.preferences.all = this.preferences.hourly && this.preferences.direct_messages && this.preferences.inline_comments;
        this.preferences.none = !this.preferences.hourly && !this.preferences.direct_messages && !this.preferences.inline_comments;
      });
    }
  }

  togglePreference(pref) {
    this.preferences[pref] = !this.preferences[pref];
    if (pref == "all") {
      this.preferences.hourly = true;
      this.preferences.direct_messages = true;
      this.preferences.inline_comments = true;
      this.preferences.none = false;
    } else if (pref == "none") {
      this.preferences.all = false;
      this.preferences.hourly = false;
      this.preferences.direct_messages = false;
      this.preferences.inline_comments = false;
    }
    if (!this.preferences.direct_messages && !this.preferences.inline_comments && !this.preferences.hourly) {
      this.preferences.all = false;
      this.preferences.none = true;
    } else if (this.preferences.direct_messages && this.preferences.inline_comments && this.preferences.hourly) {
      this.preferences.all = true;
      this.preferences.none = false;
    }
    if (!this.preferences.direct_messages || !this.preferences.inline_comments || !this.preferences.hourly) {
      this.preferences.all = false;
    }
    if (this.preferences.direct_messages || this.preferences.inline_comments || this.preferences.hourly) {
      this.preferences.none = false;
    }
    let args = {
      hourly: this.preferences.hourly,
      direct_messages: this.preferences.direct_messages,
      inline_comments: this.preferences.inline_comments,
      workspace_id: this.args.id,
    };
    this.workspaceService.updateWorkspacePreferences(args).subscribe((result) => {});
  }

  delete() {
    if (
      confirm(
        "Are you sure you want to delete this workspace? This will remove this workspace from your account, all collaborators accounts, and remove all messages and files shared. This action cannot be undone."
      )
    ) {
      this.workspaceDeleted.next(true);
      this.activeModal.close();
    }
  }

  leave() {
    if (
      confirm(
        "Are you sure you want to leave this workspace? This will remove your access to this workspace, its timeline and shared files. To be re-added, you'll need to contact the owner of this workspace."
      )
    ) {
      this.workspaceService.leaveWorkspace(this.editing.id).subscribe((result) => {
        this.activeModal.close();
        this._router.navigate(["workspaces"]);
      });
    }
  }

  submit() {
    this.loading = true;
    this.workspaceService.addEditWorkspace(this.args).subscribe({
      next: (workspace: any) => {
        if (this.initialCollaborator) {
          this.workspaceService.findCollaborator(this.initialCollaborator.theirID, workspace.id).subscribe((result) => {
            let timelineRef = this.db.collection("timelines").doc(workspace.firebase_timeline_id).ref;
            timelineRef.get().then((result) => {
              let data: any = result.data();
              let contributors = data.contributors;
              contributors[this.initialCollaborator.theirID] = true;
              timelineRef.update({ contributors });
            });
            this.loading = false;
            this.activeModal.close();
            this._router.navigate(["../workspaces/detail", workspace.id]);
          });
        } else {
          this.loading = false;
          this.activeModal.close();
          if (this.editing) {
            this.workspaceSaved.next(workspace);
          } else {
            this._router.navigate(["../workspaces"]);
            this._win.setTimeout(() => {
              this._router.navigate(["../workspaces/detail", workspace.id]);
            }, 300);
          }
        }
        this.avoService.trackEvent().workspaceCreated({
          siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
          workspaceId: parseInt(workspace.id),
        });
      },
      error: (error) => {
        this.loading = false;
        alert(this.helperService.retrieveErrorMessage(error));
      },
    });
  }
}
