<h4 class="content-title">Subscription Product List</h4>
<div class="text-muted m-b-30">Manage which products come with Studio One Pro+.</div>
<myp-inline-loader [loading]="true" *ngIf="!subscriptionProducts.software" height="400px" spinnerName="sub-products-loader"></myp-inline-loader>

<ng-container *ngIf="subscriptionProducts.software">
  <myp-segmented-bar [white]="true" (selected)="selectSection($event.id)" [options]="sections"></myp-segmented-bar>
  <div class="m-t-30">
    <div class="form-group m-t-20">
      <label class="control-label" for="description">Products</label>
      <small class="form-text text-muted">Search below to add products to what comes with Studio One Pro+.</small>
      <input class="form-control" [(ngModel)]="associatedProductsQuery" />
    </div>
    <div class="product-results m-b-30 trans-bg border-radius10 p-10" *ngIf="associatedProductsQuery && associatedProductResults && associatedProductResults.length">
      <ng-container>
        <myp-list-item *ngFor="let product of associatedProductResults" [title]="product.title" [subData]="[product.DTYPE]" [includeChevron]="false" [showImage]="false">
          <div class="button-content">
            <span class="c-link-blue font-weight-bold t-12" *ngIf="product.added">Added!</span>
            <fa-icon icon="plus" placement="left" *ngIf="!product.added" [ngbTooltip]="'Add Product'" (click)="setAssociatedProd(product)" class="action green circle-icon-btn m-r-10"></fa-icon>
          </div>
        </myp-list-item>
        <div class="m-t-20 text-center">
          <button class="btn btn-secondary btn-sm" (click)="associatedProductsQuery = ''; associatedProductResults = []">Close</button>
        </div>
      </ng-container>
    </div>

    <ng-container *ngFor="let product of subscriptionProducts[selectedSection == 'software' ? 'software' : 'extensions']">
      <myp-list-item [includeChevron]="false" [imageRound]="false" [imageBorder]="false" [photo]="product.imageUrl" [title]="product.title">
        <div class="button-content">
          <fa-icon icon="times" placement="left" (click)="removeProduct(product)" [ngbTooltip]="'Remove'" class="action circle-icon-btn"></fa-icon>
        </div>
      </myp-list-item>
      <hr />
    </ng-container>
  </div>
</ng-container>
