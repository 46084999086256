<h4 class="content-title">Product Categories</h4>
<span class="text-muted">Drag and drop to reorder categories and subcategories.</span>
<myp-inline-loader [loading]="loading" *ngIf="loading" height="200px" spinnerName="keygenloader"></myp-inline-loader>
<div class="row m-t-20" *ngIf="!loading">
  <div class="form-group col-md-12" dragula="CATEGORIES" [(dragulaModel)]="categories">
    <div class="product-category" *ngFor="let cat of categories">
      <div class="category-name category-handle">
        {{ cat.title }}
        <fa-icon icon="pencil" (click)="addEditCategory(cat)"></fa-icon>
        <fa-icon icon="times" (click)="deleteCat(cat)"></fa-icon>
      </div>

      <div class="subcategories">
        <div [dragula]="getSubcategoryGroupName(cat)" [(dragulaModel)]="cat.children">
          <div class="product-subcategory" *ngFor="let subcat of cat.children">
            <div class="subcategory-name subcategory-handle">
              {{ subcat.title }}
              <fa-icon icon="pencil" (click)="addEditCategory(subcat, cat)"></fa-icon>
              <fa-icon icon="times" (click)="deleteCat(subcat, cat)"></fa-icon>
            </div>
          </div>
        </div>

        <div class="m-l-10">
          <button class="small-btn" (click)="addEditCategory(null, cat)">
            <fa-icon icon="plus"></fa-icon>
            Add Subcategory to {{ cat.title }}
          </button>
        </div>
      </div>
    </div>

    <button class="brand-btn-reverse sm" (click)="addEditCategory()">
      <fa-icon icon="plus"></fa-icon>
      Add Category
    </button>
    &nbsp;
    <button class="brand-btn-reverse sm" (click)="saveCurrentOrder()">
      <fa-icon [icon]="savingOrder ? 'spinner' : 'check'" [animation]="savingOrder ? 'spin' : undefined"></fa-icon>
      {{ savingOrder ? "Saving" : "Save" }} Current Sort Order
    </button>
  </div>
</div>

<ng-template #addEditCategoryModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-password-update">
      {{ addingCategory ? "Add " + (editingCategoryParent ? "Subcategory to " + editingCategoryParent.title : "Category") : "Edit " + editingCategory.title }}
    </h4>
    <button ngbAutofocus class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="form-group col-md-6">
        <label class="control-label">String ID</label>
        <input id="string_id" class="form-control" [disabled]="!addingCategory" [(ngModel)]="editingCategory.string_id" />
      </div>
      <div class="form-group col-md-6">
        <label class="control-label">Title (English)</label>
        <input id="title" class="form-control" [(ngModel)]="editingCategory.title" />
      </div>
      <div class="col-12 d-flex justify-content-between">
        <h4 class="section-header">Languages</h4>
        <button class="small-btn text-nowrap" [disabled]="!editingCategory.languages.en" (click)="translateCategories()">
          <fa-icon icon="wand-magic-sparkles"></fa-icon>
          &nbsp; Auto Translate from English
        </button>
      </div>
      <div class="form-group col-md-6">
        <label class="control-label">
          <img src="./assets/images/flags/us.png" style="width: 22px; margin-right: 5px" />
          English
        </label>
        <input id="english" class="form-control" [(ngModel)]="editingCategory.languages.en" />
      </div>
      <div class="form-group col-md-6">
        <label class="control-label">
          <img src="./assets/images/flags/es.png" style="width: 22px; margin-right: 5px" />
          Spanish
        </label>
        <input id="spanish" class="form-control" [(ngModel)]="editingCategory.languages.es" />
      </div>
      <div class="form-group col-md-6">
        <label class="control-label">
          <img src="./assets/images/flags/de.png" style="width: 22px; margin-right: 5px" />
          German
        </label>
        <input id="german" class="form-control" [(ngModel)]="editingCategory.languages.de" />
      </div>
      <div class="form-group col-md-6">
        <label class="control-label">
          <img src="./assets/images/flags/it.png" style="width: 22px; margin-right: 5px" />
          Italian
        </label>
        <input id="italian" class="form-control" [(ngModel)]="editingCategory.languages.it" />
      </div>
      <div class="form-group col-md-6">
        <label class="control-label">
          <img src="./assets/images/flags/fr.png" style="width: 22px; margin-right: 5px" />
          French
        </label>
        <input id="french" class="form-control" [(ngModel)]="editingCategory.languages.fr" />
      </div>
      <div class="form-group col-md-6">
        <label class="control-label">
          <img src="./assets/images/flags/tr.png" style="width: 22px; margin-right: 5px" />
          Turkish
        </label>
        <input id="turkish" class="form-control" [(ngModel)]="editingCategory.languages.tr" />
      </div>
      <div class="form-group col-md-6">
        <label class="control-label">
          <img src="./assets/images/flags/cn.png" style="width: 22px; margin-right: 5px" />
          Chinese (Simplified)
        </label>
        <input id="chinese" class="form-control" [(ngModel)]="editingCategory.languages.cn" />
      </div>
      <div class="form-group col-md-6">
        <label class="control-label">
          <img src="./assets/images/flags/jp.png" style="width: 22px; margin-right: 5px" />
          Japanese
        </label>
        <input id="japanese" class="form-control" [(ngModel)]="editingCategory.languages.jp" />
      </div>
      <div class="form-group col-md-6">
        <label class="control-label">
          <img src="./assets/images/flags/pl.png" style="width: 22px; margin-right: 5px" />
          Polish
        </label>
        <input id="polish" class="form-control" [(ngModel)]="editingCategory.languages.pl" />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="submit()">Submit</button>
  </div>
</ng-template>
