import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ContentService, EventBusService, UserService, WindowService, WorkspaceService } from "@mypxplat/xplat/core";
import { WorkspaceFilesBaseComponent } from "@mypxplat/xplat/features";
import { AppService } from "@mypxplat/xplat/web/core";
import { take } from "rxjs/operators";
import { CreateFolderComponent, MoveFileComponent } from "../modals";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "myp-workspace-files",
  templateUrl: "workspace-files.component.html",
})
export class WorkspaceFilesComponent extends WorkspaceFilesBaseComponent implements OnInit, OnDestroy {
  @Input() unReadCounts: any = {};

  @Input() collaboratorMap: any;

  @Output() fileClicked: EventEmitter<any> = new EventEmitter();
  @Output() fileDeleted: EventEmitter<any> = new EventEmitter();
  @Output() folderChange: EventEmitter<any> = new EventEmitter();
  @Output() fileSelectedForBatchOperation: EventEmitter<any> = new EventEmitter();
  @Output() msgOptionsClicked: EventEmitter<any> = new EventEmitter();
  @Output() playAudioClicked: EventEmitter<any> = new EventEmitter();
  @Output() downloadFileClicked: EventEmitter<any> = new EventEmitter();
  @Output() batchOperationClicked: EventEmitter<any> = new EventEmitter();
  @Output() refreshedFiles: EventEmitter<any> = new EventEmitter();
  @Output() addFileClicked: EventEmitter<any> = new EventEmitter();
  public batchSelectionCount: number = 0;

  public newFileMode: boolean = true;

  constructor(
    userService: UserService,
    public workspaceService: WorkspaceService,
    public appService: AppService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private _eventbusService: EventBusService,
    private _win: WindowService,
    public translate: TranslateService,
    public contentService: ContentService
  ) {
    super(userService, workspaceService, contentService);
  }

  ngOnInit() {
    this.workspaceService.getWorkspaceFolders(this.workspace.id).subscribe((folders: any[]) => {
      this.folders = folders;
      this.displayedFolders = folders;

      // need to find the folder in the tree of folders by id

      let queryParamFolder = this.findFolderById(this.folders, this.activatedRoute.snapshot.queryParams.folder_id);
      if (this.activatedRoute.snapshot.queryParams.folder_id) this.selectFolder(queryParamFolder);
      this.getFiles(false, true);
    });
  }

  folderBack() {
    this.selectFolder(this.folderBreadCrumbSelection[this.folderBreadCrumbSelection.length - 2]);
  }

  goHome() {
    this.folderBreadCrumbSelection = ["Home"];
    this.selectFolder("Home");
  }

  selectFolder(folder) {
    this.selectedFolder = folder;
    this.displayedFolders = !this.selectedFolder || this.selectedFolder == "Home" ? this.folders : this.selectedFolder.children;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: folder?.id ? { folder_id: folder.id } : {},
    });
    this.getFiles(folder);
    this.constructBreadcrumb(this.folders, folder.id);
  }

  removeExtension(filename) {
    return filename.split(".")[0];
  }

  fileItemClick(args) {
    if (this.batchOperationMode) {
      this.selectFileForBatchOperation(args);
    } else {
      this.fileClicked.next(args);
    }
  }

  deleteBatchFiles(files) {
    let msg = this.translate.instant("sphere.workspaces.confirm_remove_file");
    if (files.length) msg = this.translate.instant("sphere.workspaces.confirm_remove_files", { count: files.length });
    if (confirm(msg)) {
      this.isGettingFiles = true;
      this.workspaceService.batchDeleteFiles(files, this.workspace.id).subscribe({
        next: (result) => {
          if (result.deleted.length) {
            this.getFiles(this.selectedFolder);
          } else {
            alert("There was an error deleting the files, please try again.");
          }
          this.isGettingFiles = false;
        },
        error: (error) => {
          this.isGettingFiles = false;
          this.appService.alertError(error);
        },
      });
    }
  }

  deleteFile(file) {
    if (!file.grouping_id && !file.format_grouping_id) {
      if (confirm(this.translate.instant("sphere.workspaces.confirm_remove_file"))) {
        this.workspaceService.deleteFile(file.id).subscribe((result) => {
          this.fileDeleted.next(file);
          this.getFiles(this.selectedFolder);
        });
      }
    } else {
      let filesToDelete = this.contentService.groupedFilesMap[file.grouping_id] || this.contentService.groupedFormatsMap[file.format_grouping_id];
      if (filesToDelete.length) {
        this.deleteBatchFiles(filesToDelete);
      }
    }
  }

  msgOptions(file, $event) {
    this.msgOptionsClicked.next({ file: file, event: $event });
  }

  decodeName(string) {
    return string ? decodeURIComponent(string) : string;
  }

  folderOptions(folder, event) {
    if (event) event.stopPropagation();
    let actions = ["Delete Folder"];
    if (!this.workspaceService.syncedFolderMap[folder.folder_id]) {
      actions.unshift("Move Folder");
      actions.unshift("Rename Folder");
    }
    let args: any = {
      title: folder.filename,
      actions: actions,
    };
    this._eventbusService.emit(this._eventbusService.types.showActionChooser, args);
    this._eventbusService
      .observe(this._eventbusService.types.actionChosen)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          if (result == "Rename Folder") {
            this.showCreateFolder(folder);
          } else if (result == "Move Folder") {
            this.showMoveFolder(false, folder);
          } else if (result == "Delete Folder") {
            this._win.setTimeout(() => {
              let msg =
                "Are you sure you would like to delete this folder? You can either delete all its contents, or move all its contents to the Home folder. If you delete all contents, the contents will be permanently deleted.";
              let args: any = {
                title: msg,
                actions: ["Delete All Contents", "Move All Contents To Home"],
              };
              this._eventbusService.emit(this._eventbusService.types.showActionChooser, args);
              this._eventbusService
                .observe(this._eventbusService.types.actionChosen)
                .pipe(take(1))
                .subscribe((result) => {
                  if (result) {
                    if (result == "Delete All Contents") {
                      this.contentService.deleteFolderWithContents(folder.id).subscribe((result) => {
                        this.refreshFolders(true);
                      });
                    } else if (result == "Move All Contents To Home") {
                      this.workspaceService.deleteFolder(folder.id).subscribe((result) => {
                        this.refreshFolders(true);
                      });
                    }
                  }
                });
            }, 300);
          }
        }
      });
  }

  showCreateFolder(renamingFolder?) {
    const modalRef = this.appService.showModal(CreateFolderComponent, { size: "sm" });
    if (this.selectedFolder != "Home") modalRef.componentInstance.parentFolder = this.selectedFolder;
    if (renamingFolder) modalRef.componentInstance.folder = renamingFolder;
    modalRef.componentInstance.workspaceID = this.workspace.id;
    modalRef.componentInstance.folderCreated.pipe(take(1)).subscribe((result) => {
      this.refreshFolders();
      this.refreshedFiles.next(result);
    });
  }

  refreshFolders(alsoGetFiles?) {
    this.isGettingFiles = true;
    this.workspaceService.getWorkspaceFolders(this.workspace.id).subscribe((folders: any[]) => {
      this.folders = folders;
      this.displayedFolders = !this.selectedFolder || this.selectedFolder == "Home" ? this.folders : this.findFolderById(this.folders, this.selectedFolder.id).children;
      if (alsoGetFiles) {
        this.getFiles(false, true);
      } else {
        this.isGettingFiles = false;
      }
    });
  }

  selectFileForBatchOperation(file) {
    this.fileSelectedForBatchOperation.next(file);
    this.batchSelectionCount = 0;
    for (var i in this.workspaceService.folderMap) {
      this.batchSelectionCount += this.workspaceService.folderMap[i].files.filter((item) => item.selectedForBatchOperation).length;
    }
  }

  showMoveFolder(files?, folderToMove?) {
    const modalRef = this.appService.showModal(MoveFileComponent, { size: "sm" });
    let moveFileMap = {};
    let moveFiles = [];

    if (files) {
      files.forEach((file) => {
        if (file.grouping_id) {
          files = [...files, ...this.contentService.groupedFilesMap[file.grouping_id]];
        }
      });
      files.forEach((file) => {
        if (!moveFileMap[file.id]) {
          moveFiles.push(file);
          moveFileMap[file.id] = file;
        }
      });
    }

    modalRef.componentInstance.folders = this.folders;
    modalRef.componentInstance.files = files || [];
    modalRef.componentInstance.workspaceID = this.workspace.id;
    if (folderToMove) modalRef.componentInstance.folderToMove = folderToMove;
    modalRef.componentInstance.moved.pipe(take(1)).subscribe((result) => {
      if (folderToMove) {
        this.refreshFolders();
      } else {
        this.getFiles(this.selectedFolder);
      }
    });
  }

  ngOnDestroy() {}
}
