import { Component, ViewChild, ElementRef, OnInit, OnDestroy, HostListener, Output, EventEmitter, NgZone, Input } from "@angular/core";

import { SatelliteDetailBaseComponent } from "@mypxplat/xplat/features";
import { UserService, HelperService, WorkspaceService, WindowService, EventBusService, ContentService } from "@mypxplat/xplat/core";
import { AngularFirestoreCollection, AngularFirestore, AngularFirestoreDocument } from "@angular/fire/compat/firestore";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "@mypxplat/xplat/web/core";
import { WorkspaceUploadComponent, SubscribeComponent, SphereSubscribeStripeComponent } from "../modals";
import { take, map, takeUntil } from "rxjs/operators";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { FileUploader } from "ng2-file-upload";
import firebase from "firebase/compat/app";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { MessageInputComponent } from "../message-input/message-input.component";

declare var window: any;

@Component({
  selector: "myp-shared-satellite-detail",
  templateUrl: "satellite-detail.component.html",
})
export class SatelliteDetailComponent extends SatelliteDetailBaseComponent implements OnInit, OnDestroy {
  // @HostListener("window:beforeunload", ["$event"])
  // doSomething($event) {
  //   this.conversationRef.update({
  //     viewing: firebase.firestore.FieldValue.arrayRemove(this.userService.user.id),
  //     typing: firebase.firestore.FieldValue.arrayRemove(this.userService.user.id),
  //   });
  // }

  public loading: boolean = false;
  public descriptionExpanded: boolean = false;
  public selectedView: "conversation" | "files" = "conversation";
  public firestore: AngularFirestoreCollection;
  public messagesRef: AngularFirestoreCollection;
  public conversationRef: AngularFirestoreDocument<any>;
  public loadingConversation: boolean = true;

  public refreshFileDone: boolean = true;
  public shouldShowShowMore: boolean = false;
  public playingAudioFile: any;
  private _isListeningToEnded: boolean = false;

  public displayedSection = "conversation";
  public conversationFilesViewHeight: number = 100;

  public cachedUserID: any;
  public activeUsers: any = {};
  public typingUsers: any;
  public hasBaseDropZoneOver: boolean = false;
  public routerEvents: any;
  public batchOperationMode: boolean = false;
  public batchOperationSelection: any;
  public hideBatchDownloadNotice = false;
  public isChrome: boolean = false;
  public showingAllMessages: boolean = false;
  public attingCollabs: Array<any>;
  public highlightedAtCollab: any;
  public selectedAtCollab: any;
  public selectedAtCollabsInMessage: any = {};
  public fileRefs: any = {};
  public unReadCounts: any = {};
  public totalUnreadFileMessages: number;

  public detailsWidth: string = "40%";
  @Output() connectionUpdated: EventEmitter<any> = new EventEmitter();
  @Input() connectionData: any;
  @ViewChild("chatWindow", { static: false }) chatWindow: ElementRef;
  @ViewChild("conversationFilesView", { static: false }) conversationFilesView: ElementRef;
  @ViewChild("descTextSpan", { static: false }) descTextSpan: ElementRef;
  @ViewChild("msgInput") msgInput: MessageInputComponent;
  public uploader: FileUploader;
  public activeFolder: any;

  constructor(
    public windowService: WindowService,
    public userService: UserService,
    public helperService: HelperService,
    private _activatedRoute: ActivatedRoute,
    public workspaceService: WorkspaceService,
    public appService: AppService,
    public db: AngularFirestore,
    public fbAuth: AngularFireAuth,
    private _eventbusService: EventBusService,
    public router: Router,
    public zone: NgZone,
    public translate: TranslateService,
    public spinner: NgxSpinnerService,
    public contentService: ContentService
  ) {
    super(userService, windowService, workspaceService, zone);
  }

  ngOnInit() {
    this.cachedUserID = this.userService.user.id += "";
    this.uploader = new FileUploader({
      isHTML5: true,
      method: "PUT",
      queueLimit: 1,
      autoUpload: false,
      disableMultipart: true,
      url: "",
    });
    if (this._activatedRoute.snapshot.params["id"]) {
      this.initView();
    }
    // this.routerEvents = this.router.events.subscribe((result) => {
    //   // this allows you to "navigate" to another workspace detail from this page
    //   if (result instanceof NavigationEnd) {
    //     console.log("NAVIGATE END");
    //     this.initView(this._activatedRoute.snapshot.params["id"]);
    //   }
    // });
  }

  initView() {
    this.loading = false;
    // this.workspaceLoaded.next(result);
    // this.setupWorkspace(result);

    this.selectView(this._activatedRoute.snapshot.params["view"]);
    if (this.connectionData.status == "active" || this.connectionData.status == "deleted") {
      if (this.connectionData.conversationId) {
        this.initMessages();
      } else {
        // /create a conversation for this connection.
        if (this.connectionData.status == "active") {
          let conversationRef = this.db.collection("linked_user_conversations");
          conversationRef
            .add({
              users: [this.user.id, this.connectionData.userId],
              created: new Date(),
            })
            .then((doc) => {
              let conversationID = doc.id;
              let myRef = this.db.collection("user_profiles").doc(this.user.id).collection("linked_users").doc(this.connectionData.userId);
              let theirRef = this.db.collection("user_profiles").doc(this.connectionData.userId).collection("linked_users").doc(this.user.id);
              let args = { conversationId: conversationID };
              let promises = [myRef.update(args), theirRef.update(args)];
              Promise.all(promises).then((result) => {
                myRef.ref.get().then((doc) => {
                  this.connectionData.conversationId = conversationID;
                  this.initMessages();
                });
              });
            });
        }
      }
    }
    window.scrollTo(0, 0);
  }

  nudge() {
    let emailNotificationArgs: any = {
      email: this.connectionData.email,
      inviterName: this.user.firstName + " " + this.user.lastName,
      message: this.connectionData.message,
      user_has_account: this.connectionData.userId ? true : false,
    };
    this.contentService.sendEmail(emailNotificationArgs, "notify_metro_connection_request").subscribe();
    alert("Notification sent!");
  }

  updateInvitation(accepted) {
    let myRef = this.db.collection("user_profiles").doc(this.user.id).collection("linked_users").doc(this.connectionData.userId);
    let theirRef = this.db.collection("user_profiles").doc(this.connectionData.userId).collection("linked_users").doc(this.user.id);
    let conversationRef = this.db.collection("linked_user_conversations");
    let conversationID;
    if (accepted) {
      conversationRef
        .add({
          users: [this.user.id, this.connectionData.userId],
          created: new Date(),
        })
        .then((doc) => {
          conversationID = doc.id;
          let args: any = { status: "active" };
          if (conversationID) args.conversationId = conversationID;
          let promises = [
            myRef.update(args),
            theirRef.update(args),
            this.db
              .collection("user_profiles")
              .doc(this.user.id)
              .update({ linkedUsersTag: firebase.firestore.FieldValue.increment(1) }),
            this.db
              .collection("user_profiles")
              .doc(this.connectionData.userId)
              .update({ linkedUsersTag: firebase.firestore.FieldValue.increment(1) }),
          ];
          Promise.all(promises).then((result) => {
            myRef.ref
              .get()
              .then((doc) => {
                this.connectionData = doc.data();
                this.initView();
              })
              .catch(() => {});
          });
        });
    } else {
      let args: any = { status: "rejected" };
      myRef.delete();
      theirRef.update(args);
      this.connectionUpdated.next(true);
    }
    this.contentService
      .sendEmail(
        {
          email: this.connectionData.email,
          name: this.userService.user.firstName + " " + this.userService.user.lastName,
          status: accepted ? "accepted" : "rejected",
        },
        "notify_metro_connection_response"
      )
      .subscribe();
  }

  deleteInvitation() {
    let myRef = this.db.collection("user_profiles").doc(this.user.id).collection("linked_users").doc(this.connectionData.userId);
    myRef.delete();
    this.connectionUpdated.next(true);
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.measureUI();
  }

  setupWorkspace(details) {
    this.workspace = details;
    this.myWorkspace = this.workspace.user_id == this.user.id;
    this.windowService.setTimeout(() => {
      this.measureUI();
    }, 100);
    if (this.workspace.mainMix) {
      this.workspace.mainMix.creator = this.workspace.collaborators.filter((item) => item.user_id == this.workspace.mainMix.user_id)[0];
    }
    this.workspace.collaborators.forEach((item) => {
      this.collaboratorMap[item.user_id] = item;
    });
    this.userService.getStorageDetails(this.workspace.user_id).subscribe((result) => {
      this.userStorageDetails = result;
    });
    this.myRole = this.workspace.collaborators.filter((item) => item.user_id == this.user.id)[0].role;
    this.windowService.setTimeout(() => {
      if (this.descTextSpan && this.descTextSpan.nativeElement) {
        if (this.descTextSpan.nativeElement.offsetHeight > 60) {
          this.shouldShowShowMore = true;
        }
      }
    }, 500);
  }

  messageValueChange(args) {
    this.newMsg = args;
  }

  showImage(src) {
    this._eventbusService.emit(this._eventbusService.types.viewFullscreenImage, src);
  }

  measureUI() {
    if (this.conversationFilesView && this.conversationFilesView.nativeElement) {
      let offsetTop = this.conversationFilesView.nativeElement.getBoundingClientRect().y;
      const height = window.innerHeight - offsetTop;
      this.conversationFilesViewHeight = height - 60;
    }
    if (window.innerWidth < 992) {
      this.detailsWidth = "100%";
    } else {
      this.detailsWidth = "40%";
    }
  }

  watchTimelineEvents() {
    this.conversationRef
      .snapshotChanges()
      .pipe(
        takeUntil(this.destroy$),
        map((conversationData: any) => {
          let data = conversationData.payload.data();
          let rtn = {
            ...data,
            key: conversationData.payload.id,
          };
          return rtn;
        })
      )
      .subscribe((result) => {
        this.activeUsers = {};
        if (result.viewing && result.viewing.length) {
          result.viewing.forEach((item) => (this.activeUsers[item] = true));
        }

        this.typingUsers = result.typing && result.typing.length ? result.typing.filter((item) => item != this.user.id) : [];
      });
  }

  initMessages(all = false) {
    this.messages = [];
    this.displayedMessages = [];
    if (this.messagesSubscription) this.messagesSubscription.unsubscribe();
    this.conversationRef = this.db.collection("linked_user_conversations").doc(this.connectionData.conversationId);
    this.updateMyReadStatus();
    this.watchTimelineEvents();
    this.messagesRef = this.conversationRef.collection("messages", (ref) => {
      if (all) {
        this.showingAllMessages = true;
        return ref.orderBy("timestamp");
      } else {
        this.showingAllMessages = false;
        return ref.orderBy("timestamp").limitToLast(100);
      }
    });
    this.messagesSubscription = this.messagesRef
      .snapshotChanges()
      .pipe(
        takeUntil(this.destroy$),
        map((changes: any) =>
          changes.map((c) => {
            let data = c.payload.doc.data();
            // if (data.file) {
            //   data.file.url = this.workspaceService.buildFileUrl(this.workspace.user_id, this.workspace.id, data.file);
            // }

            let rtn = {
              ...data,
              key: c.payload.doc.id,
            };
            return rtn;
          })
        )
      )
      .subscribe(
        (result) => {
          this.updateMessages(result);
          // this.windowService.setTimeout(() => {
          //   this.updateMyReadStatus();
          // }, 1000);
          // this.refreshFiles();
          this.loadingConversation = false;
          this.scrollChatToBottom();
          this.windowService.setTimeout(() => {
            this.scrollChatToBottom();
          }, 1000);
        },
        (err) => {}
      );
  }

  updateMessages(result) {
    this.messages = result;
    if (this.messages.length > this.displayedMessages.length) {
      for (let i = this.displayedMessages.length; this.messages.length > i; i++) {
        this.displayedMessages.push(this.messages[i]);
      }
    } else if (this.messages.length > 0 && this.messages.length == this.displayedMessages.length) {
      this.displayedMessages.forEach((item, index, theArray) => {
        if (JSON.stringify(item) != JSON.stringify(this.messages[index])) {
          theArray[index] = this.messages[index];
        }
      });
    } else if (this.messages.length < this.displayedMessages.length) {
      // the user deleted a message
      this.displayedMessages.splice(this.messageDeleteIndex, 1);
      this.messageDeleteIndex = undefined;
    }
  }

  msgOptions(msg?, file?, event?) {
    if (event) event.stopPropagation();
    if (msg && msg.file) file = msg.file;
    if (file) file = this.sharedFilesMap[file.id];
    let title = file ? file.filename : msg.message;
    if (file && file.grouping_id && this.groupedFilesMap[file.grouping_id])
      title = "Stem Upload from Studio One 5 (" + this.groupedFilesMap[file.grouping_id].length + " file" + (this.groupedFilesMap[file.grouping_id].length > 1 ? "s" : "") + ")";
    let args: any = {
      title: title,
      actions: [],
    };
    if (!file || (file && !file.grouping_id && ((file && file.user_id == this.user.id) || !file || this.myWorkspace))) {
      args.actions.push(file ? "Edit File Description" : "Edit Message");
    }
    if (file && !file.grouping_id && !file.mainmix && this.workspaceService.audioFiles[file.extension]) args.actions.push("Mark as Main Mix");
    if (file && !file.grouping_id) args.actions.push("Download File");
    if (file && file.grouping_id) args.actions.push("Download Stems");
    if (this.myWorkspace || this.myRole == "admin" || (msg && msg.author.id == this.user.id) || (this.myRole == "contributor" && file && file.user_id == this.user.id)) {
      args.actions.push(file ? (file.grouping_id ? "Delete Stems" : "Delete File") : "Delete Message");
    }
    this._eventbusService.emit(this._eventbusService.types.showActionChooser, args);
    this._eventbusService
      .observe(this._eventbusService.types.actionChosen)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          if (result == "Edit Message" || result == "Edit File Description") {
            this.editMsg(msg, file);
          } else if (result == "Download File" || result == "Download Stems") {
            this.downloadFile(false, file);
          } else if (result == "Delete Message" || result == "Delete File" || result == "Delete Stems") {
            this.deleteMsg(msg, file);
          }
        }
      });
  }

  deleteMsg(msg, file?) {
    let deleteIndex;
    if (msg.file) file = msg.file;
    if (file) {
      this.displayedMessages.forEach((item, index) => {
        if (item.file && item.file.id == file.id) this.messageDeleteIndex = index;
      });
    } else if (confirm("Are you sure you want to remove this message? This cannot be undone.")) {
      this.displayedMessages.forEach((item, index) => {
        if (msg.key == item.key) this.messageDeleteIndex = index;
      });
      this.messagesRef.doc(msg.key).delete();
    }
  }

  editMsg(msg, file?) {
    if (msg.file) file = msg.file;
    if (file) file = this.sharedFilesMap[file.id];
    if (file) {
      let newFileDescription = prompt("Edit your message...", file.description || "");
      if (newFileDescription) {
        this.workspaceService.updateFile({ id: file.id, description: newFileDescription }).subscribe((result) => {
          // this.refreshFiles();
        });
      }
    } else {
      let noAtMsg = msg.message.split("<strong>").join("@");
      noAtMsg = noAtMsg.split("</strong>").join("");
      let newText = prompt("Edit your message...", noAtMsg);
      if (newText) {
        let updateItem = this.messages.filter((item) => item.key == msg.key)[0];
        this.messagesRef.doc(updateItem.key).update({ message: newText, edited: true });
      }
    }
  }

  downloadFile(event, file) {
    if (event) event.stopPropagation();
    if (file.grouping_id) {
      let files = this.groupedFilesMap[file.grouping_id];
      let timeout = 0;
      files.forEach((item) => {
        timeout += 500;
        item.delay = timeout;
      });
      files.forEach((file) => {
        this.windowService.setTimeout(() => {
          let link = document.createElement("a");
          link.href = file.url;
          link.download = file.url;
          link.click();
        }, file.delay);
      });
    } else {
      let link = document.createElement("a");
      link.href = file.url;
      link.download = file.url;
      link.click();
    }
  }

  selectView(view) {
    this.selectedView = view;
    this.activeFolder = undefined;
    this.router.navigate(["./metro/detail/" + this.connectionData.id, view], {
      queryParams: this._activatedRoute.snapshot.queryParams,
    });
    this.windowService.setTimeout(() => {
      this.measureUI();
    }, 100);
    this.scrollChatToBottom();
  }

  incrementUnreadCount() {
    this.conversationRef.ref.get().then((data) => {
      let convo = data.data();
      let update = {};
      if (convo.missedMessages) update = convo.missedMessages;
      if (update[this.connectionData.userId]) {
        update[this.connectionData.userId] = update[this.connectionData.userId] + 1;
      } else {
        update[this.connectionData.userId] = 1;
      }
      this.conversationRef.update({ missedMessages: update });
    });
  }

  addMessage(args) {
    if (this.newMsg) {
      let obj: any = {
        author: {
          id: this.user.id,
          photo: this.user.photoURL,
          name: this.user.firstName + " " + this.user.lastName,
        },
        message: this.newMsg,
        type: "message",
        timestamp: new Date(),
      };
      this.messagesRef.add(obj);
      this.msgInput.emptyInput();

      this.incrementUnreadCount();
      this.scrollChatToBottom();
    }
  }

  updateMyReadStatus() {
    if (this.conversationRef) {
      this.conversationRef.get().subscribe((result) => {
        let convo = result.data();
        let update = false;
        if (convo.missedMessages && convo.missedMessages[this.user.id] !== 0) {
          update = convo.missedMessages;
          update[this.user.id] = 0;
          this.conversationRef.update({ missedMessages: update });
        }
      });
    }
  }

  addFile(mainMix = false, coverPhoto = false, droppedFile = false) {
    alert("Not yet implemented.");
  }

  playAudio(file, event) {
    if (event) event.stopPropagation();
    this._eventbusService.emit(this._eventbusService.types.playAudio, file);
  }

  pauseAudio() {
    const audioPlayer: HTMLAudioElement = document.getElementById("audio_player") as HTMLAudioElement;
    this.playingAudioFile.isPlaying = false;
    audioPlayer.pause();
  }

  changeImage() {
    this.addFile(false, true);
  }

  scrollChatToBottom() {
    this.windowService.setTimeout(() => {
      if (this.chatWindow && this.chatWindow.nativeElement) {
        this.chatWindow.nativeElement.scrollTop = this.selectedView == "files" ? 0 : this.chatWindow.nativeElement.scrollHeight;
      }
    }, 25);
  }

  scrollToTop() {
    this.windowService.setTimeout(() => {
      if (this.chatWindow && this.chatWindow.nativeElement) {
        this.chatWindow.nativeElement.scrollTop = 0;
      }
    }, 25);
  }

  fileOverBase(e) {
    this.hasBaseDropZoneOver = e;
  }

  droppedFile(args) {
    this.addFile(false, false, args);
    this.uploader.clearQueue();
  }

  // public isTyping: boolean = false;
  // private _typingTimeout: any;
  // private _typingAt: boolean;
  // timelineMessageKeyup(args) {
  //   if (args.key == "Enter" || args.key == "Return" || args.keyCode == 13) {
  //     this.conversationRef.update({ typing: firebase.firestore.FieldValue.arrayRemove(this.userService.user.id) });
  //     this.addMessage();
  //   } else if (args.key == "ArrowUp" && !this.displayedMessages[this.displayedMessages.length - 1].file && this.displayedMessages[this.displayedMessages.length - 1].author.id == this.user.id) {
  //     this.editMsg(this.displayedMessages[this.displayedMessages.length - 1]);
  //   } else {
  //     // this.isTyping = true;
  //     // this.conversationRef.update({ typing: firebase.firestore.FieldValue.arrayUnion(this.userService.user.id) });
  //     // this.windowService.clearTimeout(this._typingTimeout);
  //     // this._typingTimeout = this.windowService.setTimeout(() => {
  //     //   this.conversationRef.update({ typing: firebase.firestore.FieldValue.arrayRemove(this.userService.user.id) });
  //     //   this.isTyping = false;
  //     // }, 1500);
  //   }
  // }

  // timelineMessageKeyDown(args) {
  //   if (args.keyCode == 9) this.timelineMessageKeyup(args);
  // }

  upgrade() {
    const modalRef = this.appService.showModal(SubscribeComponent, { size: "lg" });
  }

  fileItemClick(file) {
    if (!this.batchOperationMode) this.router.navigate(["workspaces/file/" + file.id]);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.updateMyReadStatus();
    this.clearWatchFileProgress();
  }
}
