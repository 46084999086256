import { BaseComponent, UserService, WindowService, WorkspaceService } from "@mypxplat/xplat/core";
import { StorageDetails } from "@mypxplat/xplat/core";
import { Directive, NgZone } from "@angular/core";

@Directive()
export abstract class SatelliteDetailBaseComponent extends BaseComponent {
  public uploadWatchMap: any = {};
  public workspace: any;
  public sharedFiles: Array<any>;
  public reversedSharedFiles: Array<any>;
  public sharedFilesMap: any = {};
  public messages: any;
  public messageDeleteIndex: number;
  public displayedMessages: any = [];
  public messagesSubscription: any;
  public newMsg: string;
  public myWorkspace: boolean;
  public mainMix: any;
  public collaboratorMap: any = {};
  public userStorageDetails: StorageDetails;
  public myRole: string;
  public groupedFilesMap = {};

  constructor(
    userService: UserService,
    public windowService: WindowService,
    public workspaceService: WorkspaceService,
    public zone: NgZone
  ) {
    super(userService);
  }

  updateMessages(result, reverse?) {
    this.messages = result;

    if (this.displayedMessages.length == 0 && this.messages.length > this.displayedMessages) {
      for (let i = 0; this.messages.length > i; i++) {
        this.displayedMessages.push(this.messages[i]);
      }
    } else if (this.displayedMessages.length > 0 && this.messages.length > this.displayedMessages.length) {
      for (let i = this.displayedMessages.length; this.messages.length > i; i++) {
        this.displayedMessages[reverse ? "unshift" : "push"](reverse ? this.messages.reverse()[i] : this.messages[i]);
      }
    } else if (this.messages.length > 0 && this.messages.length == this.displayedMessages.length) {
      this.displayedMessages.forEach((item, index, theArray) => {
        if (JSON.stringify(item) != JSON.stringify(this.messages[index])) {
          theArray[index] = this.messages[index];
        }
      });
    } else if (this.messages.length < this.displayedMessages.length) {
      // the user deleted a message
      this.displayedMessages.splice(this.messageDeleteIndex, 1);
      this.messageDeleteIndex = undefined;
    }
  }

  clearWatchFileProgress() {
    for (let i in this.uploadWatchMap) {
      this.windowService.clearInterval(this.uploadWatchMap[i].interval);
    }
    this.uploadWatchMap = {};
  }
}
