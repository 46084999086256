import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { catchError, map, take } from "rxjs/operators";
import { of as observableOf, BehaviorSubject, Subject } from "rxjs";
import { environment } from "../environments/environment";
import { StorageService, StorageKeys } from "./storage.service";
import { WindowService } from "./window.service";
import { LogService } from "./log.service";
import * as moment from "moment";
import { HelperService } from "./helper.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class EventsService {
  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private window: WindowService,
    private _log: LogService,
    private _helpers: HelperService,
    public userService: UserService
  ) {
    this._helpers.userID = this.userService.user ? this.userService.user.id : undefined;
  }

  public eventsMap: any = {};
  private _events: any;
  get events() {
    if (!this._events) this._events = this.storage.getItem(StorageKeys.EVENTS);
    return this._events || [];
  }

  set events(events) {
    this._events = events;
    this.storage.setTimestamp("events", 24);
    this.storage.setItem(StorageKeys.EVENTS, events);
  }

  public inProgressEvents: Array<any> = [];

  /** getEvents() Gets events. */
  public events$: Subject<any> = new Subject();
  getEvents(limit = 10, fresh?) {
    let events = this.events;

    if (events && !fresh && this.storage.checkTimestamp("events")) {
      this.processEvents(events);
      this.events$.next(this.events);
      return observableOf(events);
    } else {
      return this.http.get(environment.apiUrl + "events/list/" + (limit ? limit : ""), this._helpers.getHttpOptions()).pipe(
        map((data: any) => {
          if (data.events && data.events.length) {
            this.processEvents(data.events);
          }
          this.events$.next(this.events);
          return this.events;
        })
      );
    }
  }

  processEvents(events) {
    this.inProgressEvents = [];
    this.events = events;
    this.events.forEach((item) => {
      const regex = /data-livestream-id="([^"]+)"/gm;
      let m = regex.exec(item.description_events);
      if (m && m.length) item.livestream_id = m[m.length - 1];
      if (item.startdate_events && item.starttime_events && item.enddate_events && item.endtime_events) {
        if (moment(item.start_utc) < moment() && moment(item.end_utc) > moment()) {
          this.inProgressEvents.push(item);
          item.in_progress = true;
        }
        if (moment(item.end_utc) < moment()) {
          item.ended = true;
        }
      }
      this.eventsMap[item.id_events] = item;
    });
  }

  getEvent(id) {
    if (this.events && !this.eventsMap[id]) {
      this.events.forEach((item) => (this.eventsMap[item.id_events] = item));
    }
    if (this.eventsMap[id]) {
      return observableOf(this.eventsMap[id]);
    } else {
      return this.http.get(environment.apiUrl + "events/detail/" + id, this._helpers.getHttpOptions()).pipe(
        map((data: any) => {
          return data && data.event ? data.event : false;
        })
      );
    }
  }

  clearCache() {
    this.events$.next(false);
    this._events = false;
    this.storage.removeItem(StorageKeys.EVENTS);
  }

  addressToLocation(address) {
    return this.http.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + address + "&key=" + environment.googleMapsKey, this._helpers.getHttpOptions());
  }
}
