import { Location } from "@angular/common";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { BaseComponent, UserService, ProductService, AdminService, WindowService } from "@mypxplat/xplat/core";
import { AppService } from "@mypxplat/xplat/web/core";

import { forkJoin, interval, Subject } from "rxjs";
import { map, skip, takeUntil, throttle } from "rxjs/operators";

@Component({
  selector: "myp-admin-subscription-product-list",
  templateUrl: "subscription-product-list.component.html",
})
export class AdminSubscriptionProductListComponent extends BaseComponent implements OnInit {
  public loading: boolean;
  public subscriptionProducts: any = {};
  public sections: any = [
    {
      name: "Software",
      id: "software",
      selected: true,
    },
    {
      name: "Extensions",
      id: "extensions",
    },
  ];
  public selectedSection = this.sections[0].id;

  private _associatedProductsQuery: string;
  private _associatedProductsQueryStream$: Subject<any> = new Subject();
  set associatedProductsQuery(query) {
    this._associatedProductsQuery = query;
    if (this._associatedProductsQuery) {
      this._associatedProductsQueryStream$.next(query);
    } else {
      this.associatedProductResults = undefined;
    }
  }
  get associatedProductsQuery() {
    return this._associatedProductsQuery;
  }
  public associatedProductResults: any;

  constructor(
    userService: UserService,
    public productService: ProductService,
    public adminService: AdminService,
    public appService: AppService,
    private _activatedRoute: ActivatedRoute,
    public router: Router,
    public location: Location,
    private _win: WindowService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.adminService.get(`subscription/group/1`).subscribe((result: any) => {
      this.subscriptionProducts = result;
    });
    this._associatedProductsQueryStream$
      .pipe(
        skip(1),
        throttle(
          () => {
            return interval(1000);
          },
          { leading: true, trailing: true }
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((result) => {
        this.productAutocomplete();
      });
  }

  productAutocomplete() {
    if (this.associatedProductsQuery) {
      this.productService.searchv2("title", this.associatedProductsQuery, 10).subscribe((result: any) => {
        this.associatedProductResults = result;
        this.associatedProductResults.forEach((prod) => {
          prod.added = this.subscriptionProducts.software.filter((product) => product.id == prod.id).length > 0;
          prod.added = prod.added || this.subscriptionProducts.extensions.filter((product) => product.id == prod.id).length > 0;
        });
      });
    } else {
      this.associatedProductResults = [];
    }
  }

  setAssociatedProd(prod) {
    let key = prod.DTYPE == "SoftwarePackage" ? "software" : "extensions";
    if (this.subscriptionProducts[key].filter((product) => product.id == prod.id).length == 0) {
      this.subscriptionProducts[key].push({
        title: prod.title,
        imageUrl: prod.imageUrl,
        id: prod.id,
        stringId: prod.stringId,
        shortDescription: prod.shortDescription,
        description: prod.description,
      });
    }
    this.subscriptionProducts[key].sort((a, b) => a.title.localeCompare(b.title));
    prod.added = true;
    this.adminService
      .put(`subscription/group/1/products`, {
        type: this.selectedSection,
        productId: prod.id,
        action: "add",
      })
      .subscribe();
  }

  selectSection(id) {
    this.selectedSection = id;
    this.sections.forEach((section) => {
      section.selected = false;
      if (section.id == id) section.selected = true;
    });
  }

  removeProduct(prod) {
    this.subscriptionProducts[this.selectedSection] = this.subscriptionProducts[this.selectedSection].filter((product) => product.id != prod.id);
    this.adminService
      .put(`subscription/group/1/products`, {
        productId: prod.id,
        action: "remove",
      })
      .subscribe();
  }
}
