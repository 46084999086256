import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { OrdersBaseComponent } from "@mypxplat/xplat/features";
import { BaseComponent, StorageService, UserService, OrderService, ProductService } from "@mypxplat/xplat/core";
import { Router } from "@angular/router";
import { AppService } from "@mypxplat/xplat/web/core";

@Component({
  selector: "myp-shared-orders",
  templateUrl: "orders.component.html",
})
export class OrdersComponent extends OrdersBaseComponent implements OnInit, OnDestroy {
  public destroy$: Subject<any> = new Subject();
  public orders: Array<any>;
  public recurringPayments: Array<any>;
  public loading: boolean = true;
  public displayedSection: string = "orders";
  constructor(
    storageService: StorageService,
    userService: UserService,
    public orderService: OrderService,
    public productService: ProductService,
    private _router: Router,
    public appService: AppService
  ) {
    super(userService);
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.orderService.getOrders().subscribe((result: any[]) => {
      result.forEach((order) => {
        if (!order.description) console.log(order);
      });
      this.orders = result.filter((order) => order.type != "recurring");
      this.recurringPayments = result.filter((order) => order.type == "recurring");
      this.loading = false;
    });
  }

  switchSection(section, force?) {
    this.displayedSection = section;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  goShopping() {
    window.open("https://presonus.com");
  }

  goToProduct(id) {
    this._router.navigate(["/products/detail", id]);
  }
}
