<section role="contentinfo" aria-label="collaboration settings">
  <div class="row">
    <div class="col-12">
      <myp-collaboration-invite-notice></myp-collaboration-invite-notice>

      <h1 class="section-header">{{ "sphere.workspaces.collaboration_settings" | translate }}</h1>
      <div class="m-y-20" *ngIf="!loading && (!workspaces || !workspaces.length)">
        {{ "sphere.workspaces.collab_settings_msg" | translate }}
      </div>

      <div class="m-y-20" *ngIf="!loading && workspaces && workspaces.length">
        <myp-list-item
          *ngFor="let item of workspaces"
          [item]="item"
          [includeChevron]="false"
          [showImage]="false"
          [title]="item.name"
          [imageRound]="false"
          [collapsible]="true"
          [showBackground]="true"
          marginBottom="10px"
        >
          <div class="collapsible-content">
            <div class="d-flex align-items-start">
              <input type="radio" class="m-r-8 m-t-5" (change)="togglePreference(item)" [checked]="preferences[item.id].subscribed" [name]="item.id + '_select'" [id]="item.id + '_select'" />
              <label [for]="item.id + '_select'">{{ "sphere.workspaces.collab_msg_on" | translate }}</label>
            </div>
            <div class="d-flex align-items-start">
              <input type="radio" class="m-r-8 m-t-5" (change)="togglePreference(item)" [checked]="!preferences[item.id].subscribed" [name]="item.id + '_select'" [id]="item.id + '_select'" />
              <label [for]="item.id + '_select'">{{ "sphere.workspaces.collab_msg_off" | translate }}</label>
            </div>
          </div>
        </myp-list-item>
      </div>
    </div>
  </div>
</section>
