<div class="column-layout-container" basepage="primary">
  <div class="menu animate-in-from-left" [class.mobile-shown]="appService.mobileMenuShown">
    <aside role="region" aria-label="side navigation">
      <div class="menu-top d-flex justify-content-between align-items-center">
        <h1 class="menu-title">Orders</h1>
        <div>
          <!-- <button class="fa-button m-r-10" placement="bottom" [ngbTooltip]="(refreshing ? 'general.refreshing_dots' : 'general.refresh') | translate" (click)="refresh()">
            <fa-icon icon="sync" class="action refresh circle-icon-btn" [animation]="refreshing ? 'spin' : undefined"></fa-icon>
          </button> -->
        </div>
      </div>

      <ul class="menu-options">
        <li class="menu-option" [class.active]="displayedSection == 'orders'">
          <a
            class="primary-option"
            tabindex="0"
            href="javascript:void(0);"
            (keyup.enter)="appService.mobileMenuShown = false; switchSection('orders')"
            (keyup.space)="appService.mobileMenuShown = false; switchSection('orders')"
            (click)="appService.mobileMenuShown = false; switchSection('orders')"
          >
            My Orders
          </a>
        </li>
        <li class="menu-option" [class.active]="displayedSection == 'recurring'">
          <a
            class="primary-option"
            tabindex="0"
            href="javascript:void(0);"
            (keyup.enter)="appService.mobileMenuShown = false; switchSection('recurring')"
            (keyup.space)="appService.mobileMenuShown = false; switchSection('recurring')"
            (click)="appService.mobileMenuShown = false; switchSection('recurring')"
          >
            Recurring Payments
          </a>
        </li>
      </ul>
    </aside>
  </div>
  <section role="contentinfo" class="content animate-in-from-right" [class.mobile-shown]="!appService.mobileMenuShown">
    <button class="mobile-back-btn no-border" (click)="appService.mobileMenuShown = true">
      <fa-icon icon="chevron-left" aria-hidden="true"></fa-icon>
      Orders Options
    </button>
    <div class="container" *ngIf="displayedSection == 'orders'">
      <h4 class="content-title">My Orders</h4>
      <ng-container *ngIf="!loading && orders?.length">
        <myp-user-order-list [orders]="orders"></myp-user-order-list>
      </ng-container>
      <myp-inline-loader *ngIf="loading" [loading]="true" height="200px" spinnerName="loader"></myp-inline-loader>
    </div>
    <div class="container" *ngIf="displayedSection == 'recurring'">
      <h4 class="content-title">Recurring Payments</h4>
      <ng-container *ngIf="!loading && recurringPayments?.length">
        <myp-user-order-list [orders]="recurringPayments"></myp-user-order-list>
      </ng-container>
      <myp-inline-loader *ngIf="loading" [loading]="true" height="200px" spinnerName="loader"></myp-inline-loader>
    </div>
  </section>
</div>
