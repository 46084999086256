import { Injectable, EventEmitter } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { environment } from "../environments/environment";
import { StorageService, StorageKeys } from "./storage.service";
import { Base64 } from "js-base64";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import * as htmlToText from "html-to-text";
import { Subject } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class HelperService {
  private _token: any;
  private _refreshToken: any;
  private _accessToken: any;
  private _idToken: any;
  private _expiry: any;

  public checkoutTestData: any = {};

  constructor(
    private _storageService: StorageService,
    public storage: StorageService,
    private _translateService: TranslateService
  ) {}

  public hasNotifiedExpiredAccessToken: boolean = false;

  public userID: any;

  public mobileTheme: "dark" | "light" = "light";

  get refreshToken() {
    if (!this._refreshToken) {
      let storedRefreshToken = this.storage.getItem(StorageKeys.REFRESHTOKEN);
      if (storedRefreshToken) {
        this._refreshToken = storedRefreshToken;
      } else if (this.cookieTokens) {
        this._refreshToken = this.cookieTokens.refreshToken;
      }
    }
    return this._refreshToken;
  }

  set refreshToken(refreshToken) {
    if (!refreshToken) {
      this.storage.removeItem(StorageKeys.REFRESHTOKEN);
    } else {
      this.storage.setItem(StorageKeys.REFRESHTOKEN, refreshToken);
    }
    this._refreshToken = refreshToken;
  }

  get accessToken() {
    if (!this._accessToken) {
      let storedaccessToken = this.storage.getItem(StorageKeys.ACCESSTOKEN);
      if (storedaccessToken) {
        this._accessToken = storedaccessToken;
      } else if (this.cookieTokens) {
        this._accessToken = this.cookieTokens.accessToken;
      }
    }
    return this._accessToken;
  }

  set accessToken(accessToken) {
    if (!accessToken) {
      this.storage.removeItem(StorageKeys.ACCESSTOKEN);
    } else {
      this.storage.setItem(StorageKeys.ACCESSTOKEN, accessToken);
    }
    this._accessToken = accessToken;
  }

  get idToken() {
    if (!this._idToken) {
      let storedidToken = this.storage.getItem(StorageKeys.IDTOKEN);
      if (storedidToken) {
        this._idToken = storedidToken;
      } else if (this.cookieTokens) {
        this._idToken = this.cookieTokens.idToken;
      }
    }
    return this._idToken;
  }

  set idToken(idToken) {
    if (!idToken) {
      this.storage.removeItem(StorageKeys.IDTOKEN);
    } else {
      this.storage.setItem(StorageKeys.IDTOKEN, idToken);
    }
    this._idToken = idToken;
  }

  get tokenExpiry() {
    if (!this._expiry) {
      let storedExpiry = this.storage.getItem(StorageKeys.TOKENEXPIRY);
      if (storedExpiry) {
        this._expiry = storedExpiry;
      } else if (this.cookieTokens) {
        this._expiry = this.cookieTokens.tokenExpiry;
      }
    }
    return this._expiry;
  }

  set tokenExpiry(expiry) {
    if (!expiry) {
      this.storage.removeItem(StorageKeys.TOKENEXPIRY);
    } else {
      this.storage.setItem(StorageKeys.TOKENEXPIRY, expiry);
    }
    this._expiry = expiry;
  }

  get token() {
    if (!this._token) {
      let storedToken = this.storage.getItem(StorageKeys.IDTOKEN);
      if (storedToken) {
        this._token = storedToken;
      } else if (this.idToken) {
        this._token = this.idToken;
      }
    }
    return this._token;
  }

  set token(token) {
    if (!token) {
      this.storage.removeItem(StorageKeys.TOKEN);
    } else {
      this.storage.setItem(StorageKeys.TOKEN, token);
    }
    this._token = token;
  }

  private _cookieTokens: {
    accessToken: string;
    idToken: string;
    refreshToken: string;
    tokenExpiry: number;
  };
  get cookieTokens() {
    return this._cookieTokens;
  }

  set cookieTokens(tokens) {
    this._cookieTokens = tokens;
  }

  private _cookieToken: any;
  get cookieToken() {
    return this._cookieToken;
  }

  set cookieToken(token) {
    this._cookieToken = token;
  }

  public getHttpOptions(includeToken = true, userID?, customToken?, includeActiveSubscription?) {
    let args = {
      "Content-Type": "application/json",
      "X-API-KEY": environment.presonusApiKey,
    };
    if (includeActiveSubscription) args["X-ACTIVE-SUBSCRIPTION"] = "true";
    if (includeToken) {
      if (customToken) {
        args["X-USER-TOKEN"] = customToken;
      } else {
        if (!this.token) {
          this.token = this._storageService.getItem(StorageKeys.TOKEN);
        }
        if (!this.token) {
          if (this.cookieToken) {
            this.token = this.cookieToken;
          }
        }
        if (this.token) {
          // console.log("%cAPI CALL WITH: " + this.token.substr(this.token.length - 5));
          args["X-USER-TOKEN"] = this.token;
        }
      }
    }
    if (userID) {
      let userEncrypedID = Base64.encode(userID);
      args["X-USER-ENCRYPTED-ID"] = userEncrypedID;
    }
    if (this.userID) args["X-NEO-MORPHEUS"] = Base64.encode(this.userID);
    return { headers: new HttpHeaders(args) };
  }

  public getPaeApiOptions(accessToken?) {
    let args = {
      "Content-Type": "application/json",
    };
    if (accessToken) args["X-USER-TOKEN"] = accessToken;
    return { headers: new HttpHeaders(args) };
  }

  public validateEmail(email) {
    return /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/.test(email);
  }

  public shuffleArray<T>(array: T[]): T[] {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  public stripHtml(string, replaceLineBreaks?, trimWhiteSpace?) {
    let result = "";
    if (string) {
      result = string;
      if (replaceLineBreaks) {
        // Replace <br> and <br/> tags with a newline character
        result = result.replace(/<br\s*\/?>/gi, "\n");
        // Replace <p> and </p> tags with a newline character
        result = result.replace(/<\/?p>/gi, "\n");
      }

      // Remove the rest of the HTML tags
      result = result.replace(/<\/?[^>]+(>|$)/g, "");
      result = result.replace(/&nbsp;/gi, " ");
      if (trimWhiteSpace) result = result.trim();
    }
    return result;
  }

  htmlToMarkdown(html) {
    // Replace line breaks
    let markdown = html.replace(/<br\s*\/?>/gi, "\n");

    // Remove spans with class="ql-ui"
    markdown = markdown.replace(/<span[^>]*class="[^"]*\bql-ui\b[^"]*"[^>]*>.*?<\/span>/gis, "");

    // Remove <u> tags (underline is not standard in Markdown)
    markdown = markdown.replace(/<u>(.*?)<\/u>/gi, "$1");

    // Replace <strong> and <b> tags with Markdown bold
    markdown = markdown.replace(/<strong>(.*?)<\/strong>/gi, "**$1**");
    markdown = markdown.replace(/<b>(.*?)<\/b>/gi, "**$1**");

    // Replace <em> and <i> tags with Markdown italics
    markdown = markdown.replace(/<em>(.*?)<\/em>/gi, "*$1*");
    markdown = markdown.replace(/<i>(.*?)<\/i>/gi, "*$1*");

    // Replace <s> and <strike> tags with Markdown strikethrough
    markdown = markdown.replace(/<s>(.*?)<\/s>/gi, "~~$1~~");
    markdown = markdown.replace(/<strike>(.*?)<\/strike>/gi, "~~$1~~");

    // Replace <h1> to <h6> with corresponding Markdown headers
    for (let i = 6; i >= 1; i--) {
      const hTag = new RegExp(`<h${i}[^>]*>(.*?)<\/h${i}>`, "gi");
      markdown = markdown.replace(hTag, `${"#".repeat(i)} $1\n`);
    }

    // Replace <a> tags with Markdown links
    markdown = markdown.replace(/<a href="(.*?)">(.*?)<\/a>/gi, "[$2]($1)");

    // Handle code blocks
    markdown = markdown.replace(/<div[^>]*class="[^"]*\bql-code-block\b[^"]*"[^>]*>(.*?)<\/div>/gis, function (match, codeContent) {
      return "\n```\n" + codeContent.trim() + "\n```\n";
    });

    // Remove any remaining <div> tags (including ql-code-block-container)
    markdown = markdown.replace(/<\/?div[^>]*>/gi, "");

    // Replace <li> elements with data-list attributes
    markdown = markdown.replace(/<li[^>]*data-list="(.*?)"[^>]*>(.*?)<\/li>/gis, function (match, listType, content) {
      content = content.trim();
      // Remove any remaining HTML tags in content
      content = content.replace(/<\/?[^>]+(>|$)/g, "");
      if (listType === "ordered") {
        return "1. " + content + "\n";
      } else if (listType === "bullet") {
        return "- " + content + "\n";
      } else {
        return "* " + content + "\n";
      }
    });

    // Remove any remaining <ol> and <ul> tags
    markdown = markdown.replace(/<\/?(ol|ul)[^>]*>/gi, "");

    // Replace <blockquote> with Markdown blockquotes
    markdown = markdown.replace(/<blockquote[^>]*>(.*?)<\/blockquote>/gis, function (match, content) {
      return "> " + content.trim() + "\n";
    });

    // Replace <p> tags with newlines
    markdown = markdown.replace(/<\/?p[^>]*>/gi, "\n");

    // Remove any remaining HTML tags
    markdown = markdown.replace(/<\/?[^>]+(>|$)/g, "");

    // Collapse multiple consecutive newlines into a single newline
    markdown = markdown.replace(/\n{2,}/g, "\n\n");

    markdown = markdown.replace(/&nbsp;/g, " ");

    // Trim extra whitespace
    return markdown.trim();
  }

  makeHtml(text) {
    text = this.urlify(text);
    text = text.replace(/(?:\n\n\n)/g, "<br /><br />");
    text = text.replace(/(?:\r\r\r)/g, "<br /><br />");
    text = text.replace(/(?:\n\n)/g, "<br />");
    text = text.replace(/(?:\r\r)/g, "<br />");
    text = text.replace(/(?:\r\n|\r|\n)/g, "<br />");
    return text;
  }

  urlify(text) {
    if (text) {
      var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      return text.replace(urlRegex, function (url) {
        return '<a href="' + url + '" target="_blank">' + url + "</a>";
      });
    } else {
      return text;
    }
  }

  public hhmmss(secs, pretty = false) {
    if (secs) {
      if (typeof secs === "string") secs = parseInt(secs);
      secs = secs.toFixed(0);
      var minutes: any = Math.floor(secs / 60);
      secs = secs % 60;
      var hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      if (pretty) {
        if (hours) {
          return hours + "h " + minutes + "m";
        } else {
          return minutes ? minutes + "m" : "<1m";
        }
      } else {
        if (hours) {
          if (minutes < 10) minutes = "0" + minutes;
          return `${hours}:${minutes}:${("0" + secs).slice(-2)}`;
        } else {
          return `${minutes}:${("0" + secs).slice(-2)}`;
        }
      }
    } else {
      return "0s";
    }
  }

  public acceptableMentionKeys = {
    a: true,
    b: true,
    c: true,
    d: true,
    e: true,
    f: true,
    g: true,
    h: true,
    i: true,
    j: true,
    k: true,
    l: true,
    m: true,
    n: true,
    o: true,
    p: true,
    q: true,
    r: true,
    s: true,
    t: true,
    u: true,
    v: true,
    w: true,
    x: true,
    y: true,
    z: true,
    Space: true,
    " ": true,
  };

  public htmlToText(html, useConverter = true, ignoreImages = true) {
    // const { convert } = require("html-to-text");
    // There is also an alias to `convert` called `htmlToText`.
    // https://github.com/html-to-text/node-html-to-text/tree/master/packages/html-to-text
    if (useConverter) {
      const options = {
        wordwrap: 130,
        selectors: [],
      };
      if (ignoreImages) options.selectors.push({ selector: "img", format: "skip" });

      return htmlToText.convert(html, options);
    } else {
      html = html.replace(/<[^>]+>/g, "");
      html = html.replace(/&nbsp;/g, "");
      return html;
    }
  }

  retrieveErrorMessage(error) {
    if (error && error.error && (error.error.error || error.error.message_plain || error.error.message || error.error.message)) {
      return error.error.error || error.error.message_plain || error.error.message || error.error.reason;
    } else if (error && error.message) {
      return error.message;
    } else if (error.error) {
      return error.error;
    } else {
      return "An unknown error occurred.";
    }
  }

  public exchangeDeployed: boolean = false;

  public languages = [
    {
      code: "en",
      name: "English",
      flag_icon: "./assets/images/flags/us.png",
    },
    {
      code: "es",
      name: "Español",
      translated_name: "Spanish",
      flag_icon: "./assets/images/flags/es.png",
    },
    {
      code: "de",
      name: "Deutsch",
      translated_name: "German",
      flag_icon: "./assets/images/flags/de.png",
    },
    {
      code: "jp",
      name: "日本語",
      translated_name: "Japanese",
      flag_icon: "./assets/images/flags/jp.png",
    },
    {
      code: "cn",
      name: "中文",
      translated_name: "Chinese",
      flag_icon: "./assets/images/flags/cn.png",
    },
    {
      code: "fr",
      name: "Français",
      translated_name: "French",
      flag_icon: "./assets/images/flags/fr.png",
    },
    {
      code: "tr",
      name: "Türk",
      translated_name: "Turkish",
      flag_icon: "./assets/images/flags/tr.png",
    },
    {
      code: "it",
      name: "Italiano",
      translated_name: "Italian",
      flag_icon: "./assets/images/flags/it.png",
    },
    {
      code: "pl",
      name: "Polski",
      translated_name: "Polish",
      flag_icon: "./assets/images/flags/pl.png",
    },
  ];

  private _selectedLanguage: any;
  get selectedLanguage() {
    let storedLang = this._storageService.getItem(StorageKeys.LANGUAGE);
    const myLang = this._selectedLanguage ? this._selectedLanguage : this.languages.filter((item) => item.code == (storedLang || "en"))[0];
    return myLang;
  }

  set selectedLanguage(lang) {
    let myLang = lang;
    if (typeof lang === "string") myLang = this.languages.filter((item) => item.code == lang)[0];
    this._translateService.setDefaultLang(myLang.code);
    this._translateService.use(myLang.code);
    moment.locale(myLang.code);
    this._storageService.setItem(StorageKeys.LANGUAGE, myLang.code);
    this._selectedLanguage = myLang;
  }

  public backPressed$: Subject<boolean> = new Subject();
  public navigatingBack: boolean;
  public goBack() {
    this.navigatingBack = true;
    this.backPressed$.next(true);
  }
}
