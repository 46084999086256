import { Component, Input, OnInit } from "@angular/core";

import { BaseComponent, UserService } from "@mypxplat/xplat/core";
import { AppService } from "@mypxplat/xplat/web/core";
import { OrderHistoryDetail, OrderIPN } from "libs/xplat/core/src/lib/models/admin.model";
import * as moment from "moment";

@Component({
  selector: "myp-user-order-list",
  templateUrl: "user-order-list.component.html",
})
export class UserOrderListComponent extends BaseComponent implements OnInit {
  @Input() orders: any[];
  public filteredOrders: any[];
  constructor(
    userService: UserService,
    public appService: AppService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.filterOrders();
  }

  public filtering: boolean;
  filterOrders(args?) {
    this.filtering = !!args;
    if (args) {
      this.filteredOrders = this.orders.filter((order) => {
        if (order.reference_number && order.reference_number.toString().toLowerCase().indexOf(args.toLowerCase()) > -1) return true;
        if (order.idorder_history && order.idorder_history.toString().toLowerCase().indexOf(args.toLowerCase()) > -1) return true;
        if (order.details?.length) {
          for (const detail of order.details) {
            if (detail.title && detail.title.toLowerCase().indexOf(args.toLowerCase()) > -1) return true;
            if (detail.product_key && detail.product_key.toLowerCase().indexOf(args.toLowerCase()) > -1) return true;
          }
        }
      });
    } else {
      this.filteredOrders = this.orders;
    }
  }

  sortOrders() {
    const sorter = (a, b) => {
      if (this.appService.sortBy == "date") {
        let dateA = a.order_date;
        let dateB = b.order_date;
        if (!dateA && !dateB) {
          return 0; // Both are missing, treat as equal
        } else if (!dateA) {
          return this.appService.sortDirection === "asc" ? -1 : 1; // Missing dateA goes to top if 'asc', to bottom if 'desc'
        } else if (!dateB) {
          return this.appService.sortDirection === "asc" ? 1 : -1; // Missing dateB goes to top if 'asc', to bottom if 'desc'
        }
        if (moment(dateA) < moment(dateB)) {
          return this.appService.sortDirection == "asc" ? -1 : 1;
        } else if (moment(dateA) > moment(dateB)) {
          return this.appService.sortDirection == "asc" ? 1 : -1;
        }
      } else {
        if (a.reference_number.toString().toLowerCase() < b.reference_number.toString().toLowerCase()) {
          return this.appService.sortDirection == "asc" ? -1 : 1;
        } else if (a.reference_number.toLowerCase() > b.reference_number.toLowerCase()) {
          return this.appService.sortDirection == "asc" ? 1 : -1;
        }
      }
    };

    this.filteredOrders.sort(sorter);
  }

  loadInvoiceUrl(order_ipn: OrderIPN | undefined) {
    if (!order_ipn) {
      return;
    }
    if (order_ipn.data) {
      const { object } = order_ipn.data;
      const { hosted_invoice_url } = object;
      return hosted_invoice_url;
    } else {
      return order_ipn.invoiceURL;
    }
  }

  processProductMetadata(detail: OrderHistoryDetail) {
    const metadata = [];
    if (detail) {
      metadata.push({ title: "Key", value: detail.product_key });
      metadata.push({ title: "Status", value: detail.registration_status });
    }

    return metadata;
  }

  parseTotalPaid(order_ipn: OrderIPN | undefined) {
    if (!order_ipn) {
      return;
    }
    if (order_ipn.data) {
      const { object } = order_ipn.data;
      const { amount_paid } = object;
      return Number(amount_paid) / 100;
    } else {
      return order_ipn.invoiceChargeAmount || 0;
    }
  }

  parseCurreny(order_ipn: OrderIPN | undefined) {
    if (!order_ipn) {
      return;
    }
    if (order_ipn.data) {
      const { object } = order_ipn.data;
      const { currency } = object;
      return currency.toUpperCase();
    } else {
      return order_ipn.currency?.toUpperCase() || "USD";
    }
  }
}
