import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/compat/firestore";
import { Router } from "@angular/router";
import { EventBusService, HelperService, UserService, WindowService, environment } from "@mypxplat/xplat/core";

import { ChannelBaseComponent } from "@mypxplat/xplat/features";
import { AppService, BetaService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { CreatePostInputComponent, SphereSubscribeStripeComponent } from "@mypxplat/xplat/web/features";
import { ClipboardService } from "ngx-clipboard";
import { map, takeUntil } from "rxjs/operators";

@Component({
  selector: "myp-channel",
  templateUrl: "channel.component.html",
})
export class ChannelComponent extends ChannelBaseComponent {
  private _selectedChannel: any;
  @Input() set selectedChannel(channel) {
    this._selectedChannel = channel;
    if (channel) {
      this._win.setTimeout(() => {
        this.initPosts();
      }, 10);
    }
  }

  public displayType: any = this.communityService.betaFilterSettings.viewMode || "detailed";
  @Input() set selectedBetaView(view) {
    this.displayType = view;
  }

  get selectedChannel() {
    return this._selectedChannel;
  }

  private _selectedTopic: any;
  @Input() set selectedTopic(topic) {
    this._selectedTopic = topic;
    if (topic) {
      this._win.setTimeout(() => {
        this.initPosts();
      }, 10);
    }
  }

  get selectedTopic() {
    return this._selectedTopic;
  }
  @Input() betaStringId: string;
  public betaCollection: string;
  @Input() postType = "channels";
  public queuedPosts: Array<any>;
  @ViewChild("pagingContainer", { static: false }) pagingContainer: ElementRef;
  @ViewChild("createPostInput") createPostInput: CreatePostInputComponent;
  @Output() channelSelected: EventEmitter<any> = new EventEmitter();
  @Output() topicSelected: EventEmitter<any> = new EventEmitter();
  @Output() postsLoaded: EventEmitter<any> = new EventEmitter();
  @Output() resetFilters: EventEmitter<any> = new EventEmitter();
  public loading: boolean = true;
  public shouldShowPagination: boolean;
  public isBetaManager: boolean;
  constructor(
    userService: UserService,
    public communityService: WebCommunityService,
    public db: AngularFirestore,
    public appService: AppService,
    private _win: WindowService,
    public eventBusService: EventBusService,
    public router: Router,
    public betaService: BetaService,
    public helperService: HelperService,
    private _clipboardService: ClipboardService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.isBetaManager = this.user.groups.some((group: any) => group.name === "beta_manager");
    if (this.betaStringId) this.betaCollection = "beta_community_posts";
    this.communityService.getAvailableSkills();
  }

  getChannelString(channels) {
    let string = "<div class='d-flex flex-wrap'>";
    channels.forEach((channel) => {
      if (this.betaService.communityTagMap[channel]) {
        string += '<span class="item-tag t-12 d-inline-block"># ' + this.betaService.communityTagMap[channel].title + "</span>";
      }
    });
    string += "</div>";
    return string;
  }

  getBetaItemStats(post) {
    let type = post.beta_type ? post.beta_type.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase()) : "None";
    let status = post.beta_status ? post.beta_status.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase()) : "None";
    let string = "Type: " + type + " &bull; Status: " + status;
    if (post.beta_review_details?.jira_ticket_number && post.beta_review_details?.jira_ticket_link && this.isBetaManager) {
      string += ` &bull; Jira Ticket: <a href="${post.beta_review_details.jira_ticket_link}" target="_blank">${post.beta_review_details.jira_ticket_number}</a>`;
    } else if (post.beta_review_details?.jira_ticket_number) {
      string += ` &bull; Jira Ticket: ${post.beta_review_details.jira_ticket_number}`;
    }
    return string;
  }

  showMore() {
    this.communityService
      .getPostsWithReplies({ channel: this.selectedChannel, collection: this.betaStringId ? "beta_community_posts" : "community_posts", betaStringId: this.betaStringId })
      .then((result: any) => {});
  }

  queuedPostsUpdate(args) {
    this.queuedPosts = args;
  }

  copyLink(msg, event) {
    event.stopPropagation();
    if (this.betaStringId) {
      this._clipboardService.copy(this.env.mypresonusUrl + "beta/betapost/" + msg.id + "?betaStringId=" + this.betaStringId);
    } else {
      this._clipboardService.copy(this.env.mypresonusUrl + "community/post/" + msg.id);
    }
  }

  initPosts() {
    this.loading = true;
    this.communityService
      .getPostsWithReplies({
        channel: this.postType == "channels" ? this.selectedChannel : false,
        topic: this.postType == "topics" ? this.selectedTopic : false,
        limit: this.betaStringId ? 100 : 30,
        clear: true,
        collection: this.betaCollection,
        betaStringId: this.betaStringId,
      })
      .then((result: any) => {
        this.loading = false;
        this.postsLoaded.next(true);
        let startWatchFrom = this.betaService.betaDetailByStringId[this.betaStringId]?.start_date ? new Date(this.betaService.betaDetailByStringId[this.betaStringId]?.start_date) : null;
        this.communityService.watchPostsWithReplies(this.selectedChannel, this.selectedTopic, null, null, this.betaCollection, this.betaStringId, startWatchFrom);
      });

    if (
      this.selectedChannel &&
      this.communityService.channelSplashes[this.selectedChannel.string_id] &&
      this.communityService.channelSplashes[this.selectedChannel.string_id].indexOf('id="youtubelink"') > -1
    ) {
      this._win.setTimeout(() => {
        let link = document.getElementById("youtubelink");
        if (link) {
          let youtubeID = link.getAttribute("data-youtube-id");
          link.addEventListener("click", () => {
            this.showVideo(youtubeID);
          });
        }
      }, 100);
    }
    if (this.selectedTopic && this.selectedTopic.description && this.selectedTopic.description.indexOf('id="youtubelink"') > -1) {
      this._win.setTimeout(() => {
        let link = document.getElementById("youtubelink");
        if (link) {
          let youtubeID = link.getAttribute("data-youtube-id");
          link.addEventListener("click", () => {
            this.showVideo(youtubeID);
          });
        }
      }, 100);
    }
  }

  goToPost(post) {
    this.router.navigate([`/beta/betapost/${post.id}`], { queryParams: { betaStringId: this.betaStringId } });
  }

  showVideo(id, type = "youtube") {
    this.eventBusService.emit(this.eventBusService.types.playVideo, {
      id: id,
      type: type,
    });
  }

  upgrade() {
    this.router.navigate(["/onboarding"], { queryParams: { subscribeOnly: true } });
  }

  ngOnDestroy() {
    this.communityService.unwatch();
  }
}
