import { Component, Input, OnInit } from "@angular/core";
import { WorkspacesListBaseComponent } from "@mypxplat/xplat/features";
import { EventBusService, UserService, WindowService, WorkspaceService, environment } from "@mypxplat/xplat/core";
import { AppService, AvoService, FirebaseService } from "@mypxplat/xplat/web/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AddWorkspaceComponent, SphereSubscribeStripeComponent } from "../modals";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { filter, takeUntil, take } from "rxjs/operators";
import { AngularFireAuth } from "@angular/fire/compat/auth";

@Component({
  selector: "myp-shared-workspaces-list",
  templateUrl: "workspaces-list.component.html",
})
export class WorkspacesListComponent extends WorkspacesListBaseComponent implements OnInit {
  @Input() isCloud: boolean = true;
  public selectedID: any;
  public invites: Array<any>;
  public loadingInvites: boolean;

  constructor(
    userService: UserService,
    public workspaceService: WorkspaceService,
    public appService: AppService,
    public router: Router,
    public fbAuth: AngularFireAuth,
    public db: AngularFirestore,
    private _activatedRoute: ActivatedRoute,
    private _win: WindowService,
    private _eventbusService: EventBusService,
    public avoService: AvoService,
    public fbService: FirebaseService
  ) {
    super(userService, workspaceService);
  }

  ngOnInit() {
    this.loadingInvites = true;
    super.ngOnInit();
    const go = () => {
      this.workspaceService.workspaces$
        .pipe(
          filter((items) => !!items),
          takeUntil(this.destroy$)
        )
        .subscribe((results: any) => {
          this.setupMissedMessages(results);
        });
      this.workspaceService.collabs$
        .pipe(
          filter((items) => !!items),
          takeUntil(this.destroy$)
        )
        .subscribe((result) => {
          this.setupMissedMessages(result);
        });
    };
    go();
    if (this._activatedRoute.snapshot.params["id"]) {
      this.getInvites();
      if ((this.workspaces && this.workspaces.length) || (this.collabs && this.collabs.length)) {
        let defaultWorkspace = this.workspaces.filter((w) => w["id"] == this._activatedRoute.snapshot.params["id"])[0];
        if (!defaultWorkspace) this.collabs.filter((w) => w["id"] == this._activatedRoute.snapshot.params["id"])[0];
        let workspaceName = undefined;
        if (defaultWorkspace !== undefined) {
          workspaceName = defaultWorkspace["name"];
        }
        this.selectWorkspace(this._activatedRoute.snapshot.params["id"], workspaceName, false);
      }
    } else {
      this.appService.mobileMenuShown = true;
    }
  }

  selectWorkspace(id, name, route = true) {
    this.selectedID = undefined;
    if (route) {
      this._win.setTimeout(() => {
        this.selectedID = id;
        this.router.navigate(["workspaces/detail/", id], {
          replaceUrl: true,
        });
      }, 10);
    } else {
      this.selectedID = id;
    }
    if (name !== undefined && this.avoService.trackEvent().workspaceDetail) {
      this.avoService.trackEvent().workspaceDetail({
        siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
        workspaceId: parseInt(id),
        name: name,
        title: name,
        customDestinationPageName_: "Workspace Detail",
      });
    }
  }

  showInvitations() {
    this.selectedID = undefined;
    this._win.setTimeout(() => {
      this.router.navigate(["workspaces"], {
        replaceUrl: true,
      });
    }, 10);
  }

  setupMissedMessages(results) {
    results.forEach((workspace) => {
      if (workspace.firebase_timeline_id) {
        this.timelineRefs[workspace.firebase_timeline_id] = this.db.collection("timelines").doc(workspace.firebase_timeline_id);
        this.fbService
          .handleFirestoreObservable(() => this.timelineRefs[workspace.firebase_timeline_id].collection("missedMessages").doc(this.user.id).get())
          .subscribe({
            next: (result: any) => {
              this.unReadCounts[workspace.id] = result?.data && result.data() ? result.data().missedCount : 0;
            },
            error: (error) => {},
          });
      }
    });
  }

  go(id) {
    this.router.navigate(["workspaces/detail/", id]);
  }

  workspacesVideo() {
    this._eventbusService.emit(this._eventbusService.types.playVideo, {
      id: "WzFkWEzpiNs",
      title: "How to use Studio One Pro+ Workspaces with Jacob Lamb",
      type: "youtube",
    });
  }

  getInvites() {
    if (this.workspaceService.cachedCollaborationInvites) this.invites = this.workspaceService.cachedCollaborationInvites;
    this.workspaceService.getCollaborationInvites(true).subscribe((invites) => {
      this.gotInvites(invites);
    });
  }

  gotInvites(invites) {
    this.loadingInvites = false;
    this.invites = invites;
  }

  add() {
    if (!this.user.active_subscription) {
      this.appService.goToCheckout("/checkout");
    } else {
      this.appService.showModal(AddWorkspaceComponent, { size: "xl" });
    }
  }
}
