<div class="m-y-20">
  <myp-product-filter #filterCmp titleWord="Ref #" (filterChanged)="filterOrders($event)" (sortChanged)="sortOrders()" [showSortType]="true" [showListGrid]="false"></myp-product-filter>
</div>
<myp-list-item
  *ngFor="let order of this.filteredOrders"
  [item]="order"
  [includeChevron]="false"
  [showImage]="false"
  [title]="'Order #: ' + order.reference_number"
  [imageRound]="false"
  [metadata]="[
    { title: 'Date', value: order.order_date | date },
    { title: 'Total', value: parseTotalPaid(order.order_ipn) | currency: parseCurreny(order.order_ipn) },
  ]"
  [collapsible]="true"
  [showBackground]="true"
  marginBottom="10px"
>
  <div class="collapsible-content">
    <div class="row">
      <div class="col-md-12 col-lg-8">
        <h2 class="section-header">Details</h2>
        <div class="row">
          <div class="col-12 m-b-5">
            <div class="text-muted t-12">Order Date</div>
            <div>{{ order.order_date | date }}</div>
          </div>
          <div class="col-12 m-b-5">
            <div class="text-muted t-12">Total Paid</div>
            <div>{{ parseTotalPaid(order.order_ipn) | currency: parseCurreny(order.order_ipn) }}</div>
          </div>
          <div class="col-12 m-b-5">
            <div class="text-muted t-12">Email</div>
            <div>{{ order.order_ipn?.email || order.order_ipn?.data?.object?.customer_email || "None found." }}</div>
          </div>
        </div>

        <ng-container *ngIf="order.details?.length">
          <h2 class="section-header">Products</h2>
          <myp-list-item
            *ngFor="let detail of order.details"
            [item]="detail"
            [includeChevron]="false"
            [showImage]="false"
            [title]="detail.title"
            [embeddedInCollapsible]="true"
            [imageRound]="false"
            [metadata]="processProductMetadata(detail)"
            [collapsible]="false"
            [showBackground]="true"
            marginBottom="10px"
          ></myp-list-item>
        </ng-container>

        <ng-container *ngIf="order.refunds?.length">
          <h2 class="section-header">Refunds</h2>
          <div class="m-y-20" *ngFor="let refund of order.refunds">
            {{ refund.refund_amount | currency: parseCurreny(order.order_ipn) }}
            <hr />
          </div>
        </ng-container>
      </div>

      <div class="col-12 col-lg-4">
        <div class="m-b-20">
          <h2 class="section-header">Actions</h2>
          <div class="m-b-5">
            <a [href]="loadInvoiceUrl(order.order_ipn)" class="small-btn" target="_blank">
              <fa-icon icon="arrow-alt-circle-up" class="m-r-5" style="color: green !important"></fa-icon>
              View Invoice
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</myp-list-item>
