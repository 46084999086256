import { Input, OnInit, Directive } from "@angular/core";
import { BaseComponent, ContentService, File, UserService, WorkspaceService } from "@mypxplat/xplat/core";

@Directive()
export abstract class WorkspaceFilesBaseComponent extends BaseComponent implements OnInit {
  public folders: any[];
  public selectedFolder: any;
  public displayedFolders: any[];
  public folderBreadCrumbSelection: any[] = ["Home"];
  @Input() batchOperationMode: any;
  @Input() workspace: any;
  constructor(
    userService: UserService,
    public workspaceService: WorkspaceService,
    public contentService: ContentService
  ) {
    super(userService);
  }

  ngOnInit() {}

  public loadingFiles: boolean = true;
  public isGettingFiles: boolean = true;
  public filesByFolderId: any = {};
  getFiles(folder?, useSelectedFolder?) {
    return new Promise((resolve, reject) => {
      if (useSelectedFolder) folder = this.selectedFolder?.id ? this.selectedFolder : null;
      this.isGettingFiles = true;
      this.loadingFiles = !this.filesByFolderId[folder?.id || "Home"];
      this.workspaceService.getFilesByFolderId(folder?.id || null, this.workspace.id).subscribe((files: any[]) => {
        this.contentService.groupedFilesMap = {};
        this.contentService.groupedFormatsMap = {};
        if (folder?.synced) {
          files.forEach((item) => (item.synced = true));
        }
        let displayFiles = files.filter((file) => !file.grouping_id && !file.format_grouping_id);

        let groupedFiles = files.filter((item) => item.grouping_id);
        if (groupedFiles.length) {
          groupedFiles.forEach((item) => {
            if (!this.contentService.groupedFilesMap[item.grouping_id]) this.contentService.groupedFilesMap[item.grouping_id] = [];
            this.contentService.groupedFilesMap[item.grouping_id].push(item);
          });
          for (var i in this.contentService.groupedFilesMap) {
            displayFiles.push(this.contentService.groupedFilesMap[i][0]);
          }
        }
        let groupedFormatFiles = files.filter((item) => item.format_grouping_id);
        if (groupedFormatFiles.length) {
          groupedFormatFiles.forEach((item) => {
            if (!this.contentService.groupedFormatsMap[item.format_grouping_id]) this.contentService.groupedFormatsMap[item.format_grouping_id] = [];

            if (!this.contentService.groupedFormatsMap[item.format_grouping_id].find((x) => x.id === item.id)) {
              this.contentService.groupedFormatsMap[item.format_grouping_id].push(item);
            }
          });
          for (var i in this.contentService.groupedFormatsMap) {
            displayFiles.push(this.contentService.groupedFormatsMap[i][0]);
          }
        }

        files.forEach((file) => {
          if (!this.workspaceService.fileMap) this.workspaceService.fileMap = {};
          this.workspaceService.fileMap[file.id] = file;
        });

        displayFiles.sort((a, b) => {
          return new Date(a.date_created).getTime() - new Date(b.date_created).getTime();
        });
        if (this.batchOperationMode) {
          displayFiles.forEach((item) => {
            if (this.workspaceService.batchOperationSelection[item.id]) item.selectedForBatchOperation = true;
          });
        }
        this.filesByFolderId[folder?.id || "Home"] = displayFiles;
        this.loadingFiles = false;
        this.isGettingFiles = false;
        resolve(displayFiles);
      });
    });
  }

  findFolderById(folderTree, folderId) {
    if (!Array.isArray(folderTree)) {
      throw new Error("The folderTree must be an array.");
    }

    for (const folder of folderTree) {
      // Check if the current folder matches
      if (folder.id == folderId) {
        return folder;
      }

      // Check the children recursively if they exist
      if (folder.children && folder.children.length > 0) {
        const result = this.findFolderById(folder.children, folderId);
        if (result) {
          return result;
        }
      }
    }

    // Folder not found in this branch
    return null;
  }

  constructBreadcrumb(folderTree, targetFolderId) {
    const breadcrumb = ["Home"]; // Always start with "Home"

    const findPath = (folders, path) => {
      for (const folder of folders) {
        // Add the current folder to the path
        path.push(folder);

        // If this is the target folder, return true
        if (folder.id === targetFolderId) {
          return true;
        }

        // Recursively check children
        if (folder.children && folder.children.length > 0) {
          if (findPath(folder.children, path)) {
            return true;
          }
        }

        // Backtrack if this folder doesn't lead to the target
        path.pop();
      }

      return false; // Folder not found at this level
    };

    const path = [];
    findPath(folderTree, path);

    // Combine "Home" with the resolved path
    this.folderBreadCrumbSelection = breadcrumb.concat(path);
  }
}
