<div class="modal-header" #addcoverphoto>
  <h1 id="modal-title" class="modal-title">{{ "profile.edit.upload_file" | translate }}</h1>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="workspaceUploadLoader"></myp-inline-loader>
  {{ message }}
  <ng-container *ngIf="!loading">
    <div *ngIf="isShowcase">
      {{ "com.choose_mp3_showcase" | translate }}
    </div>
    <div
      ng2FileDrop
      class="drop-zone"
      *ngIf="(!uploader || (!uploader.queue.length && !uploadingComplete)) && !fileResponse"
      [class.hovering]="hasBaseDropZoneOver"
      (fileOver)="fileOverBase($event)"
      [uploader]="uploader"
    >
      {{ (hasBaseDropZoneOver ? "profile.edit.dropit" : "profile.edit.drophere") | translate }}
      <!-- <span class="text-sm">{{ 'profile.edit.accepted_formats' | translate }}: .jpg, .png</span> -->
      <br />
      <br />
      <div class="text-center">
        or
        <input type="file" ng2FileSelect [uploader]="uploader" />
      </div>

      <span style="font-size: 12px; color: gray; text-align: center; display: block; max-width: 400px; margin: 30px auto 0px auto">
        {{ "com.upload_copyright_warning" | translate }}
        <br />
        {{ "com.max_upload_filesize" | translate }}
      </span>
    </div>

    <div *ngIf="!progress && uploader.queue.length && !uploader.isUploading && !fileResponse">
      <div class="text-center">
        <h5>Queued to upload:</h5>
        <div *ngFor="let item of uploader.queue" class="text-center" style="position: relative">
          <span style="color: gray; font-size: 16px; text-align: center; font-family: Lato; font-weight: bold">
            {{ item?.file?.name }}
            <ng-container *ngIf="!isPostAttachment && !isShowcase">
              to
              <code>{{ folder?.filename || "Home" }}</code>
            </ng-container>
          </span>

          <div class="form-group m-t-20" *ngIf="!isPostAttachment && !isShowcase && !coverPhoto">
            <label class="control-label" for="description">{{ "sphere.upload.enter_description_file" | translate }}</label>
            <textarea name="description" id="description" [(ngModel)]="fileToUpload.description" class="form-control"></textarea>
          </div>

          <div class="form-group m-t-20" *ngIf="isShowcase">
            <label class="control-label" for="description">{{ "com.enter_description_showcase" | translate }}</label>
            <textarea name="description" id="description" [(ngModel)]="fileToUpload.description" class="form-control"></textarea>
          </div>

          <div class="form-check" *ngIf="!isPostAttachment && !isShowcase && audioTypes[item.file.type]">
            <input class="form-check-input" type="checkbox" [(ngModel)]="mainMix" id="mainMix" />
            <label class="form-check-label" for="mainMix">{{ "sphere.workspaces.most_recent_mixdown" | translate }}</label>
            <br />
            <small>{{ "sphere.workspaces.mixdown_if_checked" | translate }}</small>
          </div>
        </div>
        <button type="button" class="btn btn-primary m-t-20" (click)="getUploadUrl()">{{ "sphere.upload.upload_now" | translate }}</button>
      </div>
    </div>

    <div *ngIf="uploadingFile && (progress || progress === 0) && !fileResponse">
      <div class="text-center" style="position: relative">
        <span style="color: gray; font-size: 16px; text-align: center; font-family: Lato; font-weight: bold">{{ "general.uploading" | translate }} {{ uploadingFile.filename }}</span>
        <br />
        <br />
        <div style="display: inline-block; margin: auto">
          <round-progress
            [current]="progress"
            max="100"
            [color]="'#45ccce'"
            [background]="'#eaeaea'"
            [radius]="125"
            [stroke]="20"
            [semicircle]="false"
            [rounded]="true"
            [clockwise]="true"
            [responsive]="false"
            [duration]="250"
            [animation]="'easeInOutQuart'"
            [animationDelay]="0"
          ></round-progress>
        </div>
        <br />
        <span *ngIf="!uploadingComplete" class="uploading-label">{{ progress }}%</span>
        <span *ngIf="uploadingComplete && !fileResponse" class="uploading-label">{{ "general.processing_dots" | translate }}</span>
      </div>
      <br />
      <br />
      <span style="font-size: 12px; color: gray; text-align: center; display: block; max-width: 400px; margin: auto">{{ "com.can_close_upload" | translate }}</span>
    </div>

    <div *ngIf="fileResponse" class="m-y-20 text-center">
      {{ error ? error : ("sphere.upload.success" | translate) }}
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
