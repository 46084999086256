<div class="modal-body">
  <div>
    <div class="text-center m-b-30">
      <fa-icon icon="calendar" class="c-link-blue t-50"></fa-icon>
      <br />
      <br />
      <h1 id="modal-title" class="brandfont-black modal-title">Choose a date.</h1>
    </div>
    <div class="d-flex justify-content-center">
      <div style="width: 200px" class="m-x-10" aria-label="Month">
        <myp-autocomplete-dropdown idName="month" (selected)="select($event, 'month')" [hasDefault]="true" [items]="months" hint="Month..." [selection]="selectedMonth"></myp-autocomplete-dropdown>
      </div>
      <div style="width: 100px" class="m-x-10" aria-label="Day">
        <myp-autocomplete-dropdown idName="day" (selected)="select($event, 'day')" [hasDefault]="true" [items]="days" hint="Day..." [selection]="selectedDay"></myp-autocomplete-dropdown>
      </div>
      <div style="width: 200px" class="m-x-10" aria-label="Year">
        <myp-autocomplete-dropdown idName="year" (selected)="select($event, 'year')" [hasDefault]="true" [items]="years" hint="Year..." [selection]="selectedYear"></myp-autocomplete-dropdown>
      </div>
    </div>
  </div>
  <div class="text-center m-t-50">
    <span class="brandfont-black">{{ prettySelectedDate }}</span>
    <br />
    <fa-icon icon="chevron-down" aria-hidden="true"></fa-icon>
    <br />
    <button ngbAutofocus class="btn btn-primary" (click)="done()">Select Date</button>
    <br />
    <br />
    <button class="link" (click)="close($event)">Cancel</button>
  </div>
</div>
