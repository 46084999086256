import { Component, ElementRef, ViewChild } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { ActivatedRoute, Router } from "@angular/router";
import { EventBusService, HelperService, Thread, UserService, WindowService, WorkspaceService } from "@mypxplat/xplat/core";

import { MessagesBaseComponent } from "@mypxplat/xplat/features";
import { AppService, FirebaseService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { AddWorkspaceComponent, CreateNewThreadComponent, MessagesDetailComponent } from "@mypxplat/xplat/web/features";
import { take } from "rxjs/operators";
import { SelectListItemComponent } from "../../modals/select-list-item/select-list-item.component";
import { Timestamp } from "firebase/firestore";

@Component({
  selector: "myp-messages",
  templateUrl: "messages.component.html",
})
export class MessagesComponent extends MessagesBaseComponent {
  @ViewChild("messageDetailCmp") messageDetailCmp: MessagesDetailComponent;
  constructor(
    userService: UserService,
    private _win: WindowService,
    private _activatedRoute: ActivatedRoute,
    public router: Router,
    public helperService: HelperService,
    public appService: AppService,
    public communityService: WebCommunityService,
    private _eventbusService: EventBusService,
    public workspaceService: WorkspaceService,
    public db: AngularFirestore,
    public fbService: FirebaseService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.appService.title = this.env.studioOnePlusBrandName + ": Messages";
    this.threads = [];
    this.loading = true;
    if (this._activatedRoute.snapshot.params["id"]) this.noThreadSelected = false;
    this.communityService.getConnections().subscribe((connections) => {
      this.setupThreads();
    });
  }

  doFilter() {
    if (this.filter) {
      const search = this.filter.toLowerCase();
      this.displayedThreads = this.threads.filter(({ archived, connection }) => {
        if (archived?.[this.userService.user.id] || !connection) return false;
        return connection?.firstName.toLowerCase().includes(search) || connection?.lastName.toLowerCase().includes(search);
      });

      // Search archived threads and expand list if found
      this.archivedThreads = this.threads.filter(({ archived, connection }) => {
        if (!archived?.[this.userService.user.id] || !connection) return false;
        return connection?.firstName.toLowerCase().includes(search) || connection?.lastName.toLowerCase().includes(search);
      });
      if (this.archivedThreads.length > 0) {
        this.archivedThreadsVisible = true;
      }
    } else {
      this.displayedThreads = this.threads.filter((thread) => !thread.archived?.[this.userService.user.id]);
      this.archivedThreads = this.threads.filter((thread) => thread.archived?.[this.userService.user.id]);
      this.archivedThreadsVisible = false;
    }
  }

  setupThreads(forceRoute = false) {
    this.communityService.getConnectedUserMessageThreads().then((result) => {
      this.threads = result.filter((item) => !item.inactive || !item.inactive.length);
      this.sortThreads();
      this.displayedThreads = this.threads.filter((thread) => !thread.archived?.[this.userService.user.id]);
      this.archivedThreads = this.threads.filter((thread) => thread.archived?.[this.userService.user.id]);
      this.loading = false;
      let id = this._activatedRoute.snapshot.params["id"];
      if (id) {
        let thread = this.threads.filter((item) => item.them == id)[0];
        if (thread) {
          this.selectThread(thread, forceRoute);
          if (thread.archived?.[this.userService.user.id]) {
            this.archivedThreadsVisible = true;
          }
        } else {
          let connection = this.communityService.connections.filter((item) => item.theirID == id)[0];
          if (connection && connection.status == "accepted") {
            this.loading = true;
            this.communityService.createConnectedUserMessageThread(id).then((result) => {
              this.setupThreads();
            });
          } else {
            this.router.navigate(["messages/"], {
              replaceUrl: true,
            });
          }
        }
      }
    });
  }

  unsupportedFileChosen(args) {
    this.messageOptions(args.thread, false, false, args.message);
  }

  messageOptions(thread, event, includeViewProfile = true, optionsTitle?) {
    if (event) event.stopPropagation();

    const { firstName } = thread.connection;
    let actions = [`Add ${firstName} to a Workspace`, `Create New Workspace with ${firstName}`];
    if (includeViewProfile) actions.unshift(`View ${firstName}'s Profile`);
    const archiveAction = thread.archived?.[this.user.id] ? "Unarchive Thread" : "Archive Thread";
    actions.push(archiveAction);

    let args: any = {
      title: optionsTitle ? optionsTitle : "Options",
      actions: actions,
    };
    this._eventbusService.emit(this._eventbusService.types.showActionChooser, args);
    this._eventbusService
      .observe(this._eventbusService.types.actionChosen)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          if (result.indexOf("Profile") > -1) {
            this.router.navigate(["../../profile/", thread.them]);
          } else if (result.indexOf("Create") > -1) {
            let modal: any = AddWorkspaceComponent;
            const modalRef = this.appService.showModal(modal, { size: "lg" });
            modalRef.componentInstance.initialCollaborator = thread.connection;
          } else if (result == "Archive Thread") {
            if (confirm("Are you sure you want to archive this thread?")) {
              const threadRef = this.db.collection("connection_threads").doc(thread.id);
              this.fbService.handleFirestorePromise(() =>
                threadRef.update({ [`archived.${this.user.id}`]: Timestamp.now() }).then((_) => {
                  this.setupThreads();
                })
              );
            }
          } else if (result == "Unarchive Thread") {
            const threadRef = this.db.collection("connection_threads").doc(thread.id);
            this.fbService.handleFirestorePromise(() =>
              threadRef.update({ [`archived.${this.user.id}`]: null }).then((_) => {
                this.setupThreads();
              })
            );
          } else {
            this.workspaceService.getWorkspaces().subscribe((result) => {
              let items = [];
              if (result.workspaces && result.workspaces.length) {
                result.workspaces.forEach((item) => {
                  items.push({
                    title: item.name,
                    photo: item.image ? item.image : "./assets/icons/workspace.png",
                    item: item,
                  });
                });
                let modal: any = SelectListItemComponent;
                const modalRef = this.appService.showModal(modal, { size: "sm" });
                modalRef.componentInstance.items = items;
                modalRef.componentInstance.imageRound = false;
                modalRef.componentInstance.imageBorder = false;
                modalRef.componentInstance.title = "Add " + thread.connection.firstName + " to a workspace...";
                modalRef.componentInstance.itemSelected.pipe(take(1)).subscribe((workspace) => {
                  this.workspaceService.findCollaborator(thread.connection.theirID, workspace.id).subscribe({
                    next: (inviteResult) => {
                      let timelineRef = this.db.collection("timelines").doc(workspace.firebase_timeline_id).ref;
                      this.fbService
                        .handleFirestorePromise(() => timelineRef.get())
                        .then((result) => {
                          let data: any = result.data();
                          let contributors = data.contributors;
                          contributors[thread.them] = true;
                          timelineRef.update({ contributors });
                        })
                        .catch((err) => {});
                      this.loading = false;
                      alert(thread.connection.firstName + " was successfully added to " + workspace.name + ".");
                      this.addWorkspaceInviteNotification(thread, workspace, inviteResult.invite);
                    },
                    error: (err) => {
                      this.appService.alertError(err);
                    },
                  });
                });
              }
            });
          }
        }
      });
  }

  addWorkspaceInviteNotification(thread, workspace, invite) {
    let obj: any = {
      author: {
        id: this.user.id,
        photo: this.user.photoURL,
        name: this.user.firstName + " " + this.user.lastName,
      },
      notificationType: "workspace_invite",
      workspace: workspace,
      invite: invite,
      type: "notification",
      timestamp: new Date(),
    };
    this.db.collection("connection_threads").doc(thread.id).collection("messages").add(obj);
  }

  showCreateMessage() {
    const modal = this.appService.showModal(CreateNewThreadComponent);
    modal.componentInstance.recipientSelected.pipe(take(1)).subscribe((result) => {
      let exists = this.threads.filter((item) => item.them == result.theirID);
      if (exists.length) {
        this.selectThread(exists[0]);
      } else {
        let theirID = result.initiated_by == this.user.id ? result.requested_user_id : result.initiated_by;
        this.communityService.createConnectedUserMessageThread(theirID).then((result) => {
          this.router.navigate(["messages/", theirID], {
            replaceUrl: true,
          });
          this.setupThreads(true);
        });
      }
    });
  }

  selectThread(thread: Thread, route = true) {
    this.selectedThread = undefined;
    if (route) {
      this._win.setTimeout(() => {
        this.router.navigate(["messages/", thread.them], {
          replaceUrl: true,
        });
        this.selectedThread = thread;
        this.communityService.activeThread = thread;
      }, 10);
    } else {
      this.selectedThread = thread;
      this.communityService.activeThread = thread;
    }
  }

  toggleArchivedThreads() {
    this.archivedThreadsVisible = !this.archivedThreadsVisible;
  }

  ngOnDestroy() {
    this.communityService.activeThread = undefined;
  }
}
