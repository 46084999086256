<div class="prs-global-header" [class.active-subscription]="user.active_subscription">
  <a class="skip-link" href="./#sphere-app-content" onclick="event.preventDefault(); location.hash='sphere-app-content';" title="Skip to content" aria-label="Skip to content">Skip to content</a>
  <div class="file-upload-progress" *ngIf="uploadingFileCount && totalUploadPercentage" [ngStyle]="{ width: 'calc(' + totalUploadPercentage + '% + 15px)' }"></div>
  <header>
    <div class="header-container">
      <div class="navbar" aria-label="website main menu">
        <ng-container *ngIf="!uploadingFileCount">
          <a class="logo-container navbar-brand" [routerLink]="['/home']" attr.aria-label="{{ 'header.nav.home' | translate }}">
            <img [src]="appService.headerLogo" class="pointer text-logo" aria-hidden="true" alt="PreSonus - Home" />
          </a>
        </ng-container>

        <div class="logo-container" *ngIf="uploadingFileCount">
          <div>
            <!-- <img [src]="appService.headerLogo" class="pointer text-logo" /> -->
            <span class="uploading-header-details">
              {{ "general.uploading" | translate }} {{ uploadingFileCount }} File{{ uploadingFileCount == 1 ? "" : "s" }}
              <span style="font-size: 12px; color: #eaeaea; display: block">
                {{ totalUploadPercentage }}% -
                <a (click)="downloadDetails()" class="c-lblue pointer">Details</a>
              </span>
            </span>
          </div>
        </div>
        <ul class="navbar-nav options flex-row">
          <li class="nav-item mb-0" [routerLinkActive]="['active']">
            <a [routerLink]="['/products']" (click)="trackPageEvent('products')" class="nav-option" attr.aria-label="{{ 'header.nav.products' | translate }}">
              {{ "header.nav.products" | translate }}
            </a>
          </li>
          <li class="nav-item mb-0" [routerLinkActive]="['active']">
            <a [routerLink]="['/learn/']" (click)="trackPageEvent('learn')" class="nav-option" attr.aria-label="{{ 'header.nav.learn' | translate }}">{{ "header.nav.learn" | translate }}</a>
          </li>
          <li class="nav-item mb-0" [routerLinkActive]="['active']">
            <a [routerLink]="['/support']" (click)="trackPageEvent('support')" class="nav-option" attr.aria-label="{{ 'header.nav.support' | translate }}">{{ "header.nav.support" | translate }}</a>
          </li>
          <li class="nav-item mb-0" [routerLinkActive]="['active']">
            <a [routerLink]="['/workspaces']" (click)="trackPageEvent('workspaces')" class="nav-option" attr.aria-label="{{ 'sphere.workspaces.workspaces' | translate }}">
              {{ "sphere.workspaces.workspaces" | translate }}
            </a>
          </li>
          <li class="nav-item mb-0" [routerLinkActive]="['active']">
            <a [routerLink]="['/exchange']" (click)="trackPageEvent('exchange')" class="nav-option" attr.aria-label="{{ 'header.nav.exchange' | translate }}">
              {{ "header.nav.exchange" | translate }}
            </a>
          </li>
          <li class="nav-item mb-0" [routerLinkActive]="['active']">
            <a
              [routerLink]="['/community']"
              (click)="trackPageEvent('community')"
              *ngIf="!user.banned && communityService.profile?.status != 'banned'"
              class="nav-option"
              attr.aria-label="{{ 'com.community' | translate }}"
            >
              {{ "com.community" | translate }}
            </a>
          </li>
        </ul>
      </div>

      <div class="account">
        <div class="d-flex align-items-center justify-content-end">
          <div class="d-flex align-items-center pointer position-relative">
            <button
              role="“link”"
              class="btn bg-transparent header-btn"
              tabindex="“0”"
              (click)="profilePhotoClicked(communityService.connectionRequests?.length ? 'connections' : 'about')"
              aria-label="visit profile"
              (keyup.enter)="profilePhotoClicked(communityService.connectionRequests?.length ? 'connections' : 'about')"
            >
              <img alt="PreSonus - User Profile" class="profile-photo" [src]="userService.profilePicture" aria-label="user profile" />
              <span
                [ngbTooltip]="'com.connection_requests_count' | translate: { count: communityService.connectionRequests.length, plural: communityService.connectionRequests.length > 1 ? 's' : '' }"
                attr.aria-label="{{
                  'com.connection_requests_count' | translate: { count: communityService.connectionRequests.length, plural: communityService.connectionRequests.length > 1 ? 's' : '' }
                }}"
                class="unread-messages-badge"
                *ngIf="communityService.connectionRequests?.length"
              >
                {{ communityService.connectionRequests.length }}
              </span>
            </button>
            <button type="button" class="btn bg-transparent header-btn" (click)="hamburgerClick()" aria-label="menu">
              <fa-icon class="c-white circle-icon-btn hamburger-menu-icon" data-cy="header-options" icon="bars" aria-hidden="true"></fa-icon>
              <span class="sr-only">menu</span>
            </button>
            <ng-container *ngIf="user.active_subscription">
              <a class="btn bg-transparent header-btn" [routerLink]="['/messages']" aria-label="messages">
                <fa-icon class="c-white circle-icon-btn" icon="envelope" aria-hidden="true"></fa-icon>
                <span class="sr-only">messages</span>
                <span class="unread-messages-badge" *ngIf="communityService.unreadCounts.total">{{ communityService.unreadCounts.total }}</span>
              </a>
            </ng-container>
            <ng-container *ngIf="communityService.notificationCount">
              <button class="btn bg-transparent header-btn" aria-label="notifications" (click)="showNotifications = !showNotifications">
                <fa-icon class="c-white circle-icon-btn" icon="bell" aria-hidden="true"></fa-icon>
                <span class="sr-only">notifications</span>
                <span class="unread-messages-badge" *ngIf="communityService.unreadNotificationCount">{{ communityService.unreadNotificationCount }}</span>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </header>
</div>
<ng-container *ngIf="showNotifications">
  <myp-notifications-overlay (closed)="showNotifications = false"></myp-notifications-overlay>
</ng-container>
