<div class="col-lg-8 offset-lg-2 col-sm-12 offset-sm-0 product-list">
  <h4>{{ "account.notifications" | translate }}</h4>
  {{ "account.not_email_too_much" | translate }}

  <ng-container>
    <h4 class="section-header m-t-50">Marketing Emails</h4>

    <div class="m-b-20">
      <div class="m-b-20">
        <label>
          <input type="checkbox" [(ngModel)]="email_settings.subscribed" />
          &nbsp;
          <strong>{{ "account.marketing" | translate }}</strong>
        </label>
      </div>
      <span *ngIf="!email_settings.subscribed">{{ "account.optout" | translate }}</span>
      <div class="m-l-10" *ngIf="email_settings.subscribed">
        <div class="m-b-20" *ngFor="let type of email_settings.messageTypes">
          <label>
            <input type="checkbox" [(ngModel)]="type.subscribed" />
            &nbsp;
            <strong>{{ type.name }}</strong>
          </label>
          <br />
          <span *ngIf="type.name == 'Marketing Message'">{{ "account.marketing" | translate }}</span>
          <span *ngIf="type.name == 'Product Updates'">{{ "account.product_updates" | translate }}</span>
          <span *ngIf="type.name == 'Sphere'">{{ "account.sphere" | translate }}</span>
          <span *ngIf="type.name == 'Studio One+'">{{ "account.studio_one_plus" | translate }}</span>
        </div>
      </div>
    </div>
    <button class="btn btn-secondary m-b-20" (click)="saveIterablePreferences()">
      <fa-icon icon="check"></fa-icon>
      &nbsp;{{ saveButtonText }}
    </button>
  </ng-container>
</div>
<img [src]="resubscribeImgSrc" *ngIf="resubscribeImgSrc" width="0" height="0" />
