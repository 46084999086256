<div class="prs-global-header" [class.active-subscription]="user.active_subscription">
  <div class="file-upload-progress" *ngIf="contentService.uploadSession.totalProgress" [ngStyle]="{ width: 'calc(' + contentService.uploadSession.totalProgress + '% + 15px)' }"></div>
  <div class="header-container">
    <div class="logo-container" *ngIf="contentService.uploadSession.totalFilesUploading">
      <div>
        <!-- <img [src]="appService.presonusAdminLogo" class="pointer text-logo" /> -->
        <span class="uploading-header-details">
          {{ "general.uploading" | translate }} {{ contentService.uploadSession.totalFilesUploading }} File{{ contentService.uploadSession.totalFilesUploading == 1 ? "" : "s" }}
          <span style="font-size: 12px; color: #eaeaea; display: block">
            {{ contentService.uploadSession.totalProgress }}% -
            <a (click)="downloadDetails()" class="c-lblue pointer">Details</a>
          </span>
        </span>
      </div>
    </div>
    <a class="logo-container" *ngIf="!contentService.uploadSession.totalFilesUploading" [routerLink]="['/home']">
      <img [src]="appService.presonusAdminLogo" class="pointer text-logo" />
    </a>

    <div class="text-left options">
      <a [routerLink]="['/products/']" class="nav-option">Products</a>
      <!-- 
      For now, we will manage subscription products the Products section.
      <a [routerLink]="['/subscriptions/']" class="nav-option">Subscriptions</a> 
      -->
      <a [routerLink]="['/studiooneplus/']" class="nav-option">Studio One Pro+</a>
      <a [routerLink]="['/customer/']" class="nav-option">Customer</a>
    </div>

    <div class="account">
      <div class="d-flex align-items-center justify-content-end">
        <div class="d-flex align-items-center pointer position-relative">
          <img (click)="profilePhotoClicked(communityService.connectionRequests?.length ? 'connections' : 'about')" [src]="userService.profilePicture" class="profile-photo m-r-10" />
          <span
            class="connection-requests-badge"
            (click)="profilePhotoClicked(communityService.connectionRequests?.length ? 'connections' : 'about')"
            [ngbTooltip]="'com.connection_requests_count' | translate: { count: communityService.connectionRequests.length }"
            *ngIf="communityService.connectionRequests?.length"
          >
            {{ communityService.connectionRequests.length }}
          </span>
          <fa-icon class="c-white circle-icon-btn hamburger-menu-icon" data-cy="header-options" icon="bars" (click)="hamburgerClick()"></fa-icon>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="showNotifications">
  <myp-notifications-overlay (closed)="showNotifications = false"></myp-notifications-overlay>
</ng-container>
