<div class="prs-global-header" [class.active-subscription]="user.active_subscription">
  <div class="file-upload-progress" *ngIf="uploadingFileCount && totalUploadPercentage" [ngStyle]="{ width: 'calc(' + totalUploadPercentage + '% + 15px)' }"></div>
  <div class="header-container">
    <a class="logo-container" *ngIf="!uploadingFileCount" [routerLink]="['/home']">
      <img src="./assets/images/fender-logo-red.png" class="pointer text-logo" />
    </a>

    <div class="text-left options">
      <!-- <a [routerLink]="['/home']" class="nav-option">Dashboard</a> -->
      <a [routerLink]="['/products/']" class="nav-option">Products</a>
      <a [routerLink]="['/home/']" class="nav-option">Learn To Play</a>
      <a [routerLink]="['/home/']" class="nav-option">Support</a>
      <!-- <a [routerLink]="['/subscriptions/']" class="nav-option">Subscriptions</a> -->
    </div>

    <div class="account">
      <div class="d-flex align-items-center justify-content-end">
        <div class="d-flex align-items-center pointer position-relative">
          <img (click)="profilePhotoClicked(communityService.connectionRequests?.length ? 'connections' : 'about')" [src]="userService.profilePicture" class="profile-photo m-r-10" />
          <span
            class="connection-requests-badge"
            (click)="profilePhotoClicked(communityService.connectionRequests?.length ? 'connections' : 'about')"
            [ngbTooltip]="'com.connection_requests_count' | translate: { count: communityService.connectionRequests.length }"
            *ngIf="communityService.connectionRequests?.length"
          >
            {{ communityService.connectionRequests.length }}
          </span>
          <fa-icon class="c-white circle-icon-btn hamburger-menu-icon" data-cy="header-options" icon="bars" (click)="hamburgerClick()"></fa-icon>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="showNotifications">
  <myp-notifications-overlay (closed)="showNotifications = false"></myp-notifications-overlay>
</ng-container>
